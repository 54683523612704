// Imports
import Vue from "vue";
import Router from "vue-router";
import { layout, route } from "@/utils/routes";

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior: (to, from, savedPosition) => {
    if (to.hash) return { selector: to.hash };
    if (savedPosition) return savedPosition;

    return { x: 0, y: 0 };
  },
  routes: [
    layout("Default", [route("ObjectsTable")], "/devices"),

    layout("Default", [route("ObjectView")], "/device_new"),
    layout("Default", [route("ObjectView")], "/device_view"),
    layout("Default", [route("ObjectView")], "/device_edit"),

    layout("Default", [route("ObjectsTable")], "/firmwares"),

    layout("Default", [route("ObjectView")], "/firmware_new"),
    layout("Default", [route("ObjectView")], "/firmware_view"),
    layout("Default", [route("ObjectView")], "/firmware_edit"),

    layout("Default", [route("ObjectsTable")], "/teams"),
    
    layout("Default", [route("QrCodeView")], "/qr"),

    layout("Default", [route("TypesList")], "/types"),

    layout("Default", [route("ObjectView")], "/type_new"),
    layout("Default", [route("ObjectView")], "/type_view"),
    layout("Default", [route("ObjectView")], "/type_edit"),

    layout("Default", [route("GroupsList")], "/groups"),

    layout("Default", [route("ObjectView")], "/group_new"),
    layout("Default", [route("ObjectView")], "/group_view"),
    layout("Default", [route("ObjectView")], "/group_edit"),
    
    layout("Default", [route("ObjectsTable")], "/documents"),
    
    layout("Default", [route("ObjectView")], "/document_new"),
    
    layout("Default", [route("ObjectView")], "/basic_document_view"),
    layout("Default", [route("ObjectView")], "/basic_document_edit"),
    
    layout("Default", [route("ObjectView")], "/documents_collection_view"),
    layout("Default", [route("ObjectView")], "/documents_collection_edit"),

    layout("Default", [route("Dashboard")], "/"),

    layout("Default", [route("Error")], "*")
  ]
});

export default router;
