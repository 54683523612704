<template>
  <div class="flex-row" v-if="null != group_id">
    <div class="column thin" :class="{ 'mt-04': null != devices }">
      Devices ({{ null != devices ? devices.length : 0 }}) :
    </div>
    <div class="column wide">
      <ul class="sublist" v-if="null != devices">
        <li v-for="(device, key_group) in devices" :key="key_group">
          <div class="flex-row">
            <div>
              <router-link :to="getDeviceViewUrl(device.id)">
                {{ device.name }}
              </router-link>
            </div>
            <v-spacer />
            <div v-if="!disabled">
              <base-button
                text="Delete from the group"
                @click="deleteDeviceFromGroup(device)"
              />
            </div>
          </div>
        </li>
      </ul>
      <br v-else />

      <!-- Dialog -->
      <div v-if="!disabled">
        <v-dialog v-model="dialog" width="500" :key="dialog">
          <template v-slot:activator="{ on, attrs }">
            <base-button
              :is_accent="true"
              text="Add devices"
              @click="addDevicesClicked()"
              v-bind="attrs"
              v-on="on"
            />
          </template>

          <v-card>
            <v-card-title>
              {{
                "Select devices to be added to the group " +
                (null != selected_group ? selected_group.name : "")
              }}</v-card-title
            >

            <div v-if="null !== selected_group">
              <p class="text-center">
                Devices of the type {{ selected_group.type_name }}:
              </p>
              <device-table-simplified
                :object_category="getDeviceCategory"
                :selected_type_id="selected_group.type_id"
                @deviceSelectionChange="deviceSelectionChangeHandler($event)"
              />
            </div>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <base-button
                :is_accent="true"
                :text="'Add selected devices (' + selected_devices.length + ')'"
                @click="addSelectedDevicesToGroup()"
              />
              <base-button text="Close" @click="dialog = false" />
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
    </div>
  </div>
</template>

<script>
import { getDeviceCategory, getViewUrl } from "../../../utils/RoutesUtils";
import {
  addSelectedDevicesToGroup,
  deleteDeviceFromGroup,
} from "../../../utils/ServerUtils.js";
import BaseButton from "../../../components/app/BaseButton.vue";
import DeviceTableSimplified from "../../../layouts/default/widgets/DeviceTableSimplified.vue";

export default {
  name: "DevicesInGroup",
  components: { DeviceTableSimplified, BaseButton },
  props: {
    devices: {
      type: Array,
      default: () => [],
    },
    group_id: {
      type: String,
      default: null,
    },
    group_name: {
      type: String,
      default: null,
    },
    type_id: {
      type: String,
      default: null,
    },
    type_name: {
      type: String,
      default: null,
    },
    disabled: Boolean,
  },

  data() {
    return {
      selected_group: null,
      dialog: false,

      selected_devices: [],
    };
  },

  computed: {
    getDeviceCategory: function () {
      return getDeviceCategory();
    },
  },

  methods: {
    addDevicesClicked: function () {
      this.selected_group = {
        id: this.group_id,
        name: this.group_name,
        type_id: this.type_id,
        type_name: this.type_name,
      };
    },
    
    getDeviceViewUrl: function (object_id) {
      return getViewUrl(object_id, getDeviceCategory());
    },

    addSelectedDevicesToGroup: async function () {
      this.dialog = false;
      await addSelectedDevicesToGroup(
        this.selected_devices,
        this.selected_group.id
      );

      this.$emit("devices_list_is_changed");
    },

    deviceSelectionChangeHandler: function (event_object) {
      this.selected_devices = event_object;
    },

    deleteDeviceFromGroup: async function (device) {
      await deleteDeviceFromGroup(device);

      this.$emit("devices_list_is_changed");
    },
  },
};
</script>

<style scoped>
li .flex-row {
  margin-top: 0;
}
.mt-04 {
  margin-top: 0.4em;
}
</style>