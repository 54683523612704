<template>
  <div>
    <qr-code-device-public-info :device="device" class="my-5" />

    <base-button
      :is_accent="true"
      :to="viewDeviceUrl"
      text="Go to the device page"
    />
  </div>
</template>

<script>
import BaseButton from "../../../components/app/BaseButton.vue";
import { getDeviceViewUrl } from "../../../utils/RoutesUtils";
import QrCodeDevicePublicInfo from "./QrCodeDevicePublicInfo.vue";
export default {
  components: { QrCodeDevicePublicInfo, BaseButton },
  props: {
    device: Object,
  },

  computed: {
    viewDeviceUrl() {
      if (null != this.device && null != this.device.id) {
        return getDeviceViewUrl(this.device.id);
      }

      return null;
    },
  },
};
</script>

<style>
</style>