// See also src/router/index.js
// TODO: this code is difficult to maintain and should be rewritten

export const PAGES_URLS = {
  HOME: "",
  TEAMS: "teams",
  DEVICES: "devices",
  FIRMWARES: "firmwares",
  GROUPS: "groups",
  TYPES: "types",
  DOCUMENTS: "documents",
  DEVICE: "device",
  FIRMWARE: "firmware",
  GROUP: "group",
  TYPE: "type",
  BASIC_DOCUMENT: "document",
  DOCUMENTS_COLLECTION: "documents_collection"
};

export const PAGE = {
  HOME: 0,
  NEW: 1,
  VIEW: 2,
  EDIT: 3,
  TABLE: 4
};

export const OBJECT_CATEGORIES = {
  NONE: 0,
  DEVICE: 1,
  GROUP: 2,
  TYPE: 3,
  FIRMWARE: 4,
  TEAM: 5,
  ROLE: 6,
  USER: 7,
  CONFIGURATION: 8,
  DOCUMENT: 9,
  BASIC_DOCUMENT: 10,
  DOCUMENTS_COLLECTION: 11
};

export const OBJECT_URL_NAMES = [
  "",
  "device",
  "group",
  "type",
  "firmware",
  "team",
  "",
  "",
  "config",
  "document",
  "basic_document",
  "documents_collection"
];

// Construct pages urls and titles
const urls_with_titles_template = {
  device: "Devices",
  group: "Groups",
  type: "Types",
  firmware: "Firmwares",
  team: "Teams",
  document: "Documents",
  basic_document: "Documents",
  documents_collection: "Documents"
};

const urls_with_titles = {
  index: "Home"
};
for (let url in urls_with_titles_template) {
  const title = urls_with_titles_template[url];
  urls_with_titles[url + "s"] = title;
  urls_with_titles[url + "_new"] = title + " | Add";
  urls_with_titles[url + "_view"] = title + " | View";
  urls_with_titles[url + "_edit"] = title + " | Edit";
}

// -----------------------
// Get categories from URL
// -----------------------

export function getPageNameFromUrl(relative_url) {
  var n = relative_url.indexOf("_"); // detect urls of type "/device_new"
  if (n == -1) {
    n = relative_url.length;
  }
  return relative_url.substr(1, n - 1);
}

export function getObjectCategoryFromUrl(relative_url) {
  var n = relative_url.lastIndexOf("_");
  var object_url_name = relative_url.substr(1, n - 1);
  return OBJECT_URL_NAMES.indexOf(object_url_name);
}

export function getObjectCategoryFromRoutePath(routePath) {
  // "/devices/" --> "devices/"
  const n_start = routePath[0] == "/" ? 1 : 0;
  // "/devices/" --> "/device"
  const n_end =
    routePath[routePath.length - 1] == "/"
      ? routePath.length - 3
      : routePath.length - 2;

  var object_url_name = routePath.substr(n_start, n_end);
  return OBJECT_URL_NAMES.indexOf(object_url_name);
}

export function getPageFromUrl(relative_url, object_category) {
  // trim last "/" symbol if it is present in the path
  relative_url = trimLastSlash(relative_url);

  switch (relative_url) {
    case "/" + getObjectUrlName(object_category) + "_new":
      return PAGE.NEW;
    case "/" + getObjectUrlName(object_category) + "_edit":
      return PAGE.EDIT;
    case "/" + getObjectUrlName(object_category) + "_view":
      return PAGE.VIEW;
    default:
      return PAGE.HOME;
  }
}

export function getListOfObjectsUrl(object_category) {
  return "/" + getObjectUrlName(object_category) + "s";
}

// ----------
// Booleans
// ----------

export function isObjectViewUrl(relative_url) {
  const page = getPageNameFromUrl(relative_url);
  if (
    PAGES_URLS.DEVICE == page ||
    PAGES_URLS.FIRMWARE == page ||
    PAGES_URLS.GROUP == page ||
    PAGES_URLS.TYPE == page
  )
    return true;
  return false;
}

export function isHomeUrl(relative_url) {
  const page = getPageNameFromUrl(relative_url);
  return PAGES_URLS.HOME == page;
}

export function isTeamsUrl(relative_url) {
  const page = getPageNameFromUrl(relative_url);
  return PAGES_URLS.TEAMS == page;
}

export function isDevicesUrl(relative_url) {
  const page = getPageNameFromUrl(relative_url);
  return PAGES_URLS.DEVICES == page;
}

export function isFirmwaresUrl(relative_url) {
  const page = getPageNameFromUrl(relative_url);
  return PAGES_URLS.FIRMWARES == page;
}

export function isGroupsUrl(relative_url) {
  const page = getPageNameFromUrl(relative_url);
  return PAGES_URLS.GROUPS == page;
}

export function isTypesUrl(relative_url) {
  const page = getPageNameFromUrl(relative_url);
  return PAGES_URLS.TYPES == page;
}

export function isDocumentsUrl(relative_url) {
  const page = getPageNameFromUrl(relative_url);
  return PAGES_URLS.DOCUMENTS == page;
}

// -----------------------
// Calculate URLs from categories
// -----------------------

function getObjectUrlName(object_category) {
  return OBJECT_URL_NAMES[object_category];
}

export function getObjectHumanName(object_category) {
  if (object_category == OBJECT_CATEGORIES.BASIC_DOCUMENT) {
    return "document";
  } else if (object_category == OBJECT_CATEGORIES.DOCUMENTS_COLLECTION) {
    return "documents collection";
  } else {
    return getObjectUrlName(object_category);
  }
}

export function getNewUrl(object_category) {
  return "/" + getObjectUrlName(object_category) + "_new";
}

export function getViewUrl(object_id, object_category) {
  return "/" + getObjectUrlName(object_category) + "_view?id=" + object_id;
}

export function getDeviceViewUrl(object_id) {
  return getViewUrl(object_id, getDeviceCategory());
}

export function getEditUrl(object_id, object_category) {
  return "/" + getObjectUrlName(object_category) + "_edit?id=" + object_id;
}

// -----------------------
// Object categories utils
// -----------------------

export function isDevice(object_category) {
  return OBJECT_CATEGORIES.DEVICE == object_category;
}

export function isGroup(object_category) {
  return OBJECT_CATEGORIES.GROUP == object_category;
}

export function isFirmware(object_category) {
  return OBJECT_CATEGORIES.FIRMWARE == object_category;
}

export function isRole(object_category) {
  return OBJECT_CATEGORIES.ROLE == object_category;
}

export function isTeam(object_category) {
  return OBJECT_CATEGORIES.TEAM == object_category;
}

export function isType(object_category) {
  return OBJECT_CATEGORIES.TYPE == object_category;
}

export function isDocument(object_category) {
  return (
    OBJECT_CATEGORIES.BASIC_DOCUMENT == object_category ||
    OBJECT_CATEGORIES.DOCUMENT == object_category
  );
}

export function getTypeCategory() {
  return OBJECT_CATEGORIES.TYPE;
}

export function getFirmwareCategory() {
  return OBJECT_CATEGORIES.FIRMWARE;
}

export function getDeviceCategory() {
  return OBJECT_CATEGORIES.DEVICE;
}

export function getConfigCategory() {
  return OBJECT_CATEGORIES.CONFIGURATION;
}

export function getGroupCategory() {
  return OBJECT_CATEGORIES.GROUP;
}

export function getTeamCategory() {
  return OBJECT_CATEGORIES.TEAM;
}

export function getDocumentCategory() {
  return OBJECT_CATEGORIES.DOCUMENT;
}

export function getDocumentsCollectionCategory() {
  return OBJECT_CATEGORIES.DOCUMENTS_COLLECTION;
}

// -----------------------
// Labels and titles utils
// -----------------------

export function getPageTitleFromRoutePath(routePath) {
  routePath = trimFirstSlash(routePath);
  routePath = trimLastSlash(routePath);

  return urls_with_titles[routePath];
}

export function getDropDownListZeroOptionLabel(object_category) {
  switch (object_category) {
    case OBJECT_CATEGORIES.TYPE:
      return "Select a type...";
    default:
      return "";
  }
}

export function getCategoryLabel(object_category, is_plural = false) {
  var plural_s = is_plural ? "s" : "";

  switch (object_category) {
    case OBJECT_CATEGORIES.GROUP:
      return "Group" + plural_s;
    case OBJECT_CATEGORIES.FIRMWARE:
      return "Firmware" + plural_s;
    case OBJECT_CATEGORIES.TYPE:
      return "Type" + plural_s;
    case OBJECT_CATEGORIES.DEVICE:
      return "Device" + plural_s;
    case OBJECT_CATEGORIES.DOCUMENT:
      return "Document" + plural_s;
    case OBJECT_CATEGORIES.TEAM:
      return "Your team" + plural_s;
    default:
      return "";
  }
}

export function getTitle(page, object_category) {
  var name_from_url = getObjectHumanName(object_category);

  switch (page) {
    case PAGE.NEW:
      return "Add a new " + name_from_url;
    case PAGE.EDIT:
      return "Edit " + name_from_url;
    case PAGE.VIEW:
      return "View " + name_from_url;
    default:
      return "";
  }
}

// -----------------------

export function trimLastSlash(string_to_trim) {
  // trim last "/" symbol if it is present in the path
  if (string_to_trim[string_to_trim.length - 1] == "/") {
    return string_to_trim.substr(0, string_to_trim.length - 1);
  }

  return string_to_trim;
}

export function trimFirstSlash(string_to_trim) {
  // trim last "/" symbol if it is present in the path
  if (string_to_trim[0] == "/") {
    return string_to_trim.substr(1, string_to_trim.length);
  }

  return string_to_trim;
}
