export function formatDate(
  object_date,
  show_time_flag = false,
  simple_format = false
) {
  
  const locale_format = "en-GB";
  
  if (null == object_date) return "-";

  if (simple_format) return new Date(object_date).toString(locale_format);

  var d = Date.parse(object_date);
  const date_time = new Date(d);

  var date_or_time_str = date_time.toLocaleDateString(locale_format);
  if (show_time_flag) {
    date_or_time_str +=
      " " + date_time.toLocaleTimeString(locale_format) + " GMT" + getTimezoneOffset();
  }

  return date_or_time_str;
}

function getTimezoneOffset() {
  function z(n) {
    return (n < 10 ? "0" : "") + n;
  }
  var offset = new Date().getTimezoneOffset();
  var sign = offset < 0 ? "+" : "-";
  offset = Math.abs(offset);
  return sign + z((offset / 60) | 0) + z(offset % 60);
}
