<template>
  <div class="subgroup">
    <div class="flex-row">
      <div class="column thin">
        Groups ({{ null != devices_groups ? devices_groups.length : 0 }}):
      </div>
      <div class="column wide">
        <ul class="sublist">
          <li
            v-for="(group, key_group) in devices_groups"
            :key="'g' + key_group"
          >
            <router-link :to="getGroupViewUrl(group.id)">{{
              group.name
            }}</router-link>
            (devices: {{ group.number_of_devices }})
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { getGroupCategory, getViewUrl } from "../../../utils/RoutesUtils";
export default {
  props: {
    devices_groups: {
      type: Array,
      required: true,
      default: null
    }
  },

  methods: {
    getGroupViewUrl: function(object_id) {
      return getViewUrl(object_id, getGroupCategory());
    }
  }
};
</script>

<style></style>
