// Vuetify Documentation https://vuetifyjs.com

import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import ripple from "vuetify/lib/directives/ripple";

Vue.use(Vuetify, { directives: { ripple } });

const theme = {
  primary: "#2095b6",
  alert: "#fb7641",
  ok: "#4CAF50",
  info_gray: "#9A9A9A",
  selected_light: "#f5f5f5",
  sidebar_active: "#2095B6",
  accent_gray: "#717171",
  light_red: "#ffebe9",
};

export default new Vuetify({
  breakpoint: { mobileBreakpoint: 960 },
  icons: {
    values: { expand: "mdi-menu-down" }
  },
  theme: {
    themes: {
      dark: theme,
      light: theme
    }
  }
});
