<template>
  <div>
    <div>
      <div v-if="type_docs_list != null && type_docs_list.length > 0">
        <label>Type documentation:</label>

        <SimpleDocumentationList :docs_list="type_docs_list" />

        <v-divider></v-divider>
      </div>

      <div v-if="group_docs_list != null && group_docs_list.length > 0">
        <label>Group documentation:</label>

        <SimpleDocumentationList :docs_list="group_docs_list" />

        <v-divider></v-divider>
      </div>

      <div
        v-if="
          current_firmware_docs_list != null &&
            current_firmware_docs_list.length > 0
        "
      >
        <label>Current firmware documentation:</label>

        <SimpleDocumentationList :docs_list="current_firmware_docs_list" />

        <v-divider></v-divider>
      </div>

      <div
        v-if="
          !firmwaresAreEqualButNotNull &&
            target_firmware_docs_list != null &&
            target_firmware_docs_list.length > 0
        "
      >
        <label>Target firmware documentation:</label>

        <SimpleDocumentationList :docs_list="target_firmware_docs_list" />

        <v-divider></v-divider>
      </div>

      <div>
        <label>Object documentation:</label>

        <div
          v-for="(item, i) in object_basic_docs_list"
          :key="i"
          class="mb-4 mt-2"
        >
          <v-icon small class="mr-1">
            {{ getTypeIcon(item.doc_type) }}
          </v-icon>

          <router-link
            :to="getViewUrlFor(item.id, getBasicDocumentCategory)"
            :target="disabled ? null : '_blank'"
            >{{ item.name }}</router-link
          ><span v-if="disabled">:</span>

          <template v-if="disabled">
            <div class="ml-15 mb-2 mt-1">
              <span v-if="isDocumentFile(item.doc_type)">
                <template v-if="null != item.content">
                  download the file
                  <a
                    href="#"
                    @click="downloadDocument($event, item.id)"
                    :file_attached="true"
                  >
                    {{ item.content }}
                  </a>
                </template>
              </span>

              <span v-if="isDocumentLink(item.doc_type)">
                <a target="_blank" :href="item.content"> {{ item.content }}</a>
              </span>

              <span v-if="isDocumentImage(item.doc_type)">
                <IconImage :image_src="item.content"></IconImage>
              </span>

              <span v-if="isDocumentText(item.doc_type)">
                <textarea
                  :rows="
                    null == item.content || item.content.length == 0 ? 1 : 5
                  "
                  v-model="item.content"
                  :disabled="true"
                />
              </span>
            </div>
          </template>

          <v-btn
            small
            v-if="!disabled"
            class="ml-3"
            @click="removeBasicDocument(item.id)"
            icon
          >
            <v-icon small>mdi-delete</v-icon>
          </v-btn>
        </div>
      </div>

      <div v-if="!disabled">
        Add document:
        <DropdownList
          :object_category="getBasicDocumentCategory"
          :team_id_filter="team_id_filter"
          @objectIsSelected="dropdownSelected($event)"
        />

        <span v-if="doc_is_already_in_collection" class="warning--text">
          The document is already in the collection.
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { getTypeIconStr } from "../../../utils/helpers";
import { getViewUrl, OBJECT_CATEGORIES } from "../../../utils/RoutesUtils";
import {
  BASIC_DOCUMENT_TYPES_ENUM,
  downloadDocumentFile,
  getFirmwareDocumentation,
  getGroupDocumentation,
  getTypeDocumentation
} from "../../../utils/ServerUtils";
import DropdownList from "./DropdownList.vue";
import IconImage from "../../../components/app/IconImage.vue";
import SimpleDocumentationList from "./SimpleDocumentationList.vue";
export default {
  name: "DocumentationInput",
  props: {
    team_id_filter: String,
    disabled: Boolean,
    preselected_id: String,
    doc_list_original: Array,
    type_id_to_load: String,
    group_id_to_load: String,
    target_firmware_id_to_load: String,
    current_firmware_id_to_load: String
  },
  data() {
    return {
      doc_is_already_in_collection: false,
      object_basic_docs_list: null,

      type_docs_list: null,
      group_docs_list: null,
      target_firmware_docs_list: null,
      current_firmware_docs_list: null
    };
  },

  mounted() {
    this.updateDocsListFromOriginal();
    this.loadTypeDocumentation();
    this.loadGroupDocumentation();
    this.loadTargetFirmwareDocumentation();
    this.loadCurrentFirmwareDocumentation();
  },

  watch: {
    doc_list_original() {
      this.updateDocsListFromOriginal();
    },

    type_id_to_load() {
      this.loadTypeDocumentation();
    },

    group_id_to_load() {
      this.loadGroupDocumentation();
    },

    target_firmware_id_to_load() {
      this.loadTargetFirmwareDocumentation();
    },

    current_firmware_id_to_load() {
      this.loadCurrentFirmwareDocumentation();
    }
  },

  computed: {
    getDocumentsCollectionCategory() {
      return OBJECT_CATEGORIES.DOCUMENTS_COLLECTION;
    },

    getBasicDocumentCategory() {
      return OBJECT_CATEGORIES.BASIC_DOCUMENT;
    },

    firmwaresAreEqualButNotNull() {
      return (
        this.current_firmware_id_to_load == this.target_firmware_id_to_load &&
        null != this.current_firmware_id_to_load
      );
    }
  },

  methods: {
    updateDocsListFromOriginal() {
      if (this.doc_list_original != null) {
        for (const doc of this.doc_list_original) {
          this.addBasicDocument(doc);
        }
      }

      // No need to show "already in collection"
      // messsage on the documents list first load
      this.doc_is_already_in_collection = false;
    },

    async loadTypeDocumentation() {
      if (null != this.type_id_to_load) {
        this.type_docs_list = await getTypeDocumentation(this.type_id_to_load);
      }
    },

    async loadGroupDocumentation() {
      if (null != this.group_id_to_load) {
        this.group_docs_list = await getGroupDocumentation(
          this.group_id_to_load
        );
      }
    },

    async loadTargetFirmwareDocumentation() {
      if (null != this.target_firmware_id_to_load) {
        this.target_firmware_docs_list = await getFirmwareDocumentation(
          this.target_firmware_id_to_load
        );
      }
    },

    async loadCurrentFirmwareDocumentation() {
      if (null != this.current_firmware_id_to_load) {
        this.current_firmware_docs_list = await getFirmwareDocumentation(
          this.current_firmware_id_to_load
        );
      }
    },

    objectIsSelected(object) {
      this.$emit("objectIsSelected", object);
    },

    dropdownSelected: function(object) {
      this.addBasicDocument(object);
    },

    addBasicDocument(object) {
      this.doc_is_already_in_collection = false;

      if (null != object) {
        if (this.object_basic_docs_list == null) {
          this.object_basic_docs_list = [];
        }

        // Check if the document is already in the list
        this.doc_is_already_in_collection = this.isDocumentAlreadyAdded(
          object.id
        );

        if (!this.doc_is_already_in_collection) {
          this.object_basic_docs_list.push(object);
        }
      }

      this.updated();
    },

    isDocumentAlreadyAdded(doc_id) {
      if (this.object_basic_docs_list == null) return false;

      for (var i = 0; i < this.object_basic_docs_list.length; i++) {
        if (this.object_basic_docs_list[i].id == doc_id) {
          return true;
        }
      }
      return false;
    },

    getViewUrlFor(object_id, object_category) {
      return getViewUrl(object_id, object_category);
    },

    removeBasicDocument(object_id) {
      var index_to_delete = -1;
      for (var i = 0; i < this.object_basic_docs_list.length; i++) {
        if (this.object_basic_docs_list[i].id == object_id) {
          index_to_delete = i;
        }
      }

      if (index_to_delete > -1) {
        this.object_basic_docs_list.splice(index_to_delete, 1);
      }

      this.updated();
    },

    updated() {
      this.$emit("updated", this.object_basic_docs_list);
    },

    getTypeIcon(type) {
      return getTypeIconStr(type);
    },

    isDocumentImage(type) {
      return BASIC_DOCUMENT_TYPES_ENUM.IMAGE == type;
    },

    isDocumentFile(type) {
      return BASIC_DOCUMENT_TYPES_ENUM.FILE == type;
    },

    isDocumentLink(type) {
      return BASIC_DOCUMENT_TYPES_ENUM.LINK == type;
    },

    isDocumentText(type) {
      return BASIC_DOCUMENT_TYPES_ENUM.TEXT == type;
    },

    downloadDocument: function(e, doc_id) {
      e.preventDefault();
      downloadDocumentFile(doc_id);
    }
  },

  components: { DropdownList, IconImage, SimpleDocumentationList }
};
</script>
