<template>
  <a @click="iconImageClicked($event)">
    <img
      :src="image_src"
      :class="small_icon ? 'small-icon' : 'ml-3 mb-2 image align-middle'"
    />
  </a>
</template>

<script>
export default {
  name: "IconImage",

  props: {
    image_src: String,
    small_icon: {
      default: false,
      type: Boolean,
    },
  },

  methods: {
    iconImageClicked: function (event) {
      if (null != event && null != event.target && null != event.target.src)
        window.open(event.target.src);
    },
  },
};
</script>