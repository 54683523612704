<template>
  <div v-if="null != used_in" class="sub-container grey--text">
    Used in the following

    <div class="mt-5">
      <ObjectsListItem
        :objects_list="used_in.types"
        :object_category="getTypeCategory"
      />

      <ObjectsListItem
        :objects_list="used_in.firmwares"
        :object_category="getFirmwareCategory"
      />

      <ObjectsListItem
        :objects_list="used_in.groups"
        :object_category="getGroupCategory"
      />

      <ObjectsListItem
        :objects_list="used_in.devices"
        :object_category="getDeviceCategory"
      />
    </div>
  </div>
</template>

<script>
import { OBJECT_CATEGORIES } from "../../../utils/RoutesUtils";
import ObjectsListItem from "./ObjectsListItem.vue";
export default {
  props: {
    used_in: {
      default: null,
      type: Object,
    },
  },

  computed: {
    getGroupCategory() {
      return OBJECT_CATEGORIES.GROUP;
    },

    getTypeCategory() {
      return OBJECT_CATEGORIES.TYPE;
    },

    getFirmwareCategory() {
      return OBJECT_CATEGORIES.FIRMWARE;
    },

    getDeviceCategory() {
      return OBJECT_CATEGORIES.DEVICE;
    },
  },
  components: { ObjectsListItem },
};
</script>

<style></style>
