<template>
  <div class="sub-container">
    <v-switch
      class="ma-0"
      v-model="settings_open"
      label="Advanced settings for label generation"
      hide-details
    />

    <div v-if="settings_open">
      <label>Translate label on x (inches):</label>
      <input type="text" v-model="translate_x" />

      <br />

      <label>Translate label on y (inches):</label>
      <input type="text" v-model="translate_y" />

      <div class="sub-container">
        <v-switch
          class="ma-0"
          v-model="use_custom_label"
          label="Use custom label template"
          hide-details
        />

        <div v-if="use_custom_label">
          <textarea
            v-model="template_str"
            rows="8"
            />

          <br />

          <input-info
            msg="
            You can enter any valid SVG string here. 
            You can also use any provisioning key as a Python template key 
            (e.g. $serial_number will be replaced by the corresponding value of the provisioning JSON). 
            Three additional variables are available: $translate_x_in, $translate_y_in and $qrcode_rectangles."
          />

          <base-text-button
            text="Download custom label"
            @click="download_custom_device_label"
          />
        </div>
      </div>
    </div>
    
    <base-text-button
      text="Download detailed label"
      @click="download_detailed_device_label"
    />

    <base-text-button
      text="Download model label"
      @click="download_model_device_label"
    />
  </div>
</template>

<script>

const TEMPLATE_EXAMPLE = `<!-- SVG Python template string: -->
            
<svg width="55mm" height="23mm" viewBox="0 0 55 23">
    <text x="0" y="14">
        $serial_number
    </text>
</svg>`

import BaseButton from "../../../components/app/BaseButton.vue";
import InputInfo from "../../../components/app/InputInfo.vue";
import {
  downloadDeviceLabel,
  downloadDeviceLabelCustomTemplate
} from "../../../utils/ServerUtils";
export default {
  components: { BaseButton, InputInfo },
  props: {
    device_id: String
  },
  data() {
    return {
      settings_open: false,
      translate_x: 0,
      translate_y: 0,
      use_custom_label: false,
      template_str: TEMPLATE_EXAMPLE
    };
  },
  methods: {
    download_detailed_device_label() {
      downloadDeviceLabel(
        this.device_id,
        this.translate_x,
        this.translate_y,
        true
      );
    },

    download_model_device_label() {
      downloadDeviceLabel(
        this.device_id,
        this.translate_x,
        this.translate_y,
        false
      );
    },

    download_custom_device_label() {
      if (this.template_str == null || this.template_str.length == 0) {
        alert("Custom template string cannot be empty.");
        return;
      }

      downloadDeviceLabelCustomTemplate(
        this.device_id,
        this.translate_x,
        this.translate_y,
        this.template_str
      );
    }
  }
};
</script>
