<template>
  <div class="sub-container grey--text">
    <label class="pb-0">Is the target of:</label>

    <a v-if="list_to_show.length > 0" href="#" @click="downloadListAsCsv()">
      <v-icon color="blue"> mdi-download </v-icon>
    </a>

    <table>
      <tbody>
        <tr v-for="(object, key) in list_to_show" :key="key">
          <td>
            <v-icon> {{ icon(object.type) }} </v-icon>
            {{ object.type }}
          </td>
          <router-link :to="getViewTargetUrl(object)">
            <td>{{ object.name }}</td>
          </router-link>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import {
  getDeviceCategory,
  getGroupCategory,
  getViewUrl
} from "../../../utils/RoutesUtils";

import { exportToCsv } from "../../../utils/helpers.js";
import { formatDate } from "../../../utils/DateUtils";

const TYPES = {
  DEVICE: "Device",
  GROUP: "Group"
};

export default {
  name: "TargetsTable",

  props: {
    firmware_name: {
      type: String,
      default: null
    },
    firmware_version: {
      type: String,
      default: null
    },
    is_target_of: {
      type: Array,
      default: () => []
    },
    devices_group: {
      type: Array,
      default: () => []
    }
  },

  computed: {
    list_to_show: function() {
      var arr = [];

      // Add target devices
      arr = this.addTargetObjects(arr, TYPES.DEVICE);

      // Add target groups
      arr = this.addTargetObjects(arr, TYPES.GROUP);

      return arr;
    }
  },

  methods: {
    icon: function(type) {
      switch (type) {
        case TYPES.DEVICE:
          return "mdi-devices";
        case TYPES.GROUP:
          return "mdi-group";
        default:
          return "";
      }
    },

    addTargetObjects: function(arr, type) {
      var list = [];

      switch (type) {
        case TYPES.DEVICE:
          list = this.is_target_of;
          break;
        case TYPES.GROUP:
          list = this.devices_group;
      }

      if (null != list) {
        for (let i = 0; i < list.length; i++) {
          arr.push({
            type: type,
            name: list[i].name,
            id: list[i].id
          });
        }
      }

      return arr;
    },

    getViewTargetUrl: function(object) {
      switch (object.type) {
        case TYPES.DEVICE:
          return getViewUrl(object.id, getDeviceCategory());
        case TYPES.GROUP:
          return getViewUrl(object.id, getGroupCategory());
      }
      return "#";
    },

    downloadListAsCsv() {
      const rows = [];
      const headers = ["Type", "Name", "ID"];
      rows.push(headers);
      for (let i = 0; i < this.list_to_show.length; i++) {
        const row = [];
        row.push(this.list_to_show[i].type);
        row.push(this.list_to_show[i].name);
        row.push(this.list_to_show[i].id);
        rows.push(row);
      }

      const date_str = formatDate(new Date());
      const fileName =
        "targets_" +
        this.firmware_name +
        "_" +
        this.firmware_version +
        "_" +
        date_str +
        ".csv";
      exportToCsv(fileName, rows);
    }
  }
};
</script>

<style scoped>
table {
  margin: 0;
}

.sub-container {
  margin: 0;
  margin-bottom: 1em;
}
</style>
