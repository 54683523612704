<template>
  <v-row v-if="objects_list != null && objects_list.length > 0">
    <v-col cols="2" class="mt-3">{{ getTitle }}:</v-col>
    <v-col cols="10">
      <v-list class="pt-0">
        <v-list-item
          class="pt-0"
          v-for="(object, key) in objects_list"
          :key="key"
        >
          <router-link :to="getViewTargetUrl(object)">
            {{ object.name }}
          </router-link>
        </v-list-item>
      </v-list>
    </v-col>
  </v-row>
</template>

<script>
import { getObjectHumanName, getViewUrl } from "../../../utils/RoutesUtils";

export default {
  props: {
    objects_list: {
      default: null,
      type: Array,
    },
    object_category: Number,
  },
  computed: {
    getTitle() {
      return getObjectHumanName(this.object_category) + "s";
    },
  },
  methods: {
    getViewTargetUrl(object) {
      if (null != object) return getViewUrl(object.id, this.object_category);

      return null;
    },
  },
};
</script>

<style></style>
