<template>
  <span v-html="customToString"></span>
</template>

<script>
import { getCustomFieldType } from '../../utils/helpers';

export default {
  name: "CustomFieldsView",

  props: {
    custom: String,
  },

  computed: {
    customToString() {
      var custom_str = "";
      try {
        const fields = JSON.parse(this.custom);
        if (null != fields) {
          custom_str += "<ul> ";
          for (var i = 0; i < fields.length; i++) {
            custom_str += ' <li> ';
            custom_str += fields[i].name + " (" + getCustomFieldType(fields[i]) + "): " + fields[i].value;
            custom_str += ' </li> ';
          }
          custom_str += " </ul>";
        }
      } catch (e) {
        custom_str = this.custom;
      }
      return custom_str;
    },
  },
};
</script>