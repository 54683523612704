<template>
  <div>
    Provisioning information:
    <br />

    <p class="font-italic" v-if="null == provisioning_json">Not provided.</p>
    <vue-json-pretty v-else :data="provisioning_json"> </vue-json-pretty>
  </div>
</template>

<script>
import VueJsonPretty from "vue-json-pretty";
import "vue-json-pretty/lib/styles.css";

export default {
  components: {
    VueJsonPretty
  },

  props: {
    device: Object
  },

  computed: {
    provisioning_json() {
      if (null == this.device) return {};
      try {
        return JSON.parse(this.device.provisioning);
      } catch (error) {
        return this.device.provisioning;
      }
    }
  }
};
</script>

<style>
.vjs-value__string {
  color: #219310;
}
</style>
