<!--
This template generates pages for:
  * creation, 
  * edition,
  * display,
of the objects of the following types:
  * device,
  * firmware,
  * type,
  * group.
  
This Vue compoent is used only (normally) for the
pages with the following URL syntax:
http://{{ip_addr}}:{{port}}/[OBJECT_URL_NAME]_[PAGE]
or
http://{{ip_addr}}:{{port}}/[OBJECT_URL_NAME]_[PAGE]?id=[OBJECT_ID]
example :
http://127.0.0.1:1600/group_new

OBJECT_URL_NAMES available are defined in the vue.config.js file.
They should not contain underscores.
-->
<template>
  <v-container
    fluid
    tag="section"
    class="mt-10"
    :class="{ 'obj-view': isView }"
  >
    <v-row>
      <v-col
        :cols="is_child_component ? null : 12"
        :md="is_child_component ? null : 8"
      >
        <material-card
          :color="object_is_disabled ? 'red' : 'primary'"
          full-header
        >
          <template #heading>
            <div
              class="white--text"
              :class="{
                'pa-8': !is_child_component,
                'pa-3': is_child_component,
              }"
            >
              <div
                :class="{
                  'text-h4': !is_child_component,
                  'text-h5': is_child_component,
                }"
              >
                {{ getTitleText }}
                <span class="font-weight-bold">{{ object_name }}</span>

                <v-btn
                  v-if="isView"
                  color="white"
                  elevation="1"
                  icon
                  @click="reloadData()"
                  class="ml-5"
                >
                  <v-icon> mdi-refresh </v-icon>
                </v-btn>
              </div>
            </div>
          </template>

          <v-card-text class="mt-5 pb-7">
            <div
              class="text-center"
              v-if="isDocument && isAddNew && null == doc_global_type"
            >
              <p class="text-h3 black--text">
                What kind of document type do you want to create?
              </p>
              <div>
                <base-button
                  text="Document"
                  class="mr-2"
                  @click="set_doc_global_type(true)"
                />
                <base-button
                  text="Collection of documents"
                  @click="set_doc_global_type(false)"
                />
              </div>
            </div>

            <div v-else :class="{ 'sub-container': is_child_component }">
              <div
                v-if="
                  !is_child_component && isView && is_user_admin_in_object_team
                "
                style="position: fixed; margin-left: -0.2em"
              >
                <v-btn small color="primary" dark fab :to="getEditUrl">
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </div>

              <div
                v-if="isAddNew && isBasicDocument && basic_doc_type == null"
                class="ml-5 mt-5 text-h4"
              >
                <label>Choose a basic document type: </label>
                <select
                  :disabled="!isEditable"
                  v-model="basic_doc_type"
                  @change="generateAutomaticObjectName()"
                >
                  <option
                    v-for="(object, key) in basic_documents_types"
                    :key="key"
                    :value="object.id"
                  >
                    {{ object.name }}
                  </option>
                </select>
              </div>

              <form
                v-else
                :class="{ 'mt-5': !is_child_component }"
                ref="form"
                @submit="processForm"
              >
                <p v-if="isDocument" class="text-h3">
                  {{
                    isBasicDocument
                      ? "Document of type " + basic_doc_type_name
                      : "Documents collection"
                  }}

                  <v-btn
                    v-if="isAddNew"
                    class="ml-2"
                    @click="reloadDocumentType()"
                    icon
                  >
                    <v-icon>mdi-reload</v-icon>
                  </v-btn>
                </p>

                <div v-if="isDocument">
                  <template v-if="isView">
                    <span
                      v-if="
                        object_loaded['target_class'] == null ||
                        object_loaded['target_class'] == 'other'
                      "
                    >
                      No target class
                    </span>
                    <template v-else>
                      <label>Target class:</label>

                      <input
                        v-if="isView"
                        :disabled="true"
                        :value="object_loaded['target_class']"
                      />
                    </template>
                  </template>
                  <template v-else>
                    <label>Target class:</label>
                    <select
                      :disabled="!isEditable"
                      v-model="object_target_class"
                    >
                      <option value="other"></option>
                      <option value="device">Device</option>
                      <option value="type">Type</option>
                      <option value="firmware">Firmware</option>
                      <option value="group">Group</option>
                    </select>
                  </template>
                </div>

                <div class="sub-container">
                  <label>Team:</label>

                  <input
                    v-if="isView"
                    :disabled="true"
                    :value="
                      null != object_loaded['team']
                        ? object_loaded['team']['name']
                        : null
                    "
                  />

                  <DropdownList
                    v-else
                    ref="teams_dropdown"
                    :object_category="getTeamCategory"
                    :disabled="
                      !isEditable ||
                      is_child_component ||
                      team_can_not_be_changed
                    "
                    :preselected_id="
                      is_child_component ? preselected_team_id : object_team_id
                    "
                    :class="{ 'display-inline': is_child_component }"
                    @objectIsSelected="dropdownSelected($event, 'team_id')"
                    :required="true"
                    @objectsListLoaded="teams_list_loaded = true"
                  />

                  <input-info
                    v-if="isEdit && isFirmware && team_can_not_be_changed"
                    msg="The firmware is already used by other groups or devices. The team cannot be changed because a change of team would imply a change of type."
                  />

                  <input-info
                    v-if="isEdit && isGroup && team_can_not_be_changed"
                    msg="The group contains devices. The team cannot be changed because a change of team would imply a change of type."
                  />

                  <input-info
                    v-if="isEdit && isType && team_can_not_be_changed"
                    msg="There are devices, groups or firmwares of the current type. The team cannot be changed."
                  />
                </div>

                <div class="sub-container">
                  <div v-if="isFirmware && !is_child_component">
                    <div class="d-flex align-center mb-5">
                      <div class="pr-2 pt-2 d-inline-block">Enabled</div>
                      <v-switch
                        color="red"
                        class="ma-0 d-inline-block"
                        v-model="object_is_disabled"
                        label="Disabled"
                        hide-details
                        :disabled="
                          !isEditable || is_used_as_target_firmware_of_device
                        "
                      />
                    </div>

                    <input-info
                      v-if="isEditable && is_used_as_target_firmware_of_device"
                      msg="Cannot be disabled because the firmware is used as target firmware for at least one device."
                    />
                  </div>

                  <div
                    v-if="
                      (!is_child_component ||
                        child_component_show_hidden_fields) &&
                      !isDocument
                    "
                  >
                    <label>Icon:</label>
                    <file-uploader
                      @load="image_src = $event"
                      v-if="isEditable"
                      :is_image="true"
                      :file_attached="image_src != null"
                    />
                    <icon-image :image_src="image_src" />
                  </div>

                  <div v-if="isDevice && null != type_image_src">
                    <label>Type icon:</label>

                    <icon-image
                      v-if="null != type_image_src"
                      :image_src="type_image_src"
                    />
                  </div>

                  <div v-if="!is_child_component && !isAddNew">
                    <label>PUCS ID:</label>

                    <input
                      type="text"
                      id="id"
                      :value="object_id"
                      :disabled="true"
                      class="min-width-25em"
                    />
                  </div>

                  <div
                    v-if="
                      (!is_child_component ||
                        child_component_show_hidden_fields) &&
                      !isAddNew &&
                      null != object_loaded['author'] &&
                      null != object_loaded['author']['username']
                    "
                  >
                    <label>Owner:</label>

                    <input
                      type="text"
                      :value="object_loaded['author']['username']"
                      :disabled="true"
                    />
                  </div>

                  <div
                    v-if="
                      !is_child_component &&
                      !isAddNew &&
                      (isDevice || isFirmware)
                    "
                  >
                    <label>Creation date:</label>

                    <input
                      type="text"
                      id="date"
                      :value="formatDate(object_loaded['date'], false)"
                      :disabled="true"
                    />
                  </div>

                  <div>
                    <label>Name:</label>
                    <input
                      type="text"
                      id="name"
                      minlength="1"
                      v-model="object_name"
                      :disabled="!isEditable"
                      @change="validateName"
                    />
                  </div>

                  <div v-if="isType">
                    <label class="pb-0 pt-3"
                      >Name of a unique ID by type:
                    </label>
                    <br />
                    <input
                      class="ml-0 min-width-35em"
                      type="text"
                      id="name_of_uid"
                      minlength="1"
                      v-model="object_name_of_uid"
                      :disabled="!isEditable"
                      placeholder="For example: Mac address, Serial number of a microcontroller, etc."
                    />
                  </div>

                  <div v-if="isFirmware">
                    <label>Version:</label>
                    <input
                      type="text"
                      id="version"
                      v-model="object_version"
                      :disabled="
                        !isEditable || is_used_as_target_firmware_of_device
                      "
                      @change="validateName"
                    />

                    <input-info
                      v-if="isEditable && is_used_as_target_firmware_of_device"
                      msg="Cannot be changed because the firmware is used as target firmware for at least one device."
                    />

                    <input-info v-else-if="isEditable" msg="Must use semver." />
                  </div>

                  <div v-if="isBasicDocument">
                    <template v-if="isDocumentIcon">
                      <label>Image:</label>
                      <file-uploader
                        ref="document_image_input"
                        @load="object_content = $event"
                        v-if="isEditable"
                        :is_image="true"
                        :file_attached="object_content != null"
                      />
                      <icon-image :image_src="object_content" />
                    </template>

                    <template v-else-if="isDocumentFile">
                      <label>File:</label>
                      <template v-if="null != object_content">
                        <a
                          class="d-inline-block ma-3"
                          href="#"
                          @click="downloadDocument($event)"
                          :file_attached="object_content != null"
                        >
                          {{ object_content }}
                        </a>
                      </template>

                      <template v-if="isEditable">
                        <file-uploader
                          ref="document_file_input"
                          @load="documentFileLoaded"
                          v-if="isEditable"
                          :file_attached="document_file != null"
                        />
                      </template>
                    </template>

                    <template v-else>
                      <label v-if="isDocumentLink" class="mt-2">Link:</label>
                      <label v-else>Text content:</label>

                      <a
                        v-if="isDocumentLink && isView"
                        class="ml-2"
                        target="_blank"
                        :href="object_content"
                        >{{ object_content }}</a
                      >

                      <textarea
                        v-else
                        ref="text_content"
                        type="text"
                        minlength="1"
                        v-model="object_content"
                        :disabled="!isEditable"
                        @change="validateForm"
                        :rows="
                          null == object_content || 0 == object_content.length
                            ? 1
                            : 5
                        "
                      />
                    </template>
                  </div>

                  <div v-if="hasType">
                    <label>Type:</label>

                    <span v-if="!isView && !teams_list_loaded" class="my-5">
                      <v-progress-circular class="mr-2" indeterminate />
                      Waiting for the team list to be loaded
                    </span>

                    <template v-else>
                      <template v-if="isView">
                        <input :disabled="true" v-model="object_type_name" />

                        <router-link
                          v-if="null != object_type_id"
                          :to="getViewUrlFor(object_type_id, getTypeCategory)"
                        >
                          <v-icon class="negative-margin"> mdi-magnify </v-icon>
                        </router-link>
                      </template>

                      <DropdownList
                        v-else
                        ref="types_dropdown"
                        :object_category="getTypeCategory"
                        :disabled="
                          !isEditable ||
                          is_child_component ||
                          type_can_not_be_changed
                        "
                        :required="true"
                        :preselected_id="
                          is_child_component
                            ? preselected_type_id
                            : object_type_id
                        "
                        :team_id_filter="object_team_id"
                        @objectIsSelected="dropdownSelected($event, 'type_id')"
                        :class="{ 'display-inline': is_child_component }"
                        @objectsListLoaded="types_list_loaded = true"
                      />

                      <base-button
                        v-if="
                          !is_child_component &&
                          isEditable &&
                          !type_can_not_be_changed
                        "
                        text="Add a new type"
                        :disabled="null == object_team_id"
                        class="ml-7"
                        @click="
                          add_type_component_is_visible =
                            !add_type_component_is_visible
                        "
                      />

                      <input-info
                        v-if="null == object_team_id"
                        msg="A team must be selected first."
                      />

                      <input-info
                        v-else-if="
                          isEdit && type_can_not_be_changed && isFirmware
                        "
                        msg="The type cannot be changed because the firmware is already used by other groups or devices."
                      />

                      <input-info
                        v-else-if="isEdit && type_can_not_be_changed && isGroup"
                        msg="The type cannot be changed because the group contains devices of the current type."
                      />
                    </template>
                  </div>

                  <object-view
                    class="mw-em-wide"
                    :is_child_component="true"
                    :object_category_in="getTypeCategory"
                    :preselected_team_id="object_team_id"
                    v-if="
                      hasType &&
                      !is_child_component &&
                      add_type_component_is_visible &&
                      isEditable
                    "
                    @newObjectIsAddedFromChild="newTypeIsAdded"
                    @child_component_is_closed="
                      add_type_component_is_visible = false
                    "
                  />

                  <div v-if="isDevice">
                    <label class="pb-0 pt-3">
                      {{ name_of_uid }}
                      <span v-if="null != object_type_name" class="font-italic">
                        (unique ID for type "{{ object_type_name }}")</span
                      >:
                    </label>
                    <br />
                    <input
                      class="ml-0 min-width-35em"
                      type="text"
                      id="uid_for_type"
                      minlength="1"
                      v-model="object_uid_for_type"
                      :disabled="!isEditable"
                      @change="validateUidForType"
                    />
                  </div>

                  <div v-if="isDevice">
                    <label>Group:</label>

                    <span v-if="!isView && !types_list_loaded" class="my-5">
                      <v-progress-circular class="mr-2" indeterminate />
                      Waiting for the types list to be loaded
                    </span>

                    <template v-else>
                      <template v-if="isView">
                        <input
                          :disabled="true"
                          :value="
                            null != object_loaded['group']
                              ? object_loaded['group']['name']
                              : null
                          "
                        />

                        <router-link
                          v-if="null != object_group_id"
                          :to="getViewUrlFor(object_group_id, getGroupCategory)"
                        >
                          <v-icon class="negative-margin"> mdi-magnify </v-icon>
                        </router-link>
                      </template>

                      <DropdownList
                        v-else
                        ref="groups_dropdown"
                        :object_category="getGroupCategory"
                        :disabled="!isEditable"
                        :type_id_filter="object_type_id"
                        :preselected_id="object_group_id"
                        @objectIsSelected="dropdownSelected($event, 'group_id')"
                        :class="{ 'display-inline': is_child_component }"
                      />

                      <base-button
                        v-if="!is_child_component && isEditable"
                        text="Add a new group"
                        :disabled="
                          null == object_type_id || null == object_team_id
                        "
                        class="ml-7"
                        @click="
                          add_group_component_is_visible =
                            !add_group_component_is_visible
                        "
                      />

                      <input-info
                        v-if="null == object_type_id"
                        msg="A type must be selected first."
                      />
                      <input-info
                        v-else-if="null == object_team_id"
                        msg="A team must be selected first."
                      />
                    </template>
                  </div>

                  <object-view
                    class="mw-em-wide"
                    :is_child_component="true"
                    :object_category_in="getGroupCategory"
                    v-if="
                      isDevice &&
                      !is_child_component &&
                      add_group_component_is_visible &&
                      isEditable
                    "
                    :preselected_type_id="object_type_id"
                    :preselected_team_id="object_team_id"
                    @newObjectIsAddedFromChild="newGroupIsAdded"
                    @child_component_is_closed="
                      add_group_component_is_visible = false
                    "
                  />

                  <div v-if="isDevice && !isAddNew && !is_child_component">
                    <label> Current firmware: </label>

                    <input
                      v-if="
                        hasFirmware && object_loaded['current_firmware'] == null
                      "
                      type="text"
                      value=""
                      :disabled="true"
                    />

                    <template v-else>
                      <input
                        type="text"
                        :value="
                          null != object_loaded['current_firmware']
                            ? object_loaded['current_firmware'].name +
                              ' (' +
                              object_loaded['current_firmware'].version +
                              ')'
                            : ''
                        "
                        :disabled="true"
                      />

                      <router-link
                        v-if="null != object_loaded['current_firmware']"
                        :to="
                          getViewUrlFor(
                            object_loaded['current_firmware'].id,
                            getFirmwareCategory
                          )
                        "
                      >
                        <v-icon class="negative-margin"> mdi-magnify </v-icon>
                      </router-link>
                    </template>

                    <actual-firmware-icon
                      class="ml-6"
                      :current_firmware_id="
                        object_loaded['current_firmware_id']
                      "
                      :target_firmware_id="object_target_firmware_id"
                    />
                  </div>

                  <div v-if="hasFirmware">
                    <label>Target firmware:</label>

                    <span v-if="!isView && !types_list_loaded" class="my-5">
                      <v-progress-circular class="mr-2" indeterminate />
                      Waiting for the types list to be loaded
                    </span>

                    <template v-else>
                      <template v-if="isView">
                        <input
                          :disabled="true"
                          :value="
                            null != object_loaded['target_firmware']
                              ? object_loaded['target_firmware'].name +
                                ' (' +
                                object_loaded['target_firmware'].version +
                                ')'
                              : null
                          "
                        />
                        <router-link
                          v-if="null != object_loaded['target_firmware']"
                          :to="
                            getViewUrlFor(
                              object_loaded['target_firmware_id'],
                              getFirmwareCategory
                            )
                          "
                        >
                          <v-icon class="negative-margin"> mdi-magnify </v-icon>
                        </router-link>
                      </template>

                      <DropdownList
                        v-else
                        :object_category="getFirmwareCategory"
                        :disabled="!isEditable || groupIsChoosenForDevice"
                        :parent_is_device_edit="isDevice && isEditable"
                        :type_id_filter="object_type_id"
                        :preselected_id="object_target_firmware_id"
                        @objectIsSelected="
                          dropdownSelected($event, 'target_firmware_id')
                        "
                      />
                    </template>
                  </div>

                  <div v-if="isFirmware">
                    <div v-if="firmware_file_is_uploaded">
                      <label>File:</label>
                      <a
                        class="d-inline-block ma-3"
                        href="#"
                        @click="downloadFirmwareFile($event)"
                      >
                        {{ object_filename }}
                      </a>

                      <input-info
                        v-if="isEditable"
                        msg="A firmware file cannot be modified after it has been uploaded to the server."
                        class="d-inline-block"
                      />
                    </div>

                    <div v-if="isEditable && !is_firmware_file_uploaded">
                      <label>File to upload:</label>
                      <file-uploader
                        ref="firmware_file"
                        @load="firmwareFileLoaded"
                        :file_attached="firmware_file != null"
                      />
                    </div>

                    <div>
                      <label>Size:</label>
                      <input
                        id="size"
                        type="text"
                        :value="object_size"
                        :disabled="true"
                      />
                    </div>
                  </div>
                </div>

                <div
                  v-if="
                    hasDocumentation &&
                    (!is_child_component || child_component_show_hidden_fields)
                  "
                  class="sub-container"
                >
                  <DocumentationInput
                    :team_id_filter="object_team_id"
                    :disabled="!isEditable"
                    @updated="object_basic_docs_list = $event"
                    :doc_list_original="object_basic_docs_list"
                    :type_id_to_load="object_type_id"
                    :group_id_to_load="object_group_id"
                    :target_firmware_id_to_load="object_target_firmware_id"
                    :current_firmware_id_to_load="
                      null != object_loaded
                        ? object_loaded.current_firmware_id
                        : null
                    "
                  />
                </div>

                <targets-table
                  v-if="isFirmware && isView"
                  :firmware_name="object_name"
                  :firmware_version="object_version"
                  :is_target_of="object_loaded['is_target_of']"
                  :devices_group="object_loaded['devices_group']"
                />

                <currently-on-table
                  v-if="isFirmware && isView"
                  :firmware_name="object_name"
                  :firmware_version="object_version"
                  :is_currently_on="object_loaded['is_currently_on']"
                />

                <div v-if="isView && isType">
                  <div class="sub-container">
                    <firmwares-of-type-table
                      :firmwares="object_loaded['firmwares']"
                    />
                  </div>

                  <div class="sub-container">
                    <list-of-groups-of-type
                      v-if="isView && isType && null != object_loaded"
                      :devices_groups="
                        null == object_loaded['devices_groups']
                          ? []
                          : object_loaded['devices_groups']
                      "
                    />
                  </div>

                  <div class="sub-container">
                    <list-of-devices
                      v-if="isView && isType"
                      :devices="null == object_devices ? [] : object_devices"
                    />
                  </div>
                </div>

                <div class="sub-container" v-if="isDevice">
                  <label
                    v-if="
                      !isAddNew &&
                      (null == object_provisioning_str ||
                        object_provisioning_str.length == 0)
                    "
                  >
                    Provisioning information is not provided.</label
                  >

                  <label v-else>Provisioning information</label>

                  <div>
                    <textarea
                      :rows="5"
                      class="column wide"
                      type="text"
                      v-model="object_provisioning_str"
                      v-if="isAddNew || provisioning_edit_enabled"
                    />

                    <template
                      v-else-if="
                        !isAddNew &&
                        null != object_provisioning_str &&
                        object_provisioning_str.length > 0
                      "
                    >
                      <download-json
                        class="mb-2"
                        :json_to_download="provisioning_json"
                        :filename="'provisioning_' + object_name"
                      />
                      
                      JSON:
                      <vue-json-pretty :deep="0" :data="provisioning_json" />
                    </template>

                    <template v-if="isEdit && !provisioning_edit_enabled">
                      <v-checkbox
                        v-model="provisioning_edit_checkbox_value"
                        hide-details="true"
                        label="Yes, I understand that the provisioning information must not be changed after the device has been provisioned. I take full responsibility for changing the provisioning information."
                      />

                      <base-button
                        text="Allow editing"
                        @click="alow_editing_provisioning_data"
                      />
                    </template>
                    <template v-else-if="isEdit && provisioning_edit_enabled">
                      <p class="red--text">
                        You have authorised the modification of the provisioning
                        information.
                      </p>
                    </template>
                  </div>
                </div>

                <CustomFieldsFromType
                  v-if="isDevice"
                  :value_json="object_custom_fields_from_type"
                  :disabled="!isEditable"
                  @change="object_custom_fields_from_type = $event"
                />

                <custom-fields
                  v-if="
                    isType &&
                    (!is_child_component || child_component_show_hidden_fields)
                  "
                  :disabled="!isEditable"
                  :value_json="object_custom_fields_devices"
                  @change="object_custom_fields_devices = $event"
                />

                <div class="sub-container" v-if="isDevice">
                  <label class="pb-0">Configuration</label>
                  <br />

                  <p v-if="conflict_resolved" class="black--text">
                    The conflict has been resolved. Do not forget to
                    <span class="font-weight-bold">
                      click on the "Submit" button</span
                    >!
                  </p>

                  <config-sync-state
                    v-if="!isAddNew"
                    :config_state="object_config_state"
                    :last_config_sync="object_loaded['last_config_sync']"
                  />

                  <textarea
                    id="config_textarea"
                    class="column wide"
                    :rows="null == config_str || config_str.length == 0 ? 1 : 5"
                    type="text"
                    v-model="config_str"
                    :disabled="!isEditable || conflict_resolved"
                    v-if="(isAddNew || isEdit) && !isConfigInConflictState"
                    @change="configChanged($event)"
                  />

                  <template v-else>
                    <v-row>
                      <v-col :cols="isConfigInConflictState ? 6 : 12">
                        <label v-if="isConfigInConflictState"
                          >User configuration (PUCS)
                        </label>
                        <template v-if="null != configuration_json">
                          <download-json
                            class="mb-2"
                            :json_to_download="configuration_json"
                            :filename="'user_configuration_' + object_name"
                          />
                          
                          JSON:
                          <vue-json-pretty
                            :data="configuration_json"
                            :deep="0"
                          />
                        </template>
                        <p class="font-italic" v-else>None</p>

                        <base-button
                          v-if="isEdit"
                          class="mt-2"
                          text="Use the PUCS config"
                          @click="resolveConflictPucs()"
                        />
                      </v-col>
                      <v-col v-if="isConfigInConflictState" cols="6">
                        <label>Device configuration</label>
                        <template v-if="null != last_config_on_device_json">
                          <download-json
                            class="mb-2"
                            :json_to_download="last_config_on_device_json"
                            :filename="'configuration_on_device_' + object_name"
                          />
                          
                          JSON:
                          <vue-json-pretty
                            :data="last_config_on_device_json"
                            :deep="0"
                          />
                        </template>
                        <p class="font-italic" v-else>None</p>

                        <base-button
                          v-if="isEdit"
                          class="mt-2"
                          text="Use the DEVICE config"
                          @click="resolveConflictDevice()"
                        />
                      </v-col>
                    </v-row>
                  </template>
                </div>

                <div class="sub-container" v-if="isDevice">
                  <label class="pb-0">Configuration history:</label>

                  <div
                    v-for="(config, key) in config_history_list"
                    :key="key"
                    class="my-1"
                  >
                    {{ config.date }}
                    <base-text-button
                      v-if="!config.config_loaded"
                      @click="getConfiguration(config.id)"
                      text="Open the configuration"
                    />

                    <template v-else>
                      <base-text-button
                        @click="config.config_loaded = false"
                        text="Close the configuration"
                      />
                      <vue-json-pretty :data="config.json" />
                    </template>
                  </div>
                </div>

                <base-text-button
                  :text="
                    child_component_show_hidden_fields
                      ? 'Hide optional fields'
                      : 'Show hidden fields'
                  "
                  v-if="is_child_component"
                  @click="
                    child_component_show_hidden_fields =
                      !child_component_show_hidden_fields
                  "
                />

                <history-table
                  v-if="isView && isDevice"
                  :last_seen="object_loaded['last_seen']"
                  :histories="object_loaded['histories']"
                />

                <div
                  v-if="(isView || isEdit) && isGroup"
                  class="sub-container grey--text"
                >
                  <devices-in-group
                    :devices="object_devices"
                    :group_id="object_id"
                    :group_name="object_name"
                    :type_id="object_type_id"
                    :type_name="object_type_name"
                    :disabled="isView"
                    @devices_list_is_changed="updateDevicesList()"
                  />
                </div>

                <input-info
                  v-if="isGroup && isAddNew && !is_child_component"
                  msg="Devices can be assigned to the group after its creation."
                  class="d-block"
                />

                <div
                  v-if="
                    !is_child_component || child_component_show_hidden_fields
                  "
                  class="sub-container"
                >
                  <label>Notes:</label>

                  <textarea
                    class="my-0"
                    :rows="
                      null == object_notes || 0 == object_notes.length ? 1 : 5
                    "
                    type="text"
                    id="notes"
                    v-model="object_notes"
                    :disabled="!isEditable"
                  />
                </div>

                <div v-if="isDevice && !isAddNew" class="mt-5">
                  <div class="my-5">
                    <div class="sub-container">
                      <p>
                        Device QR code:
                        <router-link
                          v-if="null != device_qr_code"
                          :to="device_qr_code"
                          >{{ device_qr_code }}
                        </router-link>
                        <base-button
                          v-else-if="!isView"
                          class="ml-2"
                          text="Generate QR code"
                          @click="generateQrCode"
                        />
                        <span v-else class="font-italic">
                          Enter the edit mode to generate a QR code.
                        </span>
                      </p>

                      <template v-if="object_to_be_claimed == true">
                        <p>
                          The device is intended to be claimed. Anyone with
                          access to a QR code of the device and its claim code
                          can claim ownership.
                        </p>
                        <p>
                          Claim code:
                          <span class="accent pa-2 black--text">{{
                            object_claim_code
                          }}</span>
                        </p>

                        <base-button
                          class="mt-3"
                          @click="unsetAsToBeClaimed()"
                          :is_accent="true"
                          text="Unset device as pending claim (disallows transfer of ownership)"
                          v-if="isEditable"
                        />
                      </template>

                      <base-button
                        @click="setAsToBeClaimed()"
                        :is_accent="true"
                        text="Set as pending claim (allows transfer of ownership)"
                        v-else-if="isEditable"
                      />
                    </div>
                  </div>

                  <base-text-button
                    text="Download certificate"
                    @click="download_certificate"
                  />

                  <base-text-button
                    text="Download private key"
                    @click="download_private_key"
                  />
                </div>

                <DownloadLabels
                  v-if="
                    isView &&
                    null != object_provisioning &&
                    object_provisioning.length > 0
                  "
                  :device_id="object_id"
                />

                <DocumentationUsedIn
                  v-if="isDocument && isView"
                  :used_in="object_loaded['used_in']"
                />

                <template v-if="isDocument">
                  <div v-if="isAddNew" class="sub-container grey--text">
                    <span class="input-info">
                      <v-icon color="info_gray" small>
                        mdi-information-outline
                      </v-icon>
                      You will be able to assign the documentation to objects
                      after submitting it.
                    </span>
                  </div>
                  <div
                    v-if="isEdit && null != target_class_category"
                    class="sub-container grey--text"
                  >
                    <SelectableObjectsList
                      :object_category="target_class_category"
                      :already_selected="objects_with_doc"
                      :doc_id="this.object_loaded['id']"
                      :team_id="object_team_id"
                      :teams_where_user_is_admin="teams_where_user_is_admin"
                      @list_is_changed="updateDocUsedInLists()"
                    />
                  </div>
                </template>

                <div class="mt-5">
                  <div
                    class="error--text"
                    v-if="errors != null && errors.length > 0"
                  >
                    Please, fix the following errors:
                    <p v-for="(error, key) in errors" :key="key">
                      * {{ error }}
                    </p>
                  </div>

                  <base-button
                    :is_accent="true"
                    text="Submit"
                    v-if="isEditable"
                    type="submit"
                  />

                  <base-button
                    text="Cancel"
                    v-if="isEditable && !isAddNew && !is_child_component"
                    @click="cancel_edit()"
                    class="ml-2"
                  />

                  <base-button
                    text="Close"
                    v-if="isAddNew && is_child_component"
                    @click="$emit('child_component_is_closed')"
                    class="ml-2"
                  />

                  <base-button
                    :is_accent="true"
                    text="Edit"
                    v-if="isView && is_user_admin_in_object_team"
                    :to="getEditUrl"
                  />

                  <base-button
                    class="ml-2"
                    :text="
                      isType
                        ? 'Delete type and all devices, groups and firmwares of this type'
                        : 'Delete'
                    "
                    v-if="canBeDeleted"
                    @click="deleteObject()"
                  />

                  <div v-if="isDocument && doc_is_used_by_objects" class="mt-4">
                    <input-info
                      msg="Documentation cannot be deleted if it is used by at least one object. It must first be removed from all objects."
                    />
                  </div>

                  <p
                    v-if="isView && !is_user_admin_in_object_team"
                    class="font-italic mt-10"
                  >
                    Only administrators of the object's team can modify the
                    object.
                  </p>
                </div>
              </form>
            </div>
          </v-card-text>
        </material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
const qr_code_url_common = "/qr?id=";

import {
  deleteObject,
  getExistingNames,
  getObjectJson,
  addNewObject,
  editObject,
  isSemver,
  uploadFirmwareFile,
  downloadFirmwareFile,
  isFirmwareNameValid,
  hashFile,
  deleteType,
  downloadCertificateFile,
  downloadPrivateKeyFile,
  setDeviceAsToBeClaimed,
  getDeviceQrCode,
  createDeviceQrCode,
  getTeamsListWhereUserIsAdminCall,
  getTypeIcon,
  createNewConfiguration,
  getConfigJson,
  CONFIG_STATES_ENUM,
  CONFLICT_RESOLUTION_STATE_ENUM,
  BASIC_DOCUMENT_TYPES_ENUM,
  uploadDocumentFile,
  downloadDocumentFile,
  createDocumentsCollection,
  editDocumentsCollection,
} from "../utils/ServerUtils.js";
import {
  PAGE,
  OBJECT_CATEGORIES,
  getObjectCategoryFromUrl,
  getPageFromUrl,
  getTitle,
  getListOfObjectsUrl,
  getEditUrl,
  getViewUrl,
  getDocumentCategory,
  getObjectHumanName,
} from "../utils/RoutesUtils";
import TargetsTable from "../layouts/default/widgets/TargetsTable.vue";
import IconImage from "../components/app/IconImage.vue";
import FirmwaresOfTypeTable from "../layouts/default/widgets/FirmwaresOfTypeTable.vue";
import FileUploader from "../layouts/default/widgets/FileUploader.vue";
import CustomFields from "../components/app/CustomFields.vue";
import HistoryTable from "../layouts/default/widgets/HistoryTable.vue";
import ActualFirmwareIcon from "../layouts/default/widgets/ActualFirmwareIcon.vue";
import DevicesInGroup from "../layouts/default/widgets/DevicesInGroup.vue";
import InputInfo from "../components/app/InputInfo.vue";
import BaseButton from "../components/app/BaseButton.vue";
import BaseTextButton from "../components/app/BaseTextButton.vue";
import DropdownList from "../layouts/default/widgets/DropdownList.vue";
import { formatDate } from "../utils/DateUtils";
import { mapGetters } from "vuex";
import DownloadLabels from "../layouts/default/widgets/DownloadLabels.vue";
import VueJsonPretty from "vue-json-pretty";
import "vue-json-pretty/lib/styles.css";
import ListOfGroupsOfType from "../layouts/default/widgets/ListOfGroupsOfType.vue";
import ListOfDevices from "../layouts/default/widgets/ListOfDevices.vue";
import CurrentlyOnTable from "../layouts/default/widgets/CurrentlyOnTable.vue";
import CustomFieldsFromType from "../components/app/CustomFieldsFromType.vue";
import ConfigSyncState from "../layouts/default/widgets/ConfigSyncState.vue";
import { isValidJson } from "../utils/helpers.js";
import DocumentationInput from "../layouts/default/widgets/DocumentationInput.vue";
import SelectableObjectsList from "../layouts/default/widgets/SelectableObjectsList.vue";
import DocumentationUsedIn from "../layouts/default/widgets/DocumentationUsedIn.vue";
import DownloadJson from "../layouts/default/widgets/DownloadJson.vue";

const sensitive_fields = [
  "type_id",
  "group_id",
  "target_firmware_id",
  "version",
];

const DOC_GLOBAL_TYPE_ENUM = {
  BASIC: "basic",
  COLLECTION: "collection",
};

function getImportantFieldsChangedConfirmationMessage(importantFieldsChanged) {
  var message = "The following important data is changed:\n";

  for (const field of importantFieldsChanged) {
    message += " - " + getFieldName(field) + "\n";
  }

  message += "Are you sure you want to keep these changes?";
  return message;
}

function getFieldName(field) {
  switch (field) {
    case "type_id":
      return "type";

    case "group_id":
      return "device group";

    case "target_firmware_id":
      return "target firmware";

    case "version":
      return "semver version";

    default:
      return field;
  }
}

async function isUserAdminInTeam(team_id, teams_where_user_is_admin) {
  if (null != teams_where_user_is_admin) {
    for (const admin_team of teams_where_user_is_admin) {
      if (admin_team.id == team_id) return true;
    }
  }

  return false;
}

function cleanCustomFields(fields) {
  if (fields == null) return null;
  try {
    const fields_json = JSON.parse(fields);
    var json = [];
    for (var i = 0; i < fields_json.length; i++) {
      var field = fields_json[i];
      if (field.name != "") {
        delete field["exisiting_changed"];
        json.push(field);
      }
    }
    return JSON.stringify(json);
  } catch (error) {
    console.log(error);
    return fields;
  }
}

function transformRawJsonStringToFormatted(configuration_str_raw) {
  if (null != configuration_str_raw) {
    try {
      const converted_json = JSON.parse(configuration_str_raw);
      const json_str = JSON.stringify(converted_json, undefined, 4);
      return json_str;
    } catch (error) {
      console.log("configuration_str_raw parse error");
    }
  }

  return configuration_str_raw;
}

export default {
  name: "ObjectView",

  components: {
    DropdownList,
    TargetsTable,
    IconImage,
    FirmwaresOfTypeTable,
    FileUploader,
    CustomFields,
    HistoryTable,
    ActualFirmwareIcon,
    DevicesInGroup,
    InputInfo,
    BaseButton,
    BaseTextButton,
    DownloadLabels,
    VueJsonPretty,
    ListOfGroupsOfType,
    ListOfDevices,
    CurrentlyOnTable,
    CustomFieldsFromType,
    ConfigSyncState,
    DocumentationInput,
    DocumentationUsedIn,
    SelectableObjectsList,
    DownloadJson,
  },
  name: "ObjectView",
  BaseTextButton,

  props: {
    is_child_component: {
      default: false,
      type: Boolean,
    },
    object_category_in: Number,
    preselected_type_id: String,
    preselected_team_id: String,

    object_to_copy: {
      default: null,
      type: Object,
    },
  },

  data() {
    return {
      teams_where_user_is_admin: [],
      is_user_admin_in_object_team: false,
      doc_global_type: null,

      object_loaded: {},

      // Editable form inputs
      object_id: null,
      object_name: null,
      object_team_id: null,
      object_is_disabled: false,
      object_name_of_uid: null,
      object_version: null,
      object_type_name: null,
      object_type_id: null,
      object_uid_for_type: null,
      object_group_name: null,
      object_group_id: null,
      object_target_firmware_id: null,
      object_target_firmware: null,
      object_filename: null,
      object_size: null,
      object_custom_fields: null,
      object_custom_fields_from_type: null,
      object_custom_fields_devices: null,
      object_config_state: null,
      object_config_id: null,
      object_notes: null,
      object_to_be_claimed: false,
      object_claim_code: null,
      object_provisioning: null,
      object_firmware_hash: null,
      object_devices: null,
      object_content: null,
      object_target_class: null,

      object_documents_collection_id: null,

      device_qr_code: null,
      existing_names: [],
      errors: [],
      image_src: null,
      type_image_src: null,
      firmware_file: null,
      data_is_loaded: false,
      teams_list_loaded: false,
      types_list_loaded: false,
      page: PAGE.HOME,
      object_category: OBJECT_CATEGORIES.UNKNOWN,

      name_of_uid: "Unique ID by type",

      add_type_component_is_visible: false,
      add_group_component_is_visible: false,
      child_component_show_hidden_fields: false,
      is_firmware_file_uploaded: false,

      // Save original value for important fields
      sensitive_fields_original_values: [],

      provisioning_edit_checkbox_value: false,
      provisioning_edit_enabled: false,

      config_str: null,
      object_provisioning_str: null,
      config_str_original: null,

      config_history_list: [],
      last_config_on_device_json: null,
      config_resolution_state: CONFLICT_RESOLUTION_STATE_ENUM.UNRESOLVED,

      basic_doc_type: null,
      document_file: null,
      object_basic_docs_list: null,
    };
  },

  watch: {
    provisioning_json() {
      if (this.isDevice && null != this.provisioning_json) {
        this.object_provisioning_str = JSON.stringify(
          this.provisioning_json,
          undefined,
          4
        );
      }
    },
  },

  created: function () {
    const relative_url = new URL(location.href).pathname;
    this.object_category = getObjectCategoryFromUrl(relative_url);
    this.page = getPageFromUrl(relative_url, this.object_category);

    if (this.isDocument) {
      const query_params = this.$route.query;
      if (null != query_params) {
        if (null != query_params.target_class)
          this.object_target_class = query_params.target_class;

        if (null != query_params.doc_type)
          this.basic_doc_type = query_params.doc_type;
      }
    }
  },

  mounted: async function () {
    await this.initObjectWithData();
  },

  computed: {
    ...mapGetters(["user"]),
    ...mapGetters(["filter_selected_team_id"]),
    ...mapGetters(["filter_selected_type_id"]),

    // ...mapGetters(["object_category"]),

    canBeDeleted() {
      const common_condition =
        (this.isView || this.isEdit) && this.is_user_admin_in_object_team;
      if (!this.isDocument) {
        return common_condition;
      } else {
        console.log(this.object_loaded.used_in);
        return !this.doc_is_used_by_objects;
      }
    },

    doc_is_used_by_objects() {
      if (this.object_loaded.used_in == null) return false;

      return (
        this.object_loaded.used_in.devices.length > 0 ||
        this.object_loaded.used_in.firmwares.length > 0 ||
        this.object_loaded.used_in.groups.length > 0 ||
        this.object_loaded.used_in.types.length > 0
      );
    },

    basic_documents_types() {
      return [
        {
          id: null,
          name: "",
        },
        {
          id: BASIC_DOCUMENT_TYPES_ENUM.TEXT,
          name: "Text",
        },
        {
          id: BASIC_DOCUMENT_TYPES_ENUM.LINK,
          name: "Link",
        },
        {
          id: BASIC_DOCUMENT_TYPES_ENUM.FILE,
          name: "File",
        },
        {
          id: BASIC_DOCUMENT_TYPES_ENUM.IMAGE,
          name: "Image",
        },
        // TODO: implement the functionalities for label templates
        // {
        //   id: BASIC_DOCUMENT_TYPES_ENUM.LABEL_TEMPLATE,
        //   name: "Template for a label"
        // },
        // {
        //   id: BASIC_DOCUMENT_TYPES_ENUM.OTHER,
        //   name: "Other"
        // }
      ];
    },

    basic_doc_type_name() {
      if (null == this.basic_doc_type || null == this.basic_documents_types)
        return "";
      return this.basic_documents_types.find((x) => x.id == this.basic_doc_type)
        .name;
    },

    isConfigInConflictState() {
      return this.config_state == CONFIG_STATES_ENUM.CONFLICT;
    },

    type_can_not_be_changed: function () {
      if (this.isAddNew) return false;

      if (this.is_used_as_target_firmware_of_device) return true;
      if (this.is_used_as_target_firmware_of_group) return true;

      if (
        this.isGroup &&
        null != this.object_devices &&
        this.object_devices.length > 0
      )
        return true;

      return false;
    },

    team_can_not_be_changed: function () {
      if (this.type_can_not_be_changed) return true;

      if (this.isType) {
        if (null != this.object_devices && this.object_devices.length > 0)
          return true;
        if (
          null != this.object_loaded["devices_groups"] &&
          this.object_loaded["devices_groups"].length > 0
        )
          return true;
        if (
          null != this.object_loaded["firmwares"] &&
          this.object_loaded["firmwares"].length > 0
        )
          return true;
      }

      return false;
    },

    is_used_as_target_firmware_of_device: function () {
      return (
        this.isFirmware &&
        "is_target_of" in this.object_loaded &&
        null != this.object_loaded.is_target_of &&
        this.object_loaded.is_target_of.length > 0
      );
    },

    is_used_as_target_firmware_of_group: function () {
      return (
        this.isFirmware &&
        "devices_group" in this.object_loaded &&
        null != this.object_loaded.devices_group &&
        this.object_loaded.devices_group.length > 0
      );
    },

    getTitleText: function () {
      const wait_server_indicator =
        this.data_is_loaded || this.isAddNew
          ? ""
          : " [waiting for server response]";
      return getTitle(this.page, this.object_category) + wait_server_indicator;
    },

    isEditable: function () {
      return this.isEdit || this.isAddNew;
    },

    isEdit: function () {
      return PAGE.EDIT == this.page;
    },

    isView: function () {
      return PAGE.VIEW == this.page;
    },

    isAddNew: function () {
      return PAGE.NEW == this.page;
    },

    hasType: function () {
      return this.isDevice || this.isGroup || this.isFirmware;
    },

    hasFirmware: function () {
      return this.isDevice || this.isGroup;
    },

    isDevice: function () {
      return OBJECT_CATEGORIES.DEVICE == this.object_category;
    },

    isType: function () {
      return OBJECT_CATEGORIES.TYPE == this.object_category;
    },

    isGroup: function () {
      return OBJECT_CATEGORIES.GROUP == this.object_category;
    },

    isFirmware: function () {
      return OBJECT_CATEGORIES.FIRMWARE == this.object_category;
    },

    isDocumentsCollection: function () {
      return OBJECT_CATEGORIES.DOCUMENTS_COLLECTION == this.object_category;
    },

    isBasicDocument: function () {
      return OBJECT_CATEGORIES.BASIC_DOCUMENT == this.object_category;
    },

    isDocument: function () {
      return (
        OBJECT_CATEGORIES.DOCUMENT == this.object_category ||
        this.isBasicDocument ||
        this.isDocumentsCollection
      );
    },

    isDocumentIcon() {
      return BASIC_DOCUMENT_TYPES_ENUM.IMAGE == this.basic_doc_type;
    },

    isDocumentFile() {
      return BASIC_DOCUMENT_TYPES_ENUM.FILE == this.basic_doc_type;
    },

    isDocumentLink() {
      return BASIC_DOCUMENT_TYPES_ENUM.LINK == this.basic_doc_type;
    },

    hasDocumentation() {
      return this.isDevice || this.isType || this.isGroup || this.isFirmware;
    },

    getListOfObjectsUrl: function () {
      if (this.isDocument) {
        return getListOfObjectsUrl(getDocumentCategory());
      } else {
        return getListOfObjectsUrl(this.object_category);
      }
    },

    getGroupCategory() {
      return OBJECT_CATEGORIES.GROUP;
    },

    getTypeCategory() {
      return OBJECT_CATEGORIES.TYPE;
    },

    getRoleCategory() {
      return OBJECT_CATEGORIES.ROLE;
    },

    getFirmwareCategory() {
      return OBJECT_CATEGORIES.FIRMWARE;
    },

    getDeviceCategory() {
      return OBJECT_CATEGORIES.DEVICE;
    },

    getTeamCategory() {
      return OBJECT_CATEGORIES.TEAM;
    },

    getBasicDocumentCategory() {
      return OBJECT_CATEGORIES.BASIC_DOCUMENT;
    },

    getDocumentsCollectionCategory() {
      return OBJECT_CATEGORIES.DOCUMENTS_COLLECTION;
    },

    getEditUrl: function () {
      return getEditUrl(this.object_id, this.object_category);
    },

    groupIsChoosenForDevice() {
      return this.isDevice && null != this.object_group_id;
    },

    firmware_file_is_uploaded() {
      // We calculate the firmware hash only if the file is loaded/unloaded
      return null != this.object_firmware_hash;
    },

    provisioning_json() {
      const provisioning_str = this.object_provisioning;
      var converted_json = {};
      try {
        converted_json = JSON.parse(provisioning_str);
      } catch (error) {
        converted_json = provisioning_str;
      }

      return converted_json;
    },

    configuration_json() {
      var converted_json = {};
      try {
        converted_json = JSON.parse(this.config_str);
      } catch (error) {
        console.log("configuration_json parse error");
        converted_json = this.config_str;
      }
      return converted_json;
    },

    conflict_resolved() {
      return (
        this.config_resolution_state ==
          CONFLICT_RESOLUTION_STATE_ENUM.DEVICE_CHOOSEN ||
        this.config_resolution_state ==
          CONFLICT_RESOLUTION_STATE_ENUM.PUCS_CHOOSEN
      );
    },

    target_class_category() {
      switch (this.object_target_class) {
        case "device":
          return this.getDeviceCategory;
        case "type":
          return this.getTypeCategory;
        case "firmware":
          return this.getFirmwareCategory;
        case "group":
          return this.getGroupCategory;
        default:
          return null;
      }
    },

    objects_with_doc() {
      const object_loaded = this.object_loaded;
      if (object_loaded != null && object_loaded.used_in != null) {
        switch (this.object_target_class) {
          case "device":
            return object_loaded.used_in.devices;
          case "type":
            return object_loaded.used_in.types;
          case "firmware":
            return object_loaded.used_in.firmwares;
          case "group":
            return object_loaded.used_in.groups;
          default:
            break;
        }
      }
      return null;
    },
  },

  methods: {
    async initObjectWithData() {
      // Change object category and page if it is a child (recursive) component
      if (this.is_child_component) {
        this.object_category = this.object_category_in;
        this.page = PAGE.NEW;
      }

      if (this.isView || PAGE.EDIT == this.page) {
        const object_id = new URL(location.href).searchParams.get("id");
        this.getObject(object_id);

        if (this.object_category == this.getDeviceCategory) {
          const device_qr_code_object = await getDeviceQrCode(object_id);
          if (null != device_qr_code_object) {
            this.device_qr_code = qr_code_url_common + device_qr_code_object.id;
          } else {
            this.device_qr_code = null;
          }
        }
      } else {
        // Copy an already existing object (example a type or a firmware)
        // This is used when transfering the object from one team to another
        if (null != this.object_to_copy) {
          this.updateValuesFromJson(this.object_to_copy);
        } else {
          this.initObject();
        }
      }

      if (PAGE.VIEW != this.page) {
        const existing_names = await getExistingNames(this.object_category);
        if (null != existing_names) this.existing_names = existing_names;
      }
    },

    async unsetAsToBeClaimed() {
      if (null != this.object_loaded && null != this.object_id) {
        const response = await setDeviceAsToBeClaimed(this.object_id, false);
        if (null != response && response.status == 200) {
          this.object_to_be_claimed = false;
        } else {
          console.log("Error in unsetAsToBeClaimed");
        }
      } else {
        console.log("unsetAsToBeClaimed: Device should not be null here!");
      }
    },

    async setAsToBeClaimed() {
      if (null != this.object_loaded && null != this.object_id) {
        const response = await setDeviceAsToBeClaimed(this.object_id);
        if (null != response && response.status == 200) {
          this.object_to_be_claimed = true;
          this.object_claim_code = response.data.claim_code;
        } else {
          console.log("Error in setAsToBeClaimed");
        }
      } else {
        console.log("setAsToBeClaimed: Device should not be null here!");
      }
    },

    async generateQrCode() {
      if (null != this.object_loaded && null != this.object_id) {
        const response = await createDeviceQrCode(this.object_id);
        if (null != response) {
          this.device_qr_code = qr_code_url_common + response.id;
        } else {
          console.log("Error in setAsToBeClaimed");
        }
      } else {
        console.log("setAsToBeClaimed: Device should not be null here!");
      }
    },

    formatDate: function (
      object_date,
      show_time_flag = true,
      simple_format = false
    ) {
      return formatDate(object_date, show_time_flag, simple_format);
    },

    dropdownSelected: function (object, field) {
      switch (field) {
        case "team_id":
          this.object_team_id = null != object ? object.id : null;

          break;

        case "group_id":
          this.object_group_id = null != object ? object.id : null;

          // Set a firmware if a group was choosen
          if (null != object) {
            this.object_target_firmware_id = object
              ? object.target_firmware_id
              : null;
          }

          break;

        case "type_id":
          this.object_type_id = object ? object.id : null;
          // Needed for the group edit page (when selecting devices)
          this.object_type_name = object ? object.name : null;

          this.updateUidName(null == object ? null : object.name_of_uid);

          break;

        case "target_firmware_id":
          this.object_target_firmware_id = object ? object.id : null;

          break;

        default:
          break;
      }
    },

    cleanEmptyCustomFieldsType: function () {
      const stringified = this.object_custom_fields_devices;
      try {
        var fields = JSON.parse(stringified);
        if (null != fields) {
          for (var i = fields.length - 1; i >= 0; i--) {
            if (0 == fields[i].name.length && 0 == fields[i].value.length) {
              fields.splice(i, 1);
            }
          }
        }
        this.object_custom_fields_devices = JSON.stringify(fields);
      } catch (e) {
        // do nothing
      }
    },

    cleanEmptyCustomFields: function () {
      const stringified = this.object_custom_fields;
      try {
        var fields = JSON.parse(stringified);
        if (null != fields) {
          for (var i = fields.length - 1; i >= 0; i--) {
            if (0 == fields[i].name.length && 0 == fields[i].value.length) {
              fields.splice(i, 1);
            }
          }
        }
        this.object_custom_fields = JSON.stringify(fields);
      } catch (e) {
        // do nothing
      }
    },

    getObject: async function (object_id) {
      const object_server = await getObjectJson(
        object_id,
        this.object_category,
        true
      );

      this.teams_where_user_is_admin = await getTeamsListWhereUserIsAdminCall();

      this.is_user_admin_in_object_team = await isUserAdminInTeam(
        object_server.team_id,
        this.teams_where_user_is_admin
      );

      if (null != object_server) {
        // Save initial state of the object to compare later if it changed
        this.object_loaded = object_server;
        this.updateValuesFromJson(object_server);

        if (null == object_server.icon) {
          this.type_image_src = await getTypeIcon(object_server.type_id);
        }

        await this.updateConfigHistory(object_server);

        // TODO: change this !
        // Save original value for important fields
        for (const sensitive_field of sensitive_fields) {
          if (sensitive_field in object_server) {
            this.sensitive_fields_original_values[sensitive_field] =
              object_server[sensitive_field];
          }
        }

        if (this.object_category == OBJECT_CATEGORIES.BASIC_DOCUMENT) {
          this.set_doc_global_type(true);
        } else if (
          this.object_category == OBJECT_CATEGORIES.DOCUMENTS_COLLECTION
        ) {
          this.set_doc_global_type(false);
        }
      }

      this.data_is_loaded = true;
    },

    updateConfigHistory: async function (object_server) {
      if (this.isDevice) {
        // Config history
        var last_config_id = null;
        if (null != object_server.config_history) {
          for (var i = 0; i < object_server.config_history.length; i++) {
            const config_history_object = object_server.config_history[i];
            this.config_history_list.push({
              id: config_history_object["config_id"],
              date: config_history_object["date"],
              config_loaded: false,
            });
            if (i == object_server.config_history.length - 1) {
              last_config_id = config_history_object["config_id"];
            }
          }

          this.config_history_list = this.config_history_list.reverse();
        }

        if (this.isConfigInConflictState) {
          const last_config = await getConfigJson(last_config_id);
          if (null != last_config) {
            try {
              this.last_config_on_device_json = JSON.parse(last_config.config);
            } catch (error) {
              console.log("Error parsing config json: " + error);
              this.last_config_on_device_json = last_config.config;
            }
          }
        }
      }
    },

    updateValuesFromJson: function (object_server) {
      /* Update the values of the form fields defined in data with the values from the object_server */

      // Common for all object types
      this.object_id = object_server.id;
      this.object_name = object_server.name;
      this.object_team_id = object_server.team_id;
      this.object_notes = object_server.notes;
      // Show the icon
      this.image_src = object_server.icon;

      // Specific for devices, groups and firmwares
      if (this.hasType) {
        this.object_type_id = object_server.type_id;
        if (null != object_server.type) {
          this.object_type_name = object_server.type.name;
        }
        this.updateUidName(
          null == object_server || null == object_server.type
            ? null
            : object_server.type.name_of_uid
        );
      }

      // Specific for devices and groups
      if (this.hasFirmware) {
        this.object_target_firmware_id = object_server.target_firmware_id;
      }

      // Specific for devices
      if (this.isDevice) {
        this.object_uid_for_type = object_server.uid_for_type;
        this.object_custom_fields_from_type =
          object_server.custom_fields_from_type;
        this.object_to_be_claimed = object_server.to_be_claimed;
        this.object_claim_code = object_server.claim_code;
        this.object_provisioning = object_server.provisioning;
        this.object_group_id = object_server.group_id;
        if (null != object_server.group) {
          this.object_group_name = object_server.group.name;
        }

        // Configuration
        this.object_config_state = object_server.config_state;
        this.object_config_id = object_server.config_id;

        const config_object = object_server.config;
        const configuration_str_raw =
          null != config_object ? config_object.config : null;
        this.config_str = transformRawJsonStringToFormatted(
          configuration_str_raw
        );
        this.config_str_original = this.config_str;
      }

      // Specific for firmwares
      if (this.isFirmware) {
        this.object_version = object_server.version;
        this.object_filename = object_server.filename;
        this.object_firmware_hash = object_server.firmware_hash;
        this.object_size = object_server.size;
        this.object_is_disabled = object_server.is_disabled;
        this.object_custom_fields = object_server.custom_fields;

        if (null != this.object_size && "" !== this.object_size)
          this.is_firmware_file_uploaded = true;
      }

      // Specific for groups and types
      if (this.isGroup || this.isType) {
        this.object_devices =
          object_server.devices == null ? null : object_server.devices.slice();
      }

      // Specific for types
      if (this.isType) {
        this.object_name_of_uid = object_server.name_of_uid;
        this.object_custom_fields_devices = object_server.custom_fields_devices;
      }

      // Specific for basic documents
      if (this.isBasicDocument) {
        this.object_content = object_server.content;
        this.basic_doc_type = object_server.doc_type;
        this.object_target_class = object_server.target_class;
      }

      // Specific for documents collections
      if (this.isDocumentsCollection || this.hasDocumentation) {
        this.object_basic_docs_list =
          object_server.basic_docs_list == null
            ? null
            : object_server.basic_docs_list.slice();
      }

      // Specific for documentable objects
      if (this.hasDocumentation) {
        this.object_documents_collection_id =
          object_server.documents_collection_id;
      }
    },

    updateUidName(name_of_uid = null) {
      this.name_of_uid =
        null != name_of_uid && "" != name_of_uid
          ? name_of_uid
          : "Unique ID by type";
    },

    initObject: function () {
      if (null != this.preselected_type_id)
        this.object_type_id = this.preselected_type_id;

      if (null != this.preselected_team_id)
        this.object_team_id = this.preselected_team_id;

      if (this.isDocument) this.set_doc_global_type(true);

      this.generateAutomaticObjectName();
      this.setTeamIfTeamIsFiltered();
      this.setTypeIfTypeIsFiltered();
    },

    setTeamIfTeamIsFiltered() {
      if (this.filter_selected_team_id != null && this.object_team_id == null) {
        this.object_team_id = this.filter_selected_team_id;
      }
    },

    setTypeIfTypeIsFiltered() {
      if (
        this.hasType &&
        this.filter_selected_type_id != null &&
        this.object_type_id == null
      ) {
        this.object_type_id = this.filter_selected_type_id;
      }
    },

    generateAutomaticObjectName() {
      const object_name = this.isBasicDocument
        ? "Document of type " + this.basic_doc_type_name
        : getObjectHumanName(this.object_category);
      this.object_name = "New " + object_name;
    },

    processForm: async function (e) {
      if (null != e) e.preventDefault();

      const validation_is_ok = await this.validateForm();
      if (validation_is_ok) {
        const importantFieldsChanged = this.getImportantFieldsChanged();

        if (
          importantFieldsChanged.length == 0 ||
          confirm(
            getImportantFieldsChangedConfirmationMessage(importantFieldsChanged)
          )
        ) {
          this.updateObjectRequest();
        } else {
          console.log("No important field has changed.");
        }
      } else {
        console.log("Validation error");
      }
    },

    getImportantFieldsChanged() {
      var sensitive_fields_changed = [];

      for (const sensitive_field in this.sensitive_fields_original_values) {
        if (
          this.sensitive_fields_original_values[sensitive_field] !=
          this.object_loaded[sensitive_field]
        ) {
          sensitive_fields_changed.push(sensitive_field);
        }
      }

      return sensitive_fields_changed;
    },

    updateObjectRequest: async function (upload_firmware_failed = false) {
      if (this.isDevice && this.configurationChanged()) {
        const new_config_id = await createNewConfiguration(
          this.config_str,
          this.object_team_id
        );
        if (null == new_config_id) {
          alert("Failed to create new configuration");
          return;
        }
        this.object_config_id = new_config_id.id;
      }

      // Specific for documentable objects
      // First, we should create a document collection object
      if (this.hasDocumentation) {
        // First, compare with current documentation

        // If empty, create a new doc
        if (this.documents_list_has_changed()) {
          const docs_collection_created =
            await this.createOrEditDocumentsCollection();
          console.log(docs_collection_created);
          if (null != docs_collection_created) {
            this.object_documents_collection_id = docs_collection_created.id;
          } else {
            console.error("Error creating documentation!");
          }
        }
      }

      const req_body = await this.getRequestBody();

      const object_created =
        this.isAddNew && !upload_firmware_failed
          ? await addNewObject(this.object_category, req_body)
          : await editObject(this.object_id, this.object_category, req_body);

      // Once the object has been successfully created or updated on the server,
      // the actions depend on the type of the object.
      // For a firmware and a basic document of type "file",
      // we still have to upload the file itself to the server.
      // For all other objects, we just have to change the state of the page to "view".
      if (null != object_created) {
        if (this.isFirmware) {
          // Upload the firmware file after all the other field were updated
          const file_upload_succeeded = await this.uploadFirmware(
            object_created.id
          );

          //
          if (!upload_firmware_failed) {
            if (file_upload_succeeded) {
              this.treatUpdateResponse(object_created);
            } else {
              // If there were a problem with a file upload,
              // update the current firmware object
              // in such a way that the file size and file hash
              // are set to be null.

              this.object_id = object_created.id;
              const firmware_file = this.$refs["firmware_file"];
              firmware_file.resetFile();
              this.firmwareFileLoaded(null);
              this.updateObjectRequest(true);
            }
          }
        } else if (this.isBasicDocument) {
          if (null != object_created && this.isBasicDocument) {
            // Upload the document file after all the other field were updated
            const file_upload_succeeded = await this.uploadDocument(
              object_created.id
            );

            if (file_upload_succeeded) {
              this.treatUpdateResponse(object_created);
            } else {
              console.log("Problem!");
            }
          }
        } else {
          if (this.is_child_component) {
            // close the child component window
            this.treatChildComponentResponse(object_created);
          } else {
            // refresh the page, go to the "view" state
            this.treatUpdateResponse(object_created);
          }
        }
      } else {
        console.error("Failed to update object");
      }
    },

    async createOrEditDocumentsCollection() {
      if (this.object_documents_collection_id == null) {
        const new_doc_name = this.generateNameForDocumentation();
        return await createDocumentsCollection(
          this.object_basic_docs_list,
          this.object_team_id,
          new_doc_name
        );
      } else {
        return await editDocumentsCollection(
          this.object_documents_collection_id,
          this.object_basic_docs_list
        );
      }
    },

    generateNameForDocumentation() {
      var new_doc_name =
        "Documentation for " +
        getObjectHumanName(this.object_category) +
        " " +
        this.object_name;

      const version_prefix_str = " v.";
      var new_ver_num = 0;

      // if a document collection has already been uploaded before
      if (this.object_loaded["documents"] != null) {
        // get current version from name
        try {
          const old_name = this.object_loaded["documents"].name;
          const version_index = old_name.lastIndexOf(version_prefix_str);
          const version_num_str = old_name.substring(
            version_index + version_prefix_str.length
          );
          const old_ver_num = parseInt(version_num_str);

          if (isNaN(old_ver_num)) {
            new_ver_num = Math.floor(Math.random() * 1e6);
          } else {
            new_ver_num = old_ver_num + 1;
          }
        } catch (error) {
          new_ver_num = Math.floor(Math.random() * 1e6);
        }
      }
      new_doc_name += version_prefix_str + new_ver_num;
      return new_doc_name;
    },

    configurationChanged: function () {
      if (null == this.config_str_original || "" == this.config_str_original) {
        return null != this.config_str && "" != this.config_str;
      } else {
        const current_conf_str_formatted = transformRawJsonStringToFormatted(
          this.config_str
        );
        return current_conf_str_formatted != this.config_str_original;
      }
    },

    uploadFirmware: async function (firmware_id) {
      if (null != this.firmware_file) {
        // Upload the firmware file after all the other field were updated
        var file_upload_response = await uploadFirmwareFile(
          firmware_id,
          this.firmware_file
        );
        return null == file_upload_response ? false : true;
      }
      return true;
    },

    uploadDocument: async function (document_id) {
      if (null != this.document_file) {
        // Upload the firmware file after all the other field were updated
        var file_upload_response = await uploadDocumentFile(
          document_id,
          this.document_file
        );
        return null == file_upload_response ? false : true;
      }
      return true;
    },

    treatUpdateResponse: function (response_data) {
      if (null != response_data) {
        const object_to_view_id = this.isAddNew
          ? response_data.id
          : this.object_id;
        const viewModeUrl = getViewUrl(object_to_view_id, this.object_category);
        this.$router.push(viewModeUrl);
      }
    },

    treatChildComponentResponse: function (response_data) {
      if (null != response_data) {
        this.$emit("newObjectIsAddedFromChild", response_data);
      }
    },

    newTypeIsAdded: function (response_data) {
      this.object_type_id = response_data.id;

      // trigger an update of the types dropdown list component
      this.$refs["types_dropdown"].updateObjects();
      this.add_type_component_is_visible = false;

      this.updateUidName(response_data.name_of_uid);
    },

    newGroupIsAdded: function (response_data) {
      this.object_group_id = response_data.id;
      // trigger an update of the types dropdown list component
      this.$refs["groups_dropdown"].updateObjects();
      this.add_group_component_is_visible = false;
    },

    getRequestBody: async function () {
      this.cleanEmptyCustomFieldsType();
      this.cleanEmptyCustomFields();

      var body = {};

      // Common for all object types
      body = this.addFieldIfChanged(body, "name", this.object_name);
      body = this.addFieldIfChanged(body, "team_id", this.object_team_id);
      body = this.addFieldIfChanged(body, "notes", this.object_notes);
      body = this.addFieldIfChanged(body, "icon", this.image_src);

      // Specific for devices, groups and firmwares
      if (this.hasType) {
        body = this.addFieldIfChanged(body, "type_id", this.object_type_id);
      }

      // Specific for devices
      if (this.isDevice) {
        body = this.addFieldIfChanged(
          body,
          "uid_for_type",
          this.object_uid_for_type
        );
        body = this.addFieldIfChanged(
          body,
          "custom_fields_from_type",
          this.object_custom_fields_from_type
        );
        body = this.addFieldIfChanged(
          body,
          "to_be_claimed",
          this.object_to_be_claimed
        );
        body = this.addFieldIfChanged(
          body,
          "claim_code",
          this.object_claim_code
        );
        body = this.addFieldIfChanged(
          body,
          "provisioning",
          this.object_provisioning_str
        );
        body = this.addFieldIfChanged(body, "group_id", this.object_group_id);

        body = this.addFieldIfChanged(body, "config_id", this.object_config_id);
        body = this.addFieldIfChanged(
          body,
          "config_state",
          this.object_config_state
        );

        // only update a target firmware if a group is not set
        // because the target firmware will be set on the server side
        // if the group is defined
        if (null == this.object_group_id) {
          body = this.addFieldIfChanged(
            body,
            "target_firmware_id",
            this.object_target_firmware_id
          );
        }
      }

      // Specific for firmwares
      if (this.isFirmware) {
        body = this.addFieldIfChanged(body, "version", this.object_version);
        body = this.addFieldIfChanged(
          body,
          "is_disabled",
          this.object_is_disabled
        );

        if (this.firmware_file_is_uploaded) {
          body = this.addFieldIfChanged(
            body,
            "firmware_hash",
            this.object_firmware_hash
          );
          body = this.addFieldIfChanged(body, "filename", this.object_filename);
          body = this.addFieldIfChanged(body, "size", this.object_size);
        }
      }

      // Specific for types
      if (this.isType) {
        body = this.addFieldIfChanged(
          body,
          "name_of_uid",
          this.object_name_of_uid
        );

        body = this.addFieldIfChanged(
          body,
          "custom_fields_devices",
          cleanCustomFields(this.object_custom_fields_devices)
        );
      }

      // Specific for groups
      if (this.isGroup) {
        body = this.addFieldIfChanged(
          body,
          "target_firmware_id",
          cleanCustomFields(this.object_target_firmware_id)
        );
      }

      // Specific for documents
      if (this.isBasicDocument) {
        body = this.addFieldIfChanged(body, "content", this.object_content);
        body = this.addFieldIfChanged(body, "doc_type", this.basic_doc_type);
        body = this.addFieldIfChanged(
          body,
          "target_class",
          this.object_target_class
        );
      }

      // TODO: the documents collections are currently hidden from the user
      // Specific for documents collections
      if (this.isDocumentsCollection) {
        if (this.documents_list_has_changed()) {
          body["basic_docs_list"] = this.object_basic_docs_list;
        }
      }

      // Specific for documentable objects
      if (this.hasDocumentation) {
        body = this.addFieldIfChanged(
          body,
          "documents_collection_id",
          this.object_documents_collection_id
        );
      }

      return body;
    },

    documents_list_has_changed: function () {
      // Check if the documents list has been changed

      if (
        this.object_basic_docs_list == null &&
        this.object_loaded["basic_docs_list"] == null
      )
        return false;

      if (
        this.object_basic_docs_list == null &&
        this.object_loaded["basic_docs_list"] != null
      )
        return true;

      if (
        this.object_basic_docs_list != null &&
        this.object_loaded["basic_docs_list"] == null
      )
        return true;

      if (
        this.object_basic_docs_list.length !=
        this.object_loaded["basic_docs_list"].length
      )
        return true;

      var initial_docs_ids = [];
      var current_docs_ids = [];

      for (var i = 0; i < this.object_basic_docs_list.length; i++) {
        current_docs_ids.push(this.object_basic_docs_list[i].id);
        initial_docs_ids.push(this.object_loaded["basic_docs_list"][i].id);
      }
      current_docs_ids.sort();
      initial_docs_ids.sort();

      return !(current_docs_ids.join(",") == initial_docs_ids.join(","));
    },

    addFieldIfChanged: function (body, field_name, field_value) {
      if (field_value != this.object_loaded[field_name]) {
        body[field_name] = field_value;
      }
      return body;
    },

    deleteObject: async function () {
      var delete_result = false;
      if (this.isType) {
        delete_result = await deleteType(this.object_loaded);
      } else {
        delete_result = await deleteObject(
          this.object_id,
          this.object_category,
          this.object_name
        );
      }

      // return to the list view
      if (true == delete_result) this.$router.push(this.getListOfObjectsUrl);
    },

    validateUidForType: function () {
      const target_form = this.$refs["form"];
      const target_el = target_form.elements["uid_for_type"];
      const uid_for_type = target_el.value;

      if (null == uid_for_type || uid_for_type.length == 0) {
        target_el.setCustomValidity(
          "The field 'unique ID by type' can not be empty."
        );
        return false;
      } else {
        target_el.setCustomValidity("");
        return true;
      }
    },

    validateName: function () {
      const target_form = this.$refs["form"];
      const target_el = target_form.elements["name"];
      const name = target_el.value;

      if (name.length == 0) {
        target_el.setCustomValidity("The name field can not be empty.");
        return false;
      }

      if (this.isFirmware) {
        // special rules for a firmware name
        if (false == isFirmwareNameValid(name)) {
          target_el.setCustomValidity(
            "The firmware name can only contain the following values: alphanumeric and ' ', '-', '_', '.' characters."
          );
          return false;
        }

        const version_el = target_form.elements["version"];
        const version = version_el.value;

        if (!this.uniqueNameAndVersion(name, version)) {
          const msg =
            "A firmware with the name " +
            name +
            " and the version " +
            version +
            " already exist. The pair (name + value) should be unique.";
          target_el.setCustomValidity(msg);
          version_el.setCustomValidity(msg);
        } else {
          // valid
          target_el.setCustomValidity("");
          version_el.setCustomValidity("");
          return true;
        }
      } else {
        if (!this.uniqueName(name)) {
          target_el.setCustomValidity("The name " + name + " is not unique.");
        } else {
          // valid
          target_el.setCustomValidity("");
          return true;
        }
      }
    },

    uniqueName: function (name) {
      // Name has not changed
      if (name == this.object_loaded["name"]) return true;

      for (var existing_name of this.existing_names) {
        if (
          existing_name.name == name &&
          existing_name.team_id == this.object_team_id
        )
          return false;
      }

      return true;
    },

    uniqueNameAndVersion: function (name, version) {
      if (
        name == this.object_loaded["name"] &&
        version == this.object_loaded["version"]
      )
        return true;

      for (let i = 0; i < this.existing_names.length; ++i) {
        var existing_name = this.existing_names[i];
        if (
          name == existing_name.name &&
          version == existing_name.version &&
          existing_name.team_id == this.object_team_id
        ) {
          return false;
        }
      }
      return true;
    },

    validateForm: async function () {
      // send a validation event to the teams dropdown list
      if (null != this.$refs["teams_dropdown"])
        this.$refs["teams_dropdown"].validateInput();

      // send a validation event to a types dropdown list
      if (null != this.$refs["types_dropdown"])
        this.$refs["types_dropdown"].validateInput();

      this.errors = [];
      if (!this.validateName()) {
        this.errors.push("Name is not valid.");
      }

      if (this.isDevice && !this.validateUidForType()) {
        this.errors.push("The field 'unique ID by type' can not be empty.");
      }

      if (this.isDevice && this.config_str != this.config_str_original) {
        const target_form = this.$refs["form"];
        const config_el = target_form.elements["config_textarea"];

        if (!isValidJson(this.config_str)) {
          const msg = "The configuration is not valid JSON.";
          this.errors.push(msg);
          config_el.setCustomValidity(msg);
          config_el.focus();
        } else {
          config_el.setCustomValidity("");
        }
      }

      if (this.hasType) {
        if (this.object_type_id == null)
          this.errors.push("Type should be selected.");
      }

      if (this.isBasicDocument) {
        if (this.isDocumentFile) {
          const is_valid =
            this.$refs["document_file_input"].validateIsNotEmpty() ||
            this.object_content != null;
          if (!is_valid) this.errors.push("Uploading the file is required.");
        } else if (this.isDocumentIcon) {
          const is_valid =
            this.$refs["document_image_input"].validateIsNotEmpty() ||
            this.object_content != null;
          if (!is_valid) this.errors.push("Uploading the file is required.");
        } else {
          try {
            if (
              this.object_content == null ||
              this.object_content.length == 0
            ) {
              this.$refs["text_content"].setCustomValidity(
                "The content field can not be empty."
              );
              this.errors.push("The content field can not be empty.");
            } else {
              this.$refs["text_content"].setCustomValidity("");
            }
          } catch (e) {
            // ignore
          }
        }
      }

      if (this.object_team_id == null)
        this.errors.push("Team should be selected.");

      if (this.isFirmware) {
        const target_form = this.$refs["form"];
        const target_el = target_form.elements["version"];
        // Delete spaces from both sides of the version string
        if (this.object_version == null) this.object_version = "";
        this.object_version = this.object_version.trim();
        // Do the same for the corresponding input form (because it is not updated instantly)
        target_el.value = target_el.value.trim();

        if (target_el.value.length == 0) {
          target_el.setCustomValidity("The version field can not be empty.");
          this.errors.push("The version field can not be empty.");
        } else {
          const is_semver_version = await isSemver(target_el.value);

          if (null != is_semver_version) {
            // the variable is null if the server did not respond
            if (false == is_semver_version) {
              target_el.setCustomValidity(
                "The version name is not a valid semver version."
              );
              this.errors.push(
                "The version name is not a valid semver version."
              );
            }
          }
        }
      }

      return this.errors.length == 0;
    },

    configChanged: function (event) {
      this.resetValidity(event);

      if (this.config_str != this.config_str_original) {
        this.object_config_state = CONFIG_STATES_ENUM.WAITING_TO_BE_SEND;
      } else {
        this.object_config_state = this.object_loaded["config_state"];
      }
    },

    resetValidity: function (event) {
      const target_el = event.target;
      target_el.setCustomValidity("");
    },

    firmwareFileLoaded: async function (object) {
      this.firmware_file = object;
      const file_size_in_bytes = null == object ? 0 : object.size;
      this.object_size = file_size_in_bytes + " bytes";

      // force the input to take changes into account
      const target_form = this.$refs["form"];
      target_form.elements["size"].value = this.object_size;

      var hash = await hashFile(this.firmware_file);
      this.object_firmware_hash = hash;
    },

    documentFileLoaded: async function (object) {
      this.document_file = object;
      this.object_content = object ? object.name : null;
    },

    downloadFirmwareFile: function (e) {
      e.preventDefault();
      downloadFirmwareFile(this.object_id);
    },

    downloadDocument: function (e) {
      e.preventDefault();
      downloadDocumentFile(this.object_id);
    },

    updateDevicesList: async function () {
      const object_id = new URL(location.href).searchParams.get("id");

      const object_loaded = await getObjectJson(
        object_id,
        this.object_category,
        true
      );
      if (null != object_loaded) {
        this.object_devices = object_loaded.devices;
      }
    },

    updateDocUsedInLists: async function () {
      const object_loaded = await getObjectJson(
        this.object_id,
        this.object_category,
        true
      );
      // Update only the used_in field
      if (null != object_loaded) {
        this.object_loaded["used_in"] = object_loaded.used_in;
      }
    },

    download_certificate: function () {
      downloadCertificateFile(this.object_id);
    },

    download_private_key: function () {
      downloadPrivateKeyFile(this.object_id);
    },

    alow_editing_provisioning_data: function () {
      if (this.provisioning_edit_checkbox_value) {
        this.provisioning_edit_enabled = true;
      }
    },

    cancel_edit() {
      const object_to_view_id = this.object_id;
      const viewModeUrl = getViewUrl(object_to_view_id, this.object_category);
      this.$router.push(viewModeUrl);
    },

    getViewUrlFor(object_id, object_category) {
      return getViewUrl(object_id, object_category);
    },

    async getConfiguration(config_id) {
      const config_loaded = await getConfigJson(config_id);

      for (let i = 0; i < this.config_history_list.length; ++i) {
        const config = this.config_history_list[i];
        if (config.id == config_id) {
          config.config_loaded = true;
          try {
            config.json = JSON.parse(config_loaded.config);
          } catch (error) {
            console.log("Error parsing config json: " + error);
            config.json = config_loaded.config;
          }
          this.config_history_list[i] = config;
        }
      }
    },

    resolveConflictPucs() {
      // It is enough to notify the PUCS that the configuration conflict is resolved.
      this.object_config_state = CONFIG_STATES_ENUM.WAITING_TO_BE_SEND;

      this.config_resolution_state =
        CONFLICT_RESOLUTION_STATE_ENUM.PUCS_CHOOSEN;
    },

    resolveConflictDevice() {
      // Use the device's configuration. Notify the PUCS that the configurations are synched.
      var last_config_on_device =
        this.config_history_list[this.config_history_list.length - 1];
      this.object_config_id = last_config_on_device.id;
      this.object_config_state = CONFIG_STATES_ENUM.SYNCED;

      this.config_resolution_state =
        CONFLICT_RESOLUTION_STATE_ENUM.DEVICE_CHOOSEN;
    },

    async set_doc_global_type(is_basic_type = false) {
      if (is_basic_type) {
        this.doc_global_type = DOC_GLOBAL_TYPE_ENUM.BASIC;
        this.object_category = OBJECT_CATEGORIES.BASIC_DOCUMENT;
      } else {
        this.doc_global_type = DOC_GLOBAL_TYPE_ENUM.COLLECTION;
        this.object_category = OBJECT_CATEGORIES.DOCUMENTS_COLLECTION;
      }
    },

    reloadDocumentType() {
      this.basic_doc_type = null;
      this.object_content = null;
      this.object_target_class = null;
      this.object_name = null;
    },

    reloadData() {
      this.initObjectWithData();
    },
  },
};
</script>

<style scoped>
.sub-container {
  margin: 1em 0;
}

.negative-margin {
  margin-left: -1.5em;
}
</style>
