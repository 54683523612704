<template>
  <span>
    <input
      v-show="!file_attached || file_is_upload"
      style="color: black"
      ref="browse_file_input"
      @change="uploadFile"
      type="file"
      :disabled="disabled || file_is_upload || file_attached"
      :accept="is_image ? 'image/*' : null"
    />
    <a v-if="!disabled && (file_is_upload || file_attached)" @click="resetFile">
      <v-icon>mdi-delete</v-icon>
    </a>
  </span>
</template>

<script>
export default {
  name: "FileUploader",

  props: {
    disabled: {
      default: false,
      type: Boolean
    },
    is_image: {
      default: false,
      type: Boolean
    },
    file_attached: {
      default: false,
      type: Boolean
    }
  },

  data() {
    return {
      file_is_upload: false
    };
  },

  methods: {
    uploadFile(ev) {
      const file = ev.target.files[0];

      if (this.is_image) {
        const reader = new FileReader();
        reader.onload = e => this.$emit("load", e.target.result);
        reader.readAsDataURL(file);
      } else {
        this.$emit("load", file);
      }

      this.file_is_upload = true;
    },

    resetFile() {
      this.$refs["browse_file_input"].value = null;
      this.$emit("load", null);
      this.file_is_upload = false;
    },

    validateIsNotEmpty(clear_validation = false) {
      const target_el = this.$refs["browse_file_input"];

      if (this.file_is_upload || clear_validation) {
        target_el.setCustomValidity("");
        return true;
      } else {
        target_el.setCustomValidity("Uploading the file is required.");
        return false;
      }
    }
  }
};
</script>
