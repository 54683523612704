<template>
  <div class="sub-container grey--text">
    <label class="pb-0">Is currently on:</label>

    <a v-if="list_to_show.length > 0" href="#" @click="downloadListAsCsv()">
      <v-icon color="blue"> mdi-download </v-icon>
    </a>

    <table>
      <tbody>
        <tr v-for="(object, key) in list_to_show" :key="key">
          <td>
            <v-icon> mdi-devices </v-icon>
            {{ object.type }}
          </td>
          <router-link :to="getViewTargetUrl(object)">
            <td>{{ object.name }}</td>
          </router-link>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { formatDate } from "../../../utils/DateUtils";
import { exportToCsv } from "../../../utils/helpers";
import { getDeviceCategory, getViewUrl } from "../../../utils/RoutesUtils";

export default {
  name: "CurrentlyOnTable",

  props: {
    firmware_name: {
      type: String,
      default: null
    },
    firmware_version: {
      type: String,
      default: null
    },
    is_currently_on: {
      type: Array,
      default: () => []
    }
  },

  computed: {
    list_to_show: function() {
      if (null != this.is_currently_on) {
        return this.is_currently_on;
      }
      return [];
    }
  },

  methods: {
    getViewTargetUrl: function(object) {
      return getViewUrl(object.id, getDeviceCategory());
    },

    downloadListAsCsv() {
      const rows = [];
      const headers = ["Name", "ID"];
      rows.push(headers);
      for (let i = 0; i < this.list_to_show.length; i++) {
        const row = [];
        row.push(this.list_to_show[i].name);
        row.push(this.list_to_show[i].id);
        rows.push(row);
      }

      const date_str = formatDate(new Date());
      const fileName =
        "devices_with_" +
        this.firmware_name +
        "_" +
        this.firmware_version +
        "_" +
        date_str +
        ".csv";
      exportToCsv(fileName, rows);
    }
  }
};
</script>

<style scoped>
table {
  margin: 0;
}

.sub-container {
  margin: 0;
  margin-bottom: 1em;
}
</style>
