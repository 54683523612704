<template>
  <div class="sub-container" v-if="fields.length > 0">
    <label>Type fields:</label>
    <br />

    <div v-for="(field, key) in fields" :key="key">
      <label>{{ field.name }} ({{ getCustomFieldType(field) }}):</label>

      <v-checkbox
        v-if="field.type == 1"
        class="d-inline-block mt-2"
        hide-details
        v-model="field.value"
        :disabled="disabled"
      ></v-checkbox>

      <v-text-field
        v-else
        :type="field.type == 2 ? 'number' : 'text'"
        class="no-underline limit-width-15 d-inline-block  mt-0 pt-0"
        hide-details
        v-model="field.value"
        :disabled="disabled"
      />
    </div>
  </div>
</template>

<script>
function fixTypeAndValue(field) {
  if (null == field.type) field.type = 0;
  if (1 == field.type && null == field.value) field.value = false;
  return field;
}

import { getCustomFieldType } from "../../utils/helpers";
import BaseButton from "./BaseButton.vue";
export default {
  name: "CustomFieldsFromType",

  components: { BaseButton },
  props: {
    disabled: Boolean,
    value_json: String
  },

  data() {
    return {
      fields: []
    };
  },

  watch: {
    fields: {
      // This will let Vue know to look inside the array
      deep: true,

      handler() {
        this.notifyParent();
      }
    },
    value_json: {
      handler() {
        this.convertInputToFields();
      },
      deep: true
    }
  },

  created: function() {
    //this.addEmptyField();
  },

  methods: {
    notifyParent: function() {
      this.$emit("change", this.getFieldsAsJson());
    },

    getFieldsAsJson: function() {
      return JSON.stringify(this.fields);
    },

    convertInputToFields: function() {
      try {
        const fields = JSON.parse(this.value_json);
        if (null != fields) {
          // The type by default is string
          for (let i = 0; i < fields.length; i++) {
            const field = fields[i];
            field = fixTypeAndValue(field);
          }

          this.fields = fields;
        }
      } catch (e) {
        // parse error? --> create a custom field (empty or with a value)
        this.fields = [];

        if (null == this.value_json || this.value_json.length == 0) {
          //this.addEmptyField();
        } else {
          this.addField("Custom", this.value_json, this.value_type);
        }
      }
    },

    getCustomFieldType(field) {
      return getCustomFieldType(field);
    }
  }
};
</script>

<style scoped>
form input {
  width: 10em;
}
form label {
  min-width: 1em;
}
.sub-container {
  margin: 0em;
}
.v-text-field.limit-width-15 {
  max-width: 15em;
}
.v-text-field input {
  padding: 0.5em !important;
}
</style>
