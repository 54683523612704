<template>
  <!-- Current firmware is not set -->
  <span v-if="null == current_firmware_id" class="ml-0">
    <!-- Target firmware is not set either: show empty firmware icon. -->
    <v-icon v-if="null == target_firmware_id">
      mdi-book-off-outline
    </v-icon>

    <!-- Target firmware is set: show "firmware is waiting to be sent" icon. -->
    <v-icon v-else color="alert"> mdi-book-clock-outline </v-icon>
  </span>

  <!-- Current firmware is set -->
  <span v-else>
    <!-- Target firmware is not set but current firmware is: do not show any icon. -->
    <span v-if="null == target_firmware_id"> </span>

    <!-- Current firmware is set and target firmware is set. -->
    <span v-else class="ml-0">
      <!-- Current firmware is equal to target firmware: show "firmware is up to date" icon. -->
      <v-icon
        v-if="
          null != target_firmware_id &&
            null != current_firmware_id &&
            target_firmware_id == current_firmware_id
        "
        color="ok"
      >
        mdi-book-check-outline
      </v-icon>

      <!-- Current firmware is not equal to target firmware: show "firmware is waiting to be sent" icon. -->
      <v-icon v-else color="alert"> mdi-book-clock-outline </v-icon>
    </span>
  </span>
</template>

<script>
export default {
  name: "ActualFirmwareIcon",

  props: {
    current_firmware_id: {
      type: String,
      default: null
    },
    target_firmware_id: {
      type: String,
      default: null
    }
  }
};
</script>
