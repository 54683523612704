<template>
  <div>
    <div>
      <table ref="objects">
        <thead>
          <tr>
            <th>
              {{ selectAll ? "Deselect all:" : "Select all:" }}
              <input type="checkbox" v-model="selectAll" @click="select" />
            </th>
            <th>Name</th>
            <th>Current group</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(object, key) in filtredObjectsList"
            :key="key"
            class="list-row"
            :class="{ selected: isSelected(object.id) }"
          >
            <td class="text-center">
              <input type="checkbox" :value="object.id" v-model="selected" />
            </td>
            <td>{{ object.name }}</td>
            <td>
              <span v-if="object.group == null" class="font-italic">-</span>
              <span v-else>
                <router-link :to="getGroupViewUrl(object.group.id)">
                  {{ object.group.name }}
                </router-link>
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import {
  getViewUrl,
  getEditUrl,
  getNewUrl,
  getCategoryLabel,
  isDevice,
  isGroup,
  isFirmware,
  getTypeCategory,
  getGroupCategory,
  getFirmwareCategory,
} from "../../../utils/RoutesUtils";
import { getObjectsList } from "../../../utils/ServerUtils.js";

export default {
  name: "DeviceTableSimplified",
  props: {
    object_category: Number,
    selected_type_id: String,
  },

  data() {
    return {
      objects_list: [],
      table_columns: [],
      selected_group_id: null,

      selected: [],
      selectAll: false,
    };
  },

  watch: {
    table_columns: {
      handler: function () {
        // Show/hide column when a checkbox is clicked
        const table = this.$refs["objects"];
        if (null != table) {
          const cols = table.getElementsByTagName("col");
          for (let i = 0; i < cols.length; i++) {
            if (false == this.table_columns[i].checked) {
              cols[i].style = "visibility:collapse;";
            } else {
              cols[i].style = "";
            }
          }
        }
      },
      deep: true,
    },

    selected: function () {
      this.$emit("deviceSelectionChange", this.selected);
    },
  },

  mounted: async function () {
    this.getTableColumns();

    const objects_list = await getObjectsList(this.object_category);
    if (null != objects_list) this.objects_list = objects_list;
  },

  computed: {
    filtredObjectsList: function () {
      if (this.hasType && null != this.selected_type_id) {
        var filtred_objects_list = [];
        for (let i = 0; i < this.objects_list.length; i++) {
          if (this.objects_list[i].type_id == this.selected_type_id) {
            if (this.isDevice && null != this.selected_group_id) {
              if (this.objects_list[i].group_id == this.selected_group_id) {
                filtred_objects_list.push(this.objects_list[i]);
              }
            } else {
              filtred_objects_list.push(this.objects_list[i]);
            }
          }
        }
        return filtred_objects_list;
      } else {
        return this.objects_list;
      }
    },

    getHeading: function () {
      const objects_num_string = this.objects_list.length;
      return (
        getCategoryLabel(this.object_category, true) +
        " ( " +
        objects_num_string +
        " )"
      );
    },

    hasTargetFirmware: function () {
      return isDevice(this.object_category) || isGroup(this.object_category);
    },

    hasType: function () {
      return (
        isDevice(this.object_category) ||
        isGroup(this.object_category) ||
        isFirmware(this.object_category)
      );
    },

    hasDate: function () {
      return isDevice(this.object_category) || isFirmware(this.object_category);
    },

    isDevice: function () {
      return isDevice(this.object_category);
    },

    isFirmware: function () {
      return isFirmware(this.object_category);
    },

    getNewUrl: function () {
      return getNewUrl(this.object_category);
    },

    getTypeCategory() {
      return getTypeCategory();
    },

    getGroupCategory() {
      return getGroupCategory();
    },
  },

  methods: {
    typeIsSelected: function (object) {
      if (null != object) {
        this.selected_type_id = object.id;
      } else {
        this.selected_type_id = null;
      }
    },

    groupIsSelected: function (object) {
      if (null != object) {
        this.selected_group_id = object.id;
      } else {
        this.selected_group_id = null;
      }
    },

    formatDate: function (object_date) {
      var d = Date.parse(object_date);
      return new Date(d).toLocaleDateString();
    },

    getViewUrl: function (object_id) {
      return getViewUrl(object_id, this.object_category);
    },

    getFirmwareViewUrl: function (object_id) {
      return getViewUrl(object_id, getFirmwareCategory());
    },

    getGroupViewUrl: function (object_id) {
      return getViewUrl(object_id, getGroupCategory());
    },

    getTypeViewUrl: function (object_id) {
      return getViewUrl(object_id, getTypeCategory());
    },

    getEditUrl: function (object_id) {
      return getEditUrl(object_id, this.object_category);
    },

    getTableColumns: function () {
      this.table_columns = [];
      const table = this.$refs["objects"];
      if (null != table) {
        const columnHeaders = table.rows[0].cells;
        for (let i = 0; i < columnHeaders.length; i++) {
          const checked = "ID" == columnHeaders[i].innerHTML ? false : true;
          this.table_columns.push({
            checked: checked,
            text: columnHeaders[i].innerHTML,
          });
        }
      }
    },

    select() {
      // From https://vuejsexamples.com/vuejs-tables-and-select-all-checkbox/
      this.selected = [];
      if (!this.selectAll) {
        for (let i = 0; i < this.filtredObjectsList.length; i++) {
          this.selected.push(this.filtredObjectsList[i].id);
        }
      }
    },

    isSelected(object_id) {
      return this.selected.includes(object_id);
    },
  },
};
</script>
