<template>
  <div v-if="json_to_download != null">
    Download
    <a
      class="ml-1"
      href="#"
      @click="downloadJson($event)"
      title="Download JSON"
    >
      <v-icon color="primary"> mdi-download </v-icon>
    </a>
  </div>
</template>

<script>
import { downloadObjectAsJson } from "../../../utils/helpers";

export default {
  props: {
    filename: {
      type: String,
      default: "json",
    },
    json_to_download: {
      type: Object,
      default: null,
    },
  },

  methods: {
    downloadJson(ev) {
      ev.preventDefault();
      downloadObjectAsJson(this.json_to_download, this.filename);
    },
  },
};
</script>
