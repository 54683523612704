import axios from "axios";
import {
  getListOfObjectsUrl,
  isFirmware,
  isGroup,
  getDeviceCategory,
  getTypeCategory,
  getFirmwareCategory,
  getGroupCategory,
  OBJECT_CATEGORIES,
  isDevice,
  isType,
  getConfigCategory,
  isDocument,
  getDocumentsCollectionCategory
} from "./RoutesUtils";

const OBJECT_TABLE_NAMES = [
  "",
  "device",
  "devices_group",
  "type",
  "firmware",
  "team",
  "role",
  "user",
  "config",
  "basic_document",
  "basic_document",
  "documents_collection"
];

export const CONFIG_STATES_ENUM = {
  SYNCED: "synchronized", // configs are in sync
  WAITING_TO_BE_SEND: "waiting_to_be_send", // waiting for the device to receive the config
  CONFLICT: "conflict" // conflict between the PUCS (local) and the device's (remote) config
};

export const CONFLICT_RESOLUTION_STATE_ENUM = {
  UNRESOLVED: 0,
  PUCS_CHOOSEN: 1,
  DEVICE_CHOOSEN: 2
};

export const BASIC_DOCUMENT_TYPES_ENUM = {
  TEXT: "text",
  LINK: "link",
  FILE: "file",
  IMAGE: "image",
  LABEL_TEMPLATE: "label_template",
  OTHER: "other"
};

// ---------------------------
// Public functions
// ---------------------------

export async function deleteObject(
  object_id,
  object_category,
  object_name = "",
  do_not_ask_confirmation = false
) {
  var delete_result = false;
  if (do_not_ask_confirmation) {
    await deleteObjectNoConfirmation(object_id, object_category);
  } else {
    if (confirm("Are you sure you want to delete '" + object_name + "'?")) {
      delete_result = await deleteObjectNoConfirmation(
        object_id,
        object_category
      );
    } else {
      // Do nothing!
    }
  }
  return delete_result;
}

export async function deleteType(type_object_to_delete) {
  const type_id = type_object_to_delete.id;
  const type_name = type_object_to_delete.name;
  const devices =
    "devices" in type_object_to_delete ? type_object_to_delete.devices : [];
  const firmwares =
    "firmwares" in type_object_to_delete ? type_object_to_delete.firmwares : [];
  const devices_groups =
    "devices_groups" in type_object_to_delete
      ? type_object_to_delete.devices_groups
      : [];

  if (
    devices.length == 0 &&
    firmwares.length == 0 &&
    devices_groups.length == 0
  ) {
    await deleteObject(type_id, getTypeCategory(), type_name);
  } else {
    if (
      confirm(
        "Are you sure you want to remove the type '" +
          type_name +
          "' and ALL devices, groups and firmwares of this type?"
      )
    ) {
      for (let i = 0; i < devices.length; i++) {
        await deleteObjectNoConfirmation(
          devices[i].id,
          getDeviceCategory(),
          true
        );
      }
      for (let i = 0; i < devices_groups.length; i++) {
        await deleteObjectNoConfirmation(
          devices_groups[i].id,
          getGroupCategory(),
          true
        );
      }
      for (let i = 0; i < firmwares.length; i++) {
        await deleteObjectNoConfirmation(
          firmwares[i].id,
          getFirmwareCategory(),
          true
        );
      }
      await deleteObjectNoConfirmation(type_id, getTypeCategory());
    } else {
      // Do nothing!
    }
  }

  return;
}

export async function deleteDeviceFromGroup(device) {
  await changeDeviceGroup(device.id, null);
}

export async function getObjectJson(
  object_id,
  object_category,
  get_full_object = false
) {
  var object_json = null;

  if (null != object_id) {
    const url_req = get_full_object
      ? getFullObjectUrl(object_id, object_category)
      : getEditObjectUrl(object_id, object_category);

    await axios
      .get(url_req)
      .then(response => {
        object_json = response.data;
      })
      .catch(error => {
        showServerErrorMessage(error);
      });
  }

  return object_json;
}

export async function getTypeDocumentation(object_id) {
  const object_category = OBJECT_CATEGORIES.TYPE;
  return await getObjectDocumentation(object_category, object_id);
}

export async function getGroupDocumentation(object_id) {
  const object_category = OBJECT_CATEGORIES.GROUP;
  return await getObjectDocumentation(object_category, object_id);
}

export async function getFirmwareDocumentation(object_id) {
  const object_category = OBJECT_CATEGORIES.FIRMWARE;
  return await getObjectDocumentation(object_category, object_id);
}

export async function getObjectDocumentation(object_category, object_id) {
  var object_json = null;

  if (null != object_id) {
    const only_names_for_subfields = "?fields=basic_docs_list";

    const url_req =
      "/api/full/" +
      OBJECT_TABLE_NAMES[object_category] +
      "/" +
      object_id +
      only_names_for_subfields;

    await axios
      .get(url_req)
      .then(response => {
        object_json = response.data;
      })
      .catch(error => {
        showServerErrorMessage(error);
      });
  }

  return object_json != null ? object_json.basic_docs_list : null;
}

export async function getDeviceJson(object_id) {
  return getObjectJson(object_id, getDeviceCategory(), true);
}

export async function getConfigJson(object_id) {
  return getObjectJson(object_id, getConfigCategory(), true);
}

export async function getTypeIcon(type_id) {
  var type_icon = null;

  if (null != type_id) {
    const url_req = getTypeIconUrl(type_id);

    await axios
      .get(url_req)
      .then(response => {
        if (null != response.data && null != response.data.icon) {
          type_icon = response.data.icon;
        }
      })
      .catch(error => {
        showServerErrorMessage(error);
      });
  }

  return type_icon;
}

export async function getUser() {
  var object_json = null;

  const url_req = "/api/current_user";
  await axios
    .get(url_req)
    .then(response => {
      object_json = response.data;
      if ("" == object_json) object_json = null;
    })
    .catch(error => {
      showServerErrorMessage(error);
    });

  return object_json;
}

export async function getDeviceQrCode(device_id) {
  var object_json = null;
  const url_req = "/api/device_qr_code/" + device_id;

  await axios
    .get(url_req)
    .then(response => {
      object_json = response.data;
      if ("" == object_json) object_json = null;
    })
    .catch(error => {
      if (null != error.response && error.response.status == 404) {
        console.log("QR code is not yet generated.");
      } else {
        console.log(error);
      }
    });

  return object_json;
}

export async function createDeviceQrCode(device_id) {
  var object_json = null;
  const url_req = "/api/device_qr_code/" + device_id;

  await axios
    .post(url_req, {})
    .then(response => {
      object_json = response.data;
      if ("" == object_json) object_json = null;
    })
    .catch(error => {
      // showServerErrorMessage(error);
      console.log(error);
    });

  return object_json;
}

export async function executeQrCodeAction(qr_code_id) {
  var object_json = null;
  const url_req = "/api/execute_qr_code_action/" + qr_code_id;

  await axios
    .get(url_req)
    .then(response => {
      object_json = response.data;
      if ("" == object_json) object_json = null;
    })
    .catch(error => {
      // showServerErrorMessage(error);
      console.log(error);
    });

  return object_json;
}

export async function getDeviceByQrAndClaimCodesCall(qr_code_id, claim_code) {
  const url_req = "/api/get_device_by_qr_and_claim_codes/" + qr_code_id;
  const request_body = { claim_code: claim_code };

  var response_object = null;
  await axios
    .put(url_req, request_body)
    .then(response => {
      response_object = response;
    })
    .catch(error => {
      showServerErrorMessage(error);
    });
  return response_object;
}

export async function addStratagemUserToTeam(team_id) {
  const url_req = "/api/add_strataggem_user_to_team/";
  const request_body = {
    team_id: team_id
  };

  var response_object = null;
  await axios
    .put(url_req, request_body)
    .then(response => {
      response_object = response;
    })
    .catch(error => {
      showServerErrorMessage(error);
    });
  return response_object;
}

export async function claimDeviceCall(
  device_id,
  claim_code,
  team_id,
  type_id,
  target_firmware_id,
  should_firmware_be_copied = false
) {
  const url_req = "/api/claim_device/" + device_id;
  const request_body = {
    claim_code: claim_code,
    team_id: team_id,
    type_id: type_id,
    target_firmware_id: target_firmware_id,
    should_firmware_be_copied: should_firmware_be_copied
  };

  var response_object = null;
  await axios
    .put(url_req, request_body)
    .then(response => {
      response_object = response;
    })
    .catch(error => {
      showServerErrorMessage(error);
    });
  return response_object;
}

export async function setDeviceAsToBeClaimed(device_id, to_be_claimed = true) {
  const url_req = "/api/set_device_claimed_status/" + device_id;
  const request_body = { to_be_claimed: to_be_claimed };

  var response_object = null;
  await axios
    .put(url_req, request_body)
    .then(response => {
      response_object = response;
    })
    .catch(error => {
      showServerErrorMessage(error);
    });
  return response_object;
}

export async function addNewObject(object_category, request_body) {
  var object_json = null;

  if (null != request_body) {
    const url_req = getAddObjectUrl(object_category);

    await axios
      .post(url_req, request_body)
      .then(response => {
        object_json = response.data;
      })
      .catch(error => {
        showServerErrorMessage(error);
      });
  }
  return object_json;
}

export async function editUserPreferences(object_id, preferences) {
  editObject(object_id, OBJECT_CATEGORIES.USER, { preferences: preferences });
}

export async function createNewConfiguration(config_json_str, team_id) {
  return addNewObject(getConfigCategory(), {
    config: config_json_str,
    team_id: team_id
  });
}

export async function editObject(object_id, object_category, request_body) {
  var object_json = null;

  if (null != request_body) {
    if (Object.keys(request_body).length == 0) {
      console.log("The request body is empty.");
    }

    const url_req = getEditObjectUrl(object_id, object_category);

    await axios
      .put(url_req, request_body)
      .then(response => {
        object_json = response.data;
      })
      .catch(error => {
        showServerErrorMessage(error);
      });
  }
  return object_json;
}

export async function addSelectedDevicesToGroup(
  selected_devices_ids,
  group_id
) {
  for (let i = 0; i < selected_devices_ids.length; i++) {
    // await is needed in order to notify the parent to update the list of groups
    await changeDeviceGroup(selected_devices_ids[i], group_id);
  }
}

export async function addDocToObjects(
  selected_devices_ids,
  doc_id,
  object_category,
  team_id
) {
  for (let i = 0; i < selected_devices_ids.length; i++) {
    // await is needed in order to notify the parent to update the list of groups
    await addDocToObject(
      selected_devices_ids[i],
      doc_id,
      object_category,
      team_id
    );
  }
}

export async function deleteDocFromObject(
  object_id,
  doc_id_to_del,
  object_category,
  team_id
) {
  const old_doc_collection = await getObjectDocumentCollection(
    object_id,
    object_category
  );

  const object_basic_docs_list = deleteDocFromCollection(
    old_doc_collection,
    doc_id_to_del
  );

  await editObjectWithUpdatedDocCollections(
    old_doc_collection,
    object_basic_docs_list,
    team_id,
    object_category,
    object_id
  );
}

export async function getExistingNames(object_category) {
  const get_names_with_versions = isFirmware(object_category);

  const url_req =
    "/api/" +
    OBJECT_TABLE_NAMES[object_category] +
    "?fields=name,team_id" +
    (get_names_with_versions ? ",version" : "");

  var names = [];
  try {
    const response = await axios.get(url_req);
    const data = response.data["json_list"];

    for (let i = 0; i < data.length; i++) {
      var current_name_per_team = {
        name: data[i].name,
        team_id: data[i].team_id
      };
      if (get_names_with_versions) {
        current_name_per_team["version"] = data[i].version;
      }
      names.push(current_name_per_team);
    }
  } catch (error) {
    showServerErrorMessage(error);
  }
  return names;
}

export async function isSemver(string_to_test) {
  const is_semver_url = getIsSemverUrl(string_to_test);
  var is_semver = null;

  await axios
    .get(is_semver_url)
    .then(response => {
      is_semver = response.data;
    })
    .catch(error => {
      showServerErrorMessage(error);
    });

  return is_semver;
}

export async function getTeamsListWhereUserIsAdminCall() {
  var json_list = null;

  const url_req = "/api/get_teams_where_user_is_admin/";

  await axios
    .get(url_req)
    .then(response => {
      json_list = response.data["json_list"];
    })
    .catch(error => {
      showServerErrorMessage(error);
    });
  return json_list;
}

export async function getObjectsList(
  object_category,
  is_short_version = false
) {
  var json_list = null;

  const url_req = is_short_version
    ? getShortTableUrl(object_category)
    : getFullTableUrl(object_category);

  await axios
    .get(url_req)
    .then(response => {
      json_list = response.data["json_list"];
    })
    .catch(error => {
      showServerErrorMessage(error);
    });
  return json_list;
}

export async function uploadDocumentFile(object_id, file) {
  const url_req = "/api/upload/document/" + object_id;
  return await uploadFile(url_req, file, "document");
}

export async function uploadFirmwareFile(object_id, file) {
  const url_req = "/api/upload/firmware/" + object_id;
  return await uploadFile(url_req, file, "firmware");
}

export async function uploadFile(url_req, file, object_category_name) {
  var response_data = null;

  let formData = new FormData();
  formData.append(object_category_name, file);

  await axios
    .post(url_req, formData, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    })
    .then(response => {
      response_data = response.data;
    })
    .catch(error => {
      showServerErrorMessage(error);
    });
  return response_data;
}

export async function DownloadDevicesAsCsv(team_id, type_id, group_id) {
  var url_req = "/api/download/csv_export/" + team_id;

  if (null != type_id) {
    url_req += "?type_id=" + type_id;
    if (null != group_id) {
      url_req += "&group_id=" + group_id;
    }
    await downloadFile(url_req, true);
  } else {
    await downloadFile(url_req, false);
  }
}

export async function downloadFirmwareFile(object_id) {
  const url_req = "/api/download/firmware/" + object_id;
  await downloadFile(url_req);
}

export async function downloadDocumentFile(object_id) {
  const url_req = "/api/download/document/" + object_id;
  await downloadFile(url_req);
}

export async function downloadCertificateFile(object_id) {
  const url_req = "/api/download/certificate/" + object_id;
  await downloadFile(url_req);
}

export async function downloadPrivateKeyFile(object_id) {
  const url_req = "/api/download/private_key/" + object_id;
  await downloadFile(url_req);
}

export function isFirmwareNameValid(name) {
  let re = /[^a-zA-Z0-9 _.-]/i; // literal notation
  if (re.test(name)) {
    return false;
  }
  return true;
}

export async function downloadDeviceLabel(
  device_id,
  translate_x,
  translate_y,
  is_detailed_version = false
) {
  if (isNaN(translate_x)) translate_x = 0;
  if (isNaN(translate_y)) translate_y = 0;

  const download_label_url_part = is_detailed_version
    ? "detailed_label"
    : "model_label";

  const generate_label_url_req =
    "/api/download/" +
    download_label_url_part +
    "/" +
    device_id +
    "?translate_x_in=" +
    translate_x +
    "&translate_y_in=" +
    translate_y;

  downloadFile(generate_label_url_req, true);
}

export async function downloadDeviceLabelCustomTemplate(
  device_id,
  translate_x,
  translate_y,
  template_str
) {
  const generate_label_url_req =
    "/api/download/custom_label/" +
    device_id +
    "?translate_x_in=" +
    translate_x +
    "&translate_y_in=" +
    translate_y;

  downloadFilePost(
    generate_label_url_req,
    true,
    template_str,
    "Please make sure that the string is a valid Python template that becomes a valid SVG after key substitution."
  );
}

export async function hashFile(file) {
  //https://www.npmjs.com/package/js-sha3

  // Load the file
  const arrayBuffer = await readFileAsync(file);

  // Configure hash algorithm
  const sha3_256 = require("js-sha3").sha3_256;
  var sha3 = sha3_256.create();

  // Hash the file
  sha3.update(arrayBuffer);
  const encrypted = sha3.hex();
  return encrypted.toString();
}

export async function createDocumentsCollection(object_basic_docs_list, team_id, custom_name = null) {
  const req_body = {};
  req_body["team_id"] = team_id;
  req_body["basic_docs_list"] = object_basic_docs_list;

  var new_doc_name = null;
  if (custom_name == null) {
  // Automatically generated name
   new_doc_name = "docs_collection_" + Math.random();    
  } else {
    new_doc_name = custom_name;
  }
  req_body["name"] = new_doc_name;

  return await addNewObject(getDocumentsCollectionCategory(), req_body);
}

export async function editDocumentsCollection(doc_collection_id, object_basic_docs_list) {
  const req_body = {};  
  req_body["basic_docs_list"] = object_basic_docs_list;
  return await editObject(doc_collection_id, getDocumentsCollectionCategory(), req_body);
}


// ---------------------------
// Private
// ---------------------------

async function addDocToObject(object_id, doc_id_to_add, object_category, team_id) {
  const old_doc_collection = await getObjectDocumentCollection(
    object_id,
    object_category
  );
  
  const object_basic_docs_list = addDocToCollection(
    old_doc_collection,
    doc_id_to_add
  );
 
  await editObjectWithUpdatedDocCollections(
    old_doc_collection,
    object_basic_docs_list,
    team_id,
    object_category,
    object_id
  );  
}

function addDocToCollection(old_doc_collection, doc_id_to_add) {
  var old_docs_list =
    old_doc_collection != null && "basic_docs_list" in old_doc_collection
      ? old_doc_collection.basic_docs_list
      : [];

  var object_basic_docs_list = [];
  for (var doc of old_docs_list) {
    object_basic_docs_list.push({ id: doc.id });
  }
  object_basic_docs_list.push({ id: doc_id_to_add });

  return object_basic_docs_list;
}

function deleteDocFromCollection(old_doc_collection, doc_id_to_del) {
  var old_docs_list =
    old_doc_collection != null && "basic_docs_list" in old_doc_collection
      ? old_doc_collection.basic_docs_list
      : [];

  var object_basic_docs_list = [];
  for (var doc of old_docs_list) {
    if (doc.id != doc_id_to_del) {
      object_basic_docs_list.push({ id: doc.id });
    }
  }
  return object_basic_docs_list;
}

async function editObjectWithUpdatedDocCollections(
  old_doc_collection,
  object_basic_docs_list,
  team_id,
  object_category,
  object_id
) {
  var doc_coll = null;
  if (old_doc_collection == null || old_doc_collection.documents_collection_id == null) {
    doc_coll = await createDocumentsCollection(object_basic_docs_list, team_id);
  } else {
    doc_coll = await editDocumentsCollection(
      old_doc_collection.documents_collection_id,
      object_basic_docs_list
    );
  }

  const new_doc_collection_id = doc_coll.id;
  var body = {};
  body.documents_collection_id = new_doc_collection_id;

  editObject(object_id, object_category, body);
}

async function downloadFile(url_req, queries_are_present_in_url = false) {
  // append "?[random_number]" at the end of
  // the dowloaded file name to avoid caching

  var url_with_random_part = url_req;
  if (queries_are_present_in_url) {
    url_with_random_part += "&r=" + Math.random();
  } else {
    url_with_random_part += "?r=" + Math.random();
  }

  await axios({
    url: url_with_random_part,
    method: "GET",
    responseType: "blob" // important
  })
    .then(response => {
      const blob_with_file = new Blob([response.data]);
      const url = window.URL.createObjectURL(blob_with_file);
      const link = document.createElement("a");
      link.href = url;

      // Filename
      const header_with_filename = response.headers["content-disposition"];
      const str_to_find = "filename=";
      const n = header_with_filename.search(str_to_find);
      const filename =
        n > -1
          ? header_with_filename.substring(n + str_to_find.length)
          : "firmware.bin";
      link.setAttribute("download", filename);
      link.setAttribute("target", "_blank");
      document.body.appendChild(link);

      link.click();
      URL.revokeObjectURL(link.href);
    })
    .catch(error => {
      showServerErrorMessage(error);
    });
}

async function downloadFilePost(
  url_req,
  queries_are_present_in_url = false,
  template_str,
  additional_error_message = null
) {
  // append "?[random_number]" at the end of
  // the dowloaded file name to avoid caching

  var url_with_random_part = url_req;
  if (queries_are_present_in_url) {
    url_with_random_part += "&r=" + Math.random();
  } else {
    url_with_random_part += "?r=" + Math.random();
  }

  await axios({
    url: url_with_random_part,
    method: "POST",
    responseType: "blob", // important
    data: { template_str: template_str }
  })
    .then(response => {
      const blob_with_file = new Blob([response.data]);
      const url = window.URL.createObjectURL(blob_with_file);
      const link = document.createElement("a");
      link.href = url;

      // Filename
      const header_with_filename = response.headers["content-disposition"];
      const str_to_find = "filename=";
      const n = header_with_filename.search(str_to_find);
      const filename =
        n > -1
          ? header_with_filename.substring(n + str_to_find.length)
          : "firmware.bin";
      link.setAttribute("download", filename);
      link.setAttribute("target", "_blank");
      document.body.appendChild(link);

      link.click();
      URL.revokeObjectURL(link.href);
    })
    .catch(error => {
      console.log("additional_error_message: " + additional_error_message);
      showServerErrorMessage(error, additional_error_message);
    });
}

function readFileAsync(file) {
  // https://simon-schraeder.de/posts/filereader-async/

  return new Promise((resolve, reject) => {
    let reader = new FileReader();
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.onerror = reject;
    reader.readAsArrayBuffer(file);
  });
}

function get_names_for_subfields() {
  return "?fields=device(name,id),devices_group(name,id),firmware(name,version,id),user(username,id),type(name,id,name_of_uid)";
}

function getFullTableUrl(
  object_category,
  load_only_names_for_subfields_flag = true
) {
  const only_names_for_subfields = load_only_names_for_subfields_flag
    ? get_names_for_subfields()
    : "";

  return (
    "/api/full/" +
    OBJECT_TABLE_NAMES[object_category] +
    only_names_for_subfields
  );
}

function getShortTableUrl(object_category) {
  var additionalFieldsForDropdowns = "";

  if (
    isGroup(object_category) ||
    isFirmware(object_category) ||
    isDevice(object_category)
  )
    additionalFieldsForDropdowns += ",type_id";

  if (isGroup(object_category) || isDevice(object_category))
    additionalFieldsForDropdowns += ",target_firmware_id";

  if (isFirmware(object_category))
    additionalFieldsForDropdowns += ",version,is_disabled";

  if (isType(object_category)) additionalFieldsForDropdowns += ",name_of_uid";

  if (isDocument(object_category)) additionalFieldsForDropdowns += ",doc_type";

  const fields = "?fields=id,name,team_id" + additionalFieldsForDropdowns;

  return "/api/" + OBJECT_TABLE_NAMES[object_category] + fields;
}

function getIsSemverUrl(string_to_test) {
  return "/api/issemver/" + string_to_test;
}

function getFullObjectUrl(
  object_id,
  object_category,
  load_only_names_for_subfields_flag = false
) {
  const only_names_for_subfields =
    isFirmware(object_category) || load_only_names_for_subfields_flag
      ? get_names_for_subfields()
      : "";

  return (
    "/api/full/" +
    OBJECT_TABLE_NAMES[object_category] +
    "/" +
    object_id +
    only_names_for_subfields
  );
}

function getAddObjectUrl(object_category) {
  return "/api/" + OBJECT_TABLE_NAMES[object_category];
}

function getEditObjectUrl(object_id, object_category) {
  return "/api/" + OBJECT_TABLE_NAMES[object_category] + "/" + object_id;
}

function getTypeIconUrl(type_id) {
  return (
    "/api/" +
    OBJECT_TABLE_NAMES[getTypeCategory()] +
    "/" +
    type_id +
    "?fields=icon"
  );
}

async function deleteObjectNoConfirmation(
  object_id,
  object_category,
  do_not_redirect = true
) {
  const url_req =
    "/api/" + OBJECT_TABLE_NAMES[object_category] + "/" + object_id;

  var delete_result = false;
  await axios
    .delete(url_req)
    .then(response => {
      console.log("Object deleted, status: " + response.status);

      if (!do_not_redirect) {
        const href_url = getListOfObjectsUrl(object_category);
        if (null != href_url) {
          window.location.href = href_url;
        }
      }

      delete_result = true;
    })
    .catch(error => {
      showServerErrorMessage(error);
    });

  return delete_result;
}

async function changeDeviceGroup(device_id, group_id) {
  const url_req = getEditObjectUrl(device_id, getDeviceCategory());

  var body = {};
  body.group_id = group_id;

  await axios
    .put(url_req, body)
    .then((response) => {
      console.log("changeDeviceGroup OK");
    })
    .catch((error) => {
      showServerErrorMessage(error);
    });
}

async function getObjectDocumentCollection(object_id, object_category) {
  return getObjectJson(object_id, object_category, true);
}

function showServerErrorMessage(error, add_message = null) {
  const error_unauthorized_code = 401;
  if (
    null != error.response &&
    error_unauthorized_code == error.response.status
  ) {
    alert("Please sign in before accessing this page.");
    window.location.href = "/";
    return;
  }

  var error_msg = "";

  const error_description_field = "Description";
  if (
    null != error.response &&
    null != error.response.data &&
    null != error.response.data[error_description_field]
  ) {
    error_msg = error.response.data[error_description_field];

    // Custom error message for uid_for_type uniqueness
    const uid_not_unique_msg_part = "Key (uid_for_type, type_id)";
    if (error_msg.includes(uid_not_unique_msg_part)) {
      error_msg =
        "The UID provided is not unique per selected type. Please enter a unique UID.";
    }
  } else {
    if ("Error: Request aborted" != error) error_msg = error.toString();
  }

  if (add_message != null) {
    error_msg += " \n" + add_message;
  }

  alert(error_msg);
}
