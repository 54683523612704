<template>
  <div class="sub-container" v-if="!noCustomFieldsIfView">
    <label>Custom fields (propagated to all devices of the type):</label>
    <br />

    <div v-for="(field, key) in fields" :key="key" class="sub-container my-2">
      <label>Name:</label>
      <input type="text" v-model="field.name" :disabled="disabled" />

      <br />

      <label>Type:</label>
      <select
        :disabled="disabled"
        v-model="field.type"
        @change="typeChanged(field)"
      >
        <option value="0">String</option>
        <option value="1">Boolean</option>
        <option value="2">Integer</option>
      </select>

      <br />
      <label>Default value:</label>

      <v-checkbox
        v-if="field.type == 1"
        class="d-inline-block mt-2"
        hide-details
        v-model="field.value"
        :disabled="disabled"
        @change="valueChanged(field)"
      ></v-checkbox>

      <v-text-field
        v-else
        :type="field.type == 2 ? 'number' : 'text'"
        class="no-underline limit-width-15 d-inline-block  mt-0 pt-0"
        hide-details
        v-model="field.value"
        :disabled="disabled"
        @input="valueChanged(field)"
      />

      <br />

      <v-dialog v-model="delete_dialog[key]" width="500">
        <template v-slot:activator="{ on, attrs }">
          <a v-if="!disabled" v-bind="attrs" v-on="on">
            <v-icon color="blue"> mdi-delete </v-icon>
          </a>
        </template>

        <v-card>
          <v-card-title class="text-h5 grey lighten-2">
            Are you sure you want to delete the field "{{ field.name }}"?
            <br />
            The field will be deleted from all devices of the type.
          </v-card-title>

          <v-card-actions>
            <v-spacer />
            <v-btn text @click="closeDialog(key)">
              No
            </v-btn>
            <v-btn color="primary" text @click="deleteField(key)">
              Yes, delete
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <span class="alert--text">
        {{
          field.exisiting_changed
            ? "Changing this field will not change the type and values of the field in already existing devices."
            : null
        }}</span
      >
    </div>

    <base-button
      v-if="!disabled"
      @click="addEmptyField"
      text="Add a custom field"
    />
  </div>
</template>

<script>
function fixTypeAndValue(field) {
  if (null == field.type) field.type = 0;
  if (1 == field.type && null == field.value) field.value = false;
  return field;
}

function jsonArrayHasKey(array, key) {
  if (key) {
    for (var i = 0; i < array.length; i++) {
      if (array[i].name == key) return true;
    }
  }
  return false;
}

import BaseButton from "../app/BaseButton.vue";
export default {
  name: "CustomFields",

  components: { BaseButton },
  props: {
    disabled: Boolean,
    value_json: String
  },

  data() {
    return {
      fields: [],
      fields_old: [],
      data_recieved: false,

      delete_dialog: []
    };
  },

  watch: {
    fields: {
      // This will let Vue know to look inside the array
      deep: true,

      handler() {
        this.notifyParent();

        for (var i = 0; i < this.fields.length; i++) {
          this.delete_dialog[i] = false;
        }
      }
    },
    value_json: {
      handler() {
        this.convertInputToFields();

        if (this.data_recieved == false) {
          this.data_recieved = true;
          this.fields_old = this.fields;
        }
      },
      deep: true
    }
  },
  computed: {
    noCustomFieldsIfView() {
      if (!this.disabled) return false;
      return (
        undefined == this.value_json ||
        null == this.value_json ||
        "null" == this.value_json ||
        "[]" == this.value_json
      );
    }
  },

  methods: {
    addEmptyField: function(e) {
      e.preventDefault();
      this.addField("", "");
    },

    addField: function(name, value, type = 0) {
      var field = {
        name: name,
        value: value,
        type: type
      };
      field = fixTypeAndValue(field);
      this.fields.push({
        name: field.name,
        value: field.value,
        type: field.type,
        exisiting_changed: false
      });
    },

    notifyParent: function() {
      this.$emit("change", this.getFieldsAsJson());
    },

    getFieldsAsJson: function() {
      return JSON.stringify(this.fields);
    },

    convertInputToFields: function() {
      try {
        const fields = JSON.parse(this.value_json);
        if (null != fields) {
          // The type by default is string
          for (let i = 0; i < fields.length; i++) {
            const field = fields[i];
            field = fixTypeAndValue(field);
          }

          this.fields = fields;
        }
      } catch (e) {
        // parse error? --> create a custom field (empty or with a value)
        this.fields = [];

        if (null == this.value_json || this.value_json.length == 0) {
          //this.addEmptyField();
        } else {
          this.addField("Custom", this.value_json, this.value_type);
        }
      }
    },

    deleteField: function(index) {
      this.closeDialog(index);
      
      if (index > -1) {
        this.fields.splice(index, 1);
      }
    },
    
    closeDialog: function(index) {
      this.delete_dialog[index] = false;
      
      // Trigger a change in delete_dialog array, because otherwise the deep watcher will not be triggered.
      var old = this.delete_dialog
      this.delete_dialog = []
      this.delete_dialog = old
    },

    typeChanged(field) {
      if (field.type == 1) {
        field.value = false;
      } else if (field.type == 2) {
        field.value = 0;
      }

      if (jsonArrayHasKey(this.fields_old, field.name)) {
        field.exisiting_changed = true;
      }
    },

    valueChanged(field) {
      if (jsonArrayHasKey(this.fields_old, field.name)) {
        field.exisiting_changed = true;
      }
    }
  }
};
</script>

<style scoped>
form input {
  width: 10em;
}
form label {
  min-width: 1em;
}
.sub-container {
  margin: 0em;
}
.v-text-field.limit-width-15 {
  max-width: 15em;
}
.v-text-field input {
  padding: 0.5em !important;
}
</style>
