<template>
  <div>
    <v-icon :color="syncIconColor" class="my-2 mr-1">
      {{ syncIconId }}
    </v-icon>
    <span :class="{ 'black--text': isConflict }">
      {{ syncStateText }}
    </span>
    <p>Last configuration synchronization: <span class="black--text">{{formatDate(last_config_sync)}}</span></p>
  </div>
</template>

<script>
import { formatDate } from '../../../utils/DateUtils';
import { CONFIG_STATES_ENUM } from '../../../utils/ServerUtils';

export default {
  props: {
    config_state: {
      type: String,
      default: null
    },
    last_config_sync: {
      type: String,
      default: null
    }
  },
  computed: {
    syncIconId() {
      switch (this.config_state) {
        case CONFIG_STATES_ENUM.SYNCED:
          return "mdi-check-circle";
        case CONFIG_STATES_ENUM.WAITING_TO_BE_SEND:
          return "mdi-progress-alert";
        case CONFIG_STATES_ENUM.CONFLICT:
          return "mdi-alert-decagram";
        default:
          return "mdi-alert";
      }
    },
    syncIconColor() {
      switch (this.config_state) {
        case CONFIG_STATES_ENUM.SYNCED:
          return "green";
        case CONFIG_STATES_ENUM.WAITING_TO_BE_SEND:
          return "warning";
        default:
          return "red";
      }
    },
    syncStateText() {
      switch (this.config_state) {
        case CONFIG_STATES_ENUM.SYNCED:
          return "Synchronized with the device.";
        case CONFIG_STATES_ENUM.WAITING_TO_BE_SEND:
          return "Not yet synchronized. Waiting to be sent to the device.";
        case CONFIG_STATES_ENUM.CONFLICT:
          return "Conflict! The device and a PUCS user have changed the configuration at the same time. These changes are not identical.";
        default:
          return "Unknown synchronization state.";
      }
    },
    isConflict() {
      return this.config_state === "conflict";
    }
  },
  methods: {
    formatDate(date) {
      return formatDate(date, true);
    }
  }
};
</script>
