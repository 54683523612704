var render = function render(){var _vm=this,_c=_vm._self._c;return _c('span',[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.selected_id_key),expression:"selected_id_key"}],ref:"select",class:{
      'accent-select': _vm.isHeaderDropdown && _vm.selected_id_key > 0,
      'bigger-padding': (_vm.isHeaderDropdown && !_vm.isGroup)
    },attrs:{"disabled":_vm.disabled,"required":_vm.required},on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.selected_id_key=$event.target.multiple ? $$selectedVal : $$selectedVal[0]},function($event){return _vm.emitSelected(this)}]}},[_c('option',{attrs:{"value":"0"}},[_vm._v(_vm._s(_vm.zeroOption))]),_vm._l((_vm.objects_list),function(object,key){return _c('option',{directives:[{name:"show",rawName:"v-show",value:(
        _vm.doesBelongToTypeIfFilter(object) &&
          _vm.doesBelongToTeamIfFilter(object) &&
          !_vm.isDisabled(object)
      ),expression:"\n        doesBelongToTypeIfFilter(object) &&\n          doesBelongToTeamIfFilter(object) &&\n          !isDisabled(object)\n      "}],key:key + 1,domProps:{"value":key + 1}},[_vm._v(" "+_vm._s(object.name)+" "),(_vm.isFirmware)?_c('span',[_vm._v(" ("+_vm._s(object.version)+")")]):_vm._e(),(_vm.isDocument)?_c('span',[_vm._v("["+_vm._s(object.doc_type)+"]")]):_vm._e()])})],2),(_vm.parent_is_device_edit && _vm.disabled && _vm.isFirmware)?_c('input-info',{attrs:{"msg":"An individual firmware cannot be assigned if the device belongs to a group."}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }