<template>
  <div>
    <div v-for="(item, i) in docs_list" :key="i" class="mb-4 mt-2">
      <v-icon small class="mr-1">
        {{ getTypeIcon(item.doc_type) }}
      </v-icon>

      <router-link
        :to="getViewUrlFor(item.id, getBasicDocumentCategory)"
        target="_blank"
        >{{ item.name }}</router-link
      >:

      <template>
        <div class="ml-15 mb-2 mt-1">
          <span v-if="isDocumentFile(item.doc_type)">
            <template v-if="null != item.content">
              download the file
              <a
                href="#"
                @click="downloadDocument($event, item.id)"
                :file_attached="true"
              >
                {{ item.content }}
              </a>
            </template>
          </span>

          <span v-if="isDocumentLink(item.doc_type)">
            <a target="_blank" :href="item.content"> {{ item.content }}</a>
          </span>

          <span v-if="isDocumentImage(item.doc_type)">
            <IconImage :image_src="item.content"></IconImage>
          </span>

          <span v-if="isDocumentText(item.doc_type)">
            <textarea
              :rows="null == item.content || item.content.length == 0 ? 1 : 5"
              v-model="item.content"
              :disabled="true"
            />
          </span>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { getTypeIconStr } from "../../../utils/helpers";
import { getViewUrl, OBJECT_CATEGORIES } from "../../../utils/RoutesUtils";
import { BASIC_DOCUMENT_TYPES_ENUM, downloadDocumentFile } from "../../../utils/ServerUtils";

export default {
  props: {
    docs_list: Array
  },
  
  
  computed: {
    getBasicDocumentCategory() {
      return OBJECT_CATEGORIES.BASIC_DOCUMENT;
    }
  },

  
  methods: {
    getViewUrlFor(object_id, object_category) {
      return getViewUrl(object_id, object_category);
    },

    getTypeIcon(type) {
      return getTypeIconStr(type);
    },

    isDocumentImage(type) {
      return BASIC_DOCUMENT_TYPES_ENUM.IMAGE == type;
    },

    isDocumentFile(type) {
      return BASIC_DOCUMENT_TYPES_ENUM.FILE == type;
    },

    isDocumentLink(type) {
      return BASIC_DOCUMENT_TYPES_ENUM.LINK == type;
    },

    isDocumentText(type) {
      return BASIC_DOCUMENT_TYPES_ENUM.TEXT == type;
    },

    downloadDocument: function(e, doc_id) {
      e.preventDefault();
      downloadDocumentFile(doc_id);
    }
  }
};
</script>
