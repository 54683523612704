<template>
  <v-btn
    elevation="0"
    dark
    :color="is_accent ? 'primary' : 'accent_gray'"
    class="text-none text-body-2"
    :class="{ 'custom-disabled': disabled }"
    :disabled="disabled"
    v-bind="$attrs"
    v-on="$listeners"
  >
    {{ text }}
  </v-btn>
</template>

<script>
export default {
  name: "BaseButton",

  props: {
    text: String,
    is_accent: Boolean,
    disabled: Boolean,
  },
};
</script>

