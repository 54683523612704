<template>
  <div class="subgroup">
    <div class="flex-row">
      <div class="column thin">
        Firmwares ({{ null != firmwares ? firmwares.length : 0 }}):
      </div>
      <div class="column wide">
        <ul class="sublist">
          <li
            v-for="(firmware, key_firmware) in firmwares"
            :key="'f' + key_firmware"
          >
            <router-link :to="getViewTargetUrl(firmware.id)">
              {{ firmware.name }}
            </router-link>
            ({{ firmware.version }})
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { getFirmwareCategory, getViewUrl } from "../../../utils/RoutesUtils";

export default {
  name: "FirmwaresOfTypeTable",

  props: {
    firmwares: {
      type: Array,
      default: () => []
    }
  },

  computed: {
    list_to_show: function() {
      var arr = [];

      var list = this.firmwares;
      if (null != list) {
        // Add target devices

        for (let i = 0; i < list.length; i++) {
          arr.push({
            name: list[i].name,
            id: list[i].id
          });
        }
      }
      return arr;
    }
  },

  methods: {
    getViewTargetUrl: function(object_id) {
      return getViewUrl(object_id, getFirmwareCategory());
    }
  }
};
</script>

<style scoped>
table {
  margin: 0;
}
.sub-container {
  margin: 0;
  margin-bottom: 1em;
}
</style>
