<template>
  <v-fade-transition mode="out-in">
    <qr-code-view v-if="isQrCodeUrl" />
    <router-view v-else-if="null != user" />
    <login-page v-else />
  </v-fade-transition>
</template>

<script>
// Styles
import "@/styles/overrides.sass";
import { getUser } from "./utils/ServerUtils.js";
import { mapActions, mapGetters, mapMutations } from "vuex";
import LoginPage from "./views/LoginPage.vue";
import QrCodeView from "./views/QrCodeView.vue";
import { getObjectCategoryFromRoutePath } from "./utils/RoutesUtils.js";

export default {
  name: "App",
  components: { LoginPage, QrCodeView },
  metaInfo: {
    title: "PUCS",
    titleTemplate: "%s | Strataggem",
    htmlAttrs: { lang: "en" },
    meta: [
      { charset: "utf-8" },
      { name: "viewport", content: "width=device-width, initial-scale=1" },
    ],
  },

  computed: {
    ...mapGetters(["user"]),

    isQrCodeUrl() {
      return "/qr" == this.$route.path;
    },
  },

  watch: {
    $route() {
      this.updateObjectCategoryInStore();
    },
  },

  async created() {
    var userProfile = await getUser();
    this.setCurrentUserProfile(userProfile);

    this.updateObjectCategoryInStore();
  },

  methods: {
    ...mapMutations(["setCurrentUserProfile"]),
    ...mapActions(["updateObjectCategory"]),

    updateObjectCategoryInStore() {
      const object_category = getObjectCategoryFromRoutePath(this.$route.path);
      this.updateObjectCategory(object_category);
    },
  },
};
</script>

<style>
@import "styles/tus-common.css";
</style>