// Vue
import Vue from "vue";
import Vuex from "vuex";
import pathify from "@/plugins/vuex-pathify";

// Pathify
import { make } from "vuex-pathify";

// Data
const state = {
  drawer: null,
  drawerImage: true,
  mini: false,
  user: null,
  object_category: -1,
  items: [
    // {
    //   title: "Dashboard",
    //   icon: "mdi-view-dashboard",
    //   to: "/"
    // },
    {
      title: "Devices",
      to: "/devices",
      icon: "mdi-devices"
    },
    {
      title: "Firmwares",
      to: "/firmwares",
      icon: "mdi-file-document-multiple-outline"
    },
    {
      title: "Types",
      to: "/types",
      icon: "mdi-shape"
    },
    {
      title: "Groups",
      to: "/groups",
      icon: "mdi-group"
    },
    {
      title: "Teams",
      to: "/teams",
      icon: "mdi-account-group"
    },
    {
      title: "Documents",
      to: "/documents",
      icon: "mdi-book-open-variant"
    }
  ],
  filter_selected_team_id: null,
  filter_selected_type_id: null,
  filter_selected_group_id: null,

  lastUpdateTime: null
};

var mutations = make.mutations(state);
mutations.setCurrentUserProfile = (state, userProfile) => {
  state.user = userProfile;
};

mutations.setObjectCategory = (state, object_category) => {
  state.object_category = object_category;
};

mutations.setSelectedTeamId = (state, selected_team_id) => {
  state.filter_selected_team_id = selected_team_id;
};

mutations.setSelectedTypeId = (state, selected_type_id) => {
  state.filter_selected_type_id = selected_type_id;
};

mutations.setSelectedGroupId = (state, selected_group_id) => {
  state.filter_selected_group_id = selected_group_id;
};

mutations.setLastUpdateTime = (state, lastUpdateTime) => {
  state.lastUpdateTime = lastUpdateTime;
};

const actions = {
  updateObjectCategory({ commit }, object_category) {
    commit("setObjectCategory", object_category);
  },

  updateSelectedTeamId({ commit }, selected_team_id) {
    commit("setSelectedTeamId", selected_team_id);
  },

  updateSelectedTypeId({ commit }, selected_type_id) {
    commit("setSelectedTypeId", selected_type_id);
  },

  updateSelectedGroupId({ commit }, selected_group_id) {
    commit("setSelectedGroupId", selected_group_id);
  },

  updateUserPreferences({ commit }, userPreferences) {
    if (null != state.user) {
      var updated_user = state.user;
      updated_user.preferences = userPreferences;
      commit("setCurrentUserProfile", updated_user);
    }
  },

  updateLastUpdateTime({ commit }) {
    commit("setLastUpdateTime", new Date());
  }
};

const getters = {
  user: state => {
    return state.user;
  },
  object_category: state => {
    return state.object_category;
  },
  filter_selected_team_id: state => {
    return state.filter_selected_team_id;
  },
  filter_selected_type_id: state => {
    return state.filter_selected_type_id;
  },
  filter_selected_group_id: state => {
    return state.filter_selected_group_id;
  }
};

Vue.use(Vuex);

const store = new Vuex.Store({
  plugins: [pathify.plugin],
  namespaced: true,
  state,
  mutations,
  actions,
  getters
});

export default store;

export const ROOT_DISPATCH = Object.freeze({ root: true });
