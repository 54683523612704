<template>
  <span>
    <select
      ref="select"
      @change="emitSelected(this)"
      :disabled="disabled"
      :required="required"
      v-model="selected_id_key"
      :class="{
        'accent-select': isHeaderDropdown && selected_id_key > 0,
        'bigger-padding': (isHeaderDropdown && !isGroup)
      }"
    >
      <option value="0">{{ zeroOption }}</option>
      <option
        v-for="(object, key) in objects_list"
        :key="key + 1"
        :value="key + 1"
        v-show="
          doesBelongToTypeIfFilter(object) &&
            doesBelongToTeamIfFilter(object) &&
            !isDisabled(object)
        "
      >
        {{ object.name }}
        <span v-if="isFirmware"> ({{ object.version }})</span>
        <span v-if="isDocument">[{{ object.doc_type }}]</span>
      </option>
    </select>
    <input-info
      v-if="parent_is_device_edit && disabled && isFirmware"
      msg="An individual firmware cannot be assigned if the device belongs to a group."
    />
  </span>
</template>

<script>
import { getRoles } from "../../../utils/RolesUtils";
import {
  getCategoryLabel,
  getDropDownListZeroOptionLabel,
  isDevice,
  isFirmware,
  isGroup,
  isRole,
  isTeam,
  isType,
  isDocument
} from "../../../utils/RoutesUtils";
import {
  getObjectsList,
  getTeamsListWhereUserIsAdminCall
} from "../../../utils/ServerUtils.js";
import InputInfo from "../../../components/app/InputInfo.vue";

export default {
  components: { InputInfo },
  name: "DropdownList",

  props: {
    isListView: {
      type: Boolean,
      default: false
    },
    disabled: Boolean,
    preselected_id: String,
    object_category: Number,
    type_id_filter: String,
    team_id_filter: String,
    required: {
      default: false,
      type: Boolean
    },
    parent_is_device_edit: {
      default: false,
      type: Boolean
    },
    isHeaderDropdown: {
      default: false,
      type: Boolean
    }
  },

  watch: {
    preselected_id: function(new_id) {
      if (this.objects_list.length > 0) {
        this.setObject(new_id);
      }
    },
    type_id_filter: function(new_type_id) {
      if (
        null != this.objects_list &&
        this.selected_id_key > 0 &&
        new_type_id != this.objects_list[this.selected_id_key - 1].type_id
      ) {
        this.selected_id_key = 0;
        this.emitSelected();
      }
    },
    team_id_filter: function(new_team_id) {
      if (
        null != this.objects_list &&
        this.selected_id_key > 0 &&
        new_team_id != this.objects_list[this.selected_id_key - 1].team_id
      ) {
        this.selected_id_key = 0;
        this.emitSelected();
      }
    }
  },

  data() {
    return {
      objects_list: [],
      selected_id_key: 0,
      preselected_parent: null,
      // TODO: instead of keeping both objects_list and objects_list_original,
      // filter the objects_list_original instead by using computed function
      // (this is needed to filter out user that do not have manager rights)
      objects_list_original: []
    };
  },

  computed: {
    zeroOption: function() {
      if (this.isHeaderDropdown && this.isTeam) {
        return "All teams";
      } else if (this.isHeaderDropdown && this.isType) {
        return "All types";
      } else if (this.isHeaderDropdown && this.isGroup) {
        if (this.type_id_filter == null) {
          return "Select a type to allow filtering by groups"
        } else {
          return "All groups";  
        }        
      } else {
        return getDropDownListZeroOptionLabel(this.object_category);
      }
    },

    label: function() {
      return getCategoryLabel(this.object_category) + ":";
    },

    isFirmware: function() {
      return isFirmware(this.object_category);
    },

    isDocument() {
      return isDocument(this.object_category);
    },

    isRole: function() {
      return isRole(this.object_category);
    },

    isTeam: function() {
      return isTeam(this.object_category);
    },

    isType: function() {
      return isType(this.object_category);
    },
    
    isGroup: function() {
      return isGroup(this.object_category);
    },
  },

  created: function() {
    this.updateObjects();
  },

  methods: {
    clearSelection() {
      this.selected_id_key = 0;
      this.emitSelected();
    },

    doesBelongToTypeIfFilter: function(object) {
      if (
        isGroup(this.object_category) ||
        isDevice(this.object_category) ||
        this.isFirmware
      ) {
        return this.type_id_filter == object.type_id;
      } else {
        return true;
      }
    },

    doesBelongToTeamIfFilter: function (object) {
      if (this.isListView) {
        if (
          (this.isType || this.isDocument) &&
          null != this.team_id_filter &&
          "" != this.team_id_filter
        ) {
          return this.team_id_filter == object.team_id;
        }
      } else if ((this.isType || this.isDocument) && "" != this.team_id_filter) {
        return this.team_id_filter == object.team_id;
      }

      return true;
    },

    isDisabled(object) {
      if (this.isFirmware && true == object.is_disabled) {
        return true;
      }
      return false;
    },

    updateObjects: async function() {
      var objects_list;
      if (this.isRole) {
        objects_list = getRoles();
      } else {
        // If a user edit an object, he should not be able to see the teams where he is not admin
        // This does not concern the dropdown in the list of objects view
        if (this.isTeam && this.disabled == false && !this.isHeaderDropdown) {
          objects_list = await getTeamsListWhereUserIsAdminCall();
        } else {
          objects_list = await getObjectsList(this.object_category, true);
        }
      }
      this.objects_list_original = objects_list;
      if (null != objects_list) this.fillDropDown(objects_list);

      this.emitLoaded(objects_list);
    },

    fillDropDown: function(json_list) {
      if (null != json_list) {
        this.objects_list = [];
        for (var i = 0; i < json_list.length; i++) {
          const current_item = json_list[i];
          this.objects_list.push(current_item);
        }

        this.setObject(this.preselected_id);

        if (
          this.isTeam &&
          null == this.preselected_id &&
          this.objects_list.length == 1
        ) {
          this.selected_id_key = 1;
          this.emitSelected();
        }
      }
    },

    setObject: function(new_id) {
      if (null == new_id) {
        this.selected_id_key = 0;
        this.emitSelected();
        return;
      }
      for (var i = 0; i < this.objects_list.length; i++) {
        if (new_id == this.objects_list[i].id) {
          this.selected_id_key = i + 1; // +1 because there is a 0 option (Select a type...) in the Select element
          this.emitSelected();
          return;
        }
      }
    },

    emitSelected: function() {
      var object_selected = null;
      if (this.selected_id_key > 0) {
        // -1 because there is a 0 option (Select a type...) in the Select element
        object_selected = this.objects_list[this.selected_id_key - 1];
      }
      this.$emit("objectIsSelected", object_selected);

      this.validateInput();
    },

    emitLoaded(objects_list) {
      this.$emit("objectsListLoaded", objects_list);
    },

    validateInput: function() {
      if (this.required) {
        const target_el = this.$refs["select"];
        if (target_el != null) {
          if (this.selected_id_key == 0 && this.isType) {
            target_el.setCustomValidity("Type should be selected.");
          } else if (this.selected_id_key == 0 && this.isTeam) {
            target_el.setCustomValidity("Team should be selected.");
          } else {
            // valid
            target_el.setCustomValidity("");
          }
        }
      }
    }
  }
};
</script>

<style scoped>
select {
  margin-top: 0.5em;
}

select.bigger-padding {
  padding: 1em;
}
.accent-select {
  color: white;
  background-color: #2095b6;
}
</style>
