<template>
  <span class="input-info">
    <v-icon color="info_gray" small> mdi-information-outline </v-icon>
    {{ msg }}
  </span>
</template>

<script>
export default {
  name: "InputInfo",
  props: {
    msg: String,
  },
};
</script>