<template>
  <div>
    <ul class="sublist" v-if="null != already_selected">
      <li v-for="(object, i) in already_selected" :key="i">
        <div class="flex-row">
          <div>
            <router-link :to="getObjectViewUrl(object.id)">
              {{ object.name }}
            </router-link>
            <v-progress-circular
              :size="20"
              class="ml-5"
              v-if="list_is_beign_updating"
              indeterminate
            />
          </div>
          <v-spacer />
          <div>
            <base-button
              :disabled="list_is_beign_updating"
              text="Withdraw the documentation"
              @click="deleteDocFromObject(object)"
            />
          </div>
        </div>
      </li>
    </ul>
    <br v-else />

    <!-- Dialog -->
    <div>
      <v-dialog v-model="dialog" width="500" :key="dialog">
        <template v-slot:activator="{ on, attrs }">
          <base-button
            :disabled="list_is_beign_updating"
            :is_accent="true"
            :text="
              list_is_beign_updating
                ? 'Please wait, the list of items is being updated'
                : 'Assign documentation to ' + objectHumanNamePlural
            "
            @click="addObjectsClicked()"
            v-bind="attrs"
            v-on="on"
          />

          <v-btn class="ml-4" @click="reloadObjects()" icon small>
            <v-icon small>mdi-reload</v-icon>
          </v-btn>
        </template>

        <v-card>
          <v-card-title>
            {{
              "Select the " +
              objectHumanNamePlural +
              " to which the documentation should be assigned "
            }}</v-card-title
          >

          <div>
            <p class="text-center">
              Available {{ objectHumanNamePlural }} from the current team:
            </p>

            <table ref="objects">
              <thead class="light-grey">
                <tr>
                  <th>
                    {{ selectAll ? "Deselect all:" : "Select all:" }}
                    <input
                      type="checkbox"
                      v-model="selectAll"
                      @click="select"
                    />
                  </th>
                  <th>Name</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(object, key) in objects_to_select_from_list"
                  :key="key"
                  class="list-row"
                  :class="{ selected: isSelected(object.id) }"
                >
                  <td class="text-center">
                    <input
                      type="checkbox"
                      :value="object.id"
                      v-model="selected"
                    />
                  </td>
                  <td>{{ object.name }}</td>
                </tr>
              </tbody>
            </table>
          </div>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <base-button
              :is_accent="true"
              :text="
                'Add selected ' +
                objectHumanNamePlural +
                ' (' +
                selected.length +
                ')'
              "
              @click="addDocToSelectedObjects()"
            />
            <base-button text="Close" @click="dialog = false" />
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import BaseButton from "../../../components/app/BaseButton.vue";
import { getObjectHumanName, getViewUrl } from "../../../utils/RoutesUtils";
import {
  addDocToObjects,
  deleteDocFromObject,
  getObjectsList,
} from "../../../utils/ServerUtils.js";

function getObjectsOfTeamIfUserIsAdmin(
  full_objects_list,
  team_id,
  teams_where_user_is_admin
) {
  var editable_objects_list = [];
  for (const object of full_objects_list) {
    if (object.team_id == team_id) {
      if (isUserAdminInTeam(team_id, teams_where_user_is_admin)) {
        editable_objects_list.push(object);
      }
    }
  }

  return editable_objects_list;
}

function isUserAdminInTeam(team_id, teams_where_user_is_admin) {
  for (const admin_team of teams_where_user_is_admin) {
    if (admin_team.id == team_id) return true;
  }
  return false;
}

function canUserEdit(object, teams_where_user_is_admin) {
  for (const admin_team of teams_where_user_is_admin) {
    if (admin_team.id == object.team_id) return true;
  }
  return false;
}

function isObjectInList(object, already_selected) {
  for (const object_selected of already_selected) {
    if (object.id == object_selected.id) return true;
  }
  return false;
}

function deleteAlreadySelected(objects_list, already_selected) {
  var objects_cleaned = [];
  for (const object of objects_list) {
    if (!isObjectInList(object, already_selected)) {
      objects_cleaned.push(object);
    }
  }
  return objects_cleaned;
}

export default {
  components: { BaseButton },
  props: {
    team_id: {
      type: String,
      default: null,
    },
    teams_where_user_is_admin: {
      type: Array,
      default: [],
    },
    doc_id: {
      type: String,
      default: null,
    },
    already_selected: {
      type: Array,
      default: [],
    },
    disabled: Boolean,
    object_category: {
      type: Number,
      default: null,
    },
  },

  data() {
    return {
      full_objects_list: [],
      objects_to_select_from_list: [],

      dialog: false,

      selected: [],
      selectAll: false,

      list_is_beign_updating: false,
    };
  },

  mounted() {
    this.updateObjects();
  },

  computed: {
    objectHumanName() {
      return getObjectHumanName(this.object_category);
    },

    objectHumanNamePlural() {
      return this.objectHumanName + "s";
    },
  },

  watch: {
    object_category: function () {
      this.updateObjects();
    },

    already_selected() {
      this.filterObjectsListAndDeselect();
      this.list_is_beign_updating = false;
    },
  },

  methods: {
    async reloadObjects() {
      await this.updateObjects()
      this.$emit("list_is_changed");       
    },
    
    updateObjects: async function () {
      this.list_is_beign_updating = true;

      this.full_objects_list = await getObjectsList(this.object_category, true);

      this.filterObjectsListAndDeselect();
      this.list_is_beign_updating = false;
    },

    filterObjectsListAndDeselect() {
      const objects_that_user_can_edit = getObjectsOfTeamIfUserIsAdmin(
        this.full_objects_list,
        this.team_id,
        this.teams_where_user_is_admin
      );

      this.objects_to_select_from_list = deleteAlreadySelected(
        objects_that_user_can_edit,
        this.already_selected
      );

      this.selected = [];
      this.selectAll = false;
    },

    getObjectViewUrl: function (object_id) {
      return getViewUrl(object_id, this.object_category);
    },

    addDocToSelectedObjects: async function () {
      this.dialog = false;

      this.list_is_beign_updating = true;
      await addDocToObjects(
        this.selected,
        this.doc_id,
        this.object_category,
        this.team_id
      );

      this.$emit("list_is_changed");
    },

    deleteDocFromObject: async function (object) {
      this.list_is_beign_updating = true;
      await deleteDocFromObject(
        object.id,
        this.doc_id,
        this.object_category,
        this.team_id
      );

      this.$emit("list_is_changed");
    },

    isSelected(id) {
      return this.selected.includes(id);
    },

    select() {
      // From https://vuejsexamples.com/vuejs-tables-and-select-all-checkbox/
      this.selected = [];
      if (!this.selectAll) {
        for (let i = 0; i < this.objects_to_select_from_list.length; i++) {
          this.selected.push(this.objects_to_select_from_list[i].id);
        }
      }
    },

    async addObjectsClicked() {
      this.updateObjects();
    },
  },
};
</script>

<style scoped>
li .flex-row {
  margin-top: 0;
}
.mt-04 {
  margin-top: 0.4em;
}
</style>
