<template>
  <v-app>
    <v-main>
      <v-container>
        <v-row align="center" justify="center" class="mt-15 px-5">
          <v-col cols="12" md="6">
            <v-img
              :src="require('@/assets/images/strataggem_logo.svg')"
              max-width="15em"
              class="mb-15"
            />
            <p>
              <span class="text-h3 font-weight-bold">Welcome to PUCS</span>
              <br />
              <span class="text-h4  font-weight-bold"
                >Provisioning Update Configuration Server</span
              >
            </p>

            <p class="mt-7">Please login to use this service.</p>
            <base-button href="/login" :is_accent="true" text="Sign in" />
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import BaseButton from "../components/app/BaseButton.vue";

export default {
  components: { BaseButton }
};
</script>
