export const ROLES = {
  NONE: 0,
  USER: 1,
  TEAMLEADER: 2,
  ADMIN: 3
};

// see https://stackoverflow.com/a/50437822
export const ROLES_NAMES = {
  [ROLES.NONE]: 'not_authorized',
  [ROLES.USER]: 'user',
  [ROLES.ADMIN]: 'admin',
}

export function getRoles() {
  var roles = [];
  const roles_ids = Object.values(ROLES);
  for (let i = 0; i < roles_ids.length; i++) {
    roles.push({
      'id': roles_ids[i].toString(),
      'name': ROLES_NAMES[roles_ids[i]]
    })
  }
  return roles;
}

export function getNameOfUserRoleInTeam(team, current_user_id) {
  const role_id = getUserRoleInTeam(team, current_user_id)
  return getRoleName(role_id);
}

export function doesUserHaveManagerRights(user) {
  if (
    undefined !== user &&
    null != user &&
    "role_id" in user &&
    (
      user.role_id == ROLES.ADMIN.toString()
      ||
      user.role_id == ROLES.TEAMLEADER.toString()
    )
  ) return true;

  // else
  return false;
}

// ------------------
// Private
// ------------------

function getUserRoleInTeam(team, current_user_id) {
  if (team.rolesInTeams && team.rolesInTeams.length > 0) {        
    for (let i = 0; i < team.rolesInTeams.length; i++) { 
      const role = team.rolesInTeams[i];
      if (current_user_id == role.user_id)
          return role.role_id;
      }
  }
  return ROLES.NONE        
}

function getRoleName(role_id) {
if (role_id in ROLES_NAMES) {
  return ROLES_NAMES[role_id];
} else {
  console.log("The role " + role_id + " is unknown!")
  return "Role " + role_id; // undefined in the front-end
}
}