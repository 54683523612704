<template>
  <v-btn
    elevation="0"
    class="text-none font-normal no-border text-decoration-underline"
    v-on="$listeners"
    v-bind="$attrs"
    text
  >
    {{ text }}
  </v-btn>
</template>

<script>
export default {
  name: "BaseTextButton",

  props: {
    text: String,
    is_accent: Boolean,
  },
};
</script>

