<template>
  <div class="sub-container grey--text">
    <div class="mb-5">
      <label class="pb-0">Last seen:</label>
      <input
        type="text"
        :value="formatDate(last_seen, true)"
        :disabled="true"
      />
    </div>

    <div>
      <label class="pb-0">Firmware update history:</label>
      <table>
        <tbody>
          <tr v-for="(object, key) in list_to_show" :key="key">
            <td>
              {{ formatDate(object.date, false) }}
            </td>
            <td>
              <router-link :to="getViewTargetUrl(object.firmware_id)">{{
                object.firmware_id
              }}</router-link>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { getFirmwareCategory, getViewUrl } from "../../../utils/RoutesUtils";
import { formatDate } from "../../../utils/DateUtils";

export default {
  name: "HistoryTable",

  props: {
    last_seen: {
      type: String,
      default: null
    },
    histories: {
      type: Array,
      default: null
    }
  },

  computed: {
    list_to_show: function() {
      return null != this.histories ? this.histories : [];
    }
  },

  methods: {
    formatDate: function(
      object_date,
      show_time_flag = true,
      simple_format = false
    ) {
      return formatDate(object_date, show_time_flag, simple_format);
    },

    getViewTargetUrl: function(firmware_id) {
      return getViewUrl(firmware_id, getFirmwareCategory());
    }
  }
};
</script>

<style scoped>
table {
  margin: 0;
}

.sub-container {
  margin: 0;
  margin-bottom: 1em;
}
</style>
