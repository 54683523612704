<template>
  <div class="subgroup">
    <div class="flex-row">
      <div class="column thin">
        Devices ({{ null != devices ? devices.length : 0 }}):
      </div>
      <div class="column wide">
        <ul class="sublist">
          <li v-for="(device, i) in devices" :key="'d' + i">
            <router-link :to="getDeviceViewUrl(device.id)">{{
              device.name
            }}</router-link>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { getDeviceCategory, getViewUrl } from "../../../utils/RoutesUtils";
export default {
  props: {
    devices: {
      type: Array,
      required: true,
      default: null
    }
  },

  methods: {
    getDeviceViewUrl: function(object_id) {
      return getViewUrl(object_id, getDeviceCategory());
    }
  }
};
</script>

<style></style>
