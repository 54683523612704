import { BASIC_DOCUMENT_TYPES_ENUM } from "./ServerUtils";

export function leadingSlash(str) {
  return str.startsWith("/") ? str : "/" + str;
}

export function trailingSlash(str) {
  return str.endsWith("/") ? str : str + "/";
}

export const wait = timeout => {
  return new Promise(resolve => setTimeout(resolve, timeout));
};

export function exportToCsv(filename, rows) {
  var processRow = function (row) {
    var finalVal = "";
    for (var j = 0; j < row.length; j++) {
      var transformedCellValue = escapeForCsv(row[j]);
      var innerValue = transformedCellValue;
      if (row[j] instanceof Date) {
        innerValue = row[j].toLocaleString();
      }
      var result = innerValue.replace(/"/g, '""');
      if (result.search(/("|,|\n)/g) >= 0) result = '"' + result + '"';
      if (j > 0) finalVal += ",";
      finalVal += result;
    }
    return finalVal + "\n";
  };

  var csvFile = "";
  for (var i = 0; i < rows.length; i++) {
    csvFile += processRow(rows[i]);
  }

  var blob = new Blob([csvFile], { type: "text/csv;charset=utf-8;" });
  if (navigator.msSaveBlob) {
    // IE 10+
    navigator.msSaveBlob(blob, filename);
  } else {
    var link = document.createElement("a");
    if (link.download !== undefined) {
      // feature detection
      // Browsers that support HTML5 download attribute
      var url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", filename);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
}

export function downloadObjectAsJson(exportObj, exportName) {
  // From https://stackoverflow.com/a/30800715
  console.log(exportObj);
  var dataStr =
    "data:text/json;charset=utf-8," +
    encodeURIComponent(JSON.stringify(exportObj, undefined, 4));
  var downloadAnchorNode = document.createElement("a");
  downloadAnchorNode.setAttribute("href", dataStr);
  downloadAnchorNode.setAttribute("download", exportName + ".json");
  document.body.appendChild(downloadAnchorNode); // required for firefox
  downloadAnchorNode.click();
  downloadAnchorNode.remove();
}

export function getCustomFieldType(field) {
  if (null == field.type) field.type = 0;

  switch (field.type) {
    case "1":
      return "boolean";
    case "2":
      return "integer";
    default:
      return "string";
  }
}

export function isValidJson(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

export function getTypeIconStr(type) {
  switch (type) {
    case BASIC_DOCUMENT_TYPES_ENUM.FILE:
      return "mdi-file-outline";
    case BASIC_DOCUMENT_TYPES_ENUM.IMAGE:
      return "mdi-image-outline";
    case BASIC_DOCUMENT_TYPES_ENUM.LINK:
      return "mdi-link";
    case BASIC_DOCUMENT_TYPES_ENUM.TEXT:
      return "mdi-clipboard-text-outline";
    default:
      return null;
  }
}

// --------------------
// Private functions
// --------------------

function escapeForCsv(str) {
  if (str === null || str === undefined) {
    return "";
  }
  return JSON.stringify(str.toString());
}
