<template>
  <v-app>
    <v-main>
      <v-container>
        <v-row class="mt-5 mb-5">
          <v-col cols="12" sm="6">
            <p><router-link to="/">Strataggem PUCS</router-link></p>

            <div class="text-h3 font-weight-bold mb-2">Device</div>

            <!-- CASE 0: Device not found (wrong address) -->
            <template v-if="!isValidQrCodeAddress">
              <div class="my-5 pa-3 border-error">
                The address in the QR code does not correspond to a valid device
                or an error has occurred. Please ensure that you have followed
                the correct link. If you have any questions, please
                <a target="_blanc" href="https://users.strataggem.com/contact">
                  contact us</a
                >.
              </div>
            </template>

            <template v-else>
              <div v-if="null != label_image" class="for-svg-resize">
                <span v-html="label_image"></span>
              </div>

              <template v-if="!isDeviceWaitingToBeClaimed">
                <!-- CASE B: Device is not marked as waiting to be claimed and the user is not logged in -->
                <template v-if="null == user" class="ml-5">
                  <p>Please login to see information related to that device.</p>

                  <base-button
                    :href="'/qr_code_page_login/?id=' + qr_code_id"
                    :is_accent="true"
                    text="Sign in"
                  />
                </template>

                <template v-else>
                  <!-- CASE A: Device is not marked as waiting to be claimed and the current user has rights to see it -->
                  <qr-code-device-info
                    v-if="user_has_rights"
                    :device="device_full"
                  />

                  <!-- CASE C: Device is not marked as waiting to be claimed and the user has no right to see it -->
                  <div v-else>
                    <p class="mt-5">
                      The device has been assigned to a team of which you are
                      not a member.
                      <br />
                      You can ask a team administrator to add you.
                    </p>
                    <p>
                      If you think there is an error or if you have a question,
                      please
                      <a
                        target="_blanc"
                        href="https://users.strataggem.com/contact"
                      >
                        contact us</a
                      >.
                    </p>
                  </div>
                </template>
              </template>

              <!-- CASE D: Device is marked as waiting to be claimed -->
              <template v-else>
                <qr-code-device-public-info
                  :device="device_public_info"
                  class="mb-5"
                />

                <base-button
                  v-if="!claimed_open"
                  @click="claimed_open = true"
                  :is_accent="true"
                  text="Claim the device"
                />

                <v-card v-else class="py-5">
                  <div class="px-5" v-if="null == user">
                    <p>Please login to claim the device.</p>

                    <base-button
                      :href="'/qr_code_page_login/?id=' + qr_code_id"
                      :is_accent="true"
                      text="Sign in"
                    />
                  </div>

                  <template v-else>
                    <v-form ref="form">
                      <v-text-field
                        ref="claim_code_input"
                        :required="true"
                        v-model="claim_code"
                        class="keep-veutify"
                        label="Claim code"
                      />

                      <v-switch
                        class="ma-0"
                        v-model="advanced_flag"
                        label="Advanced settings for the device claim"
                        hide-details
                      />

                      <div
                        v-show="advanced_flag || team_id == null"
                        class="claim-container my-5"
                      >
                        <p class="font-italic">
                          Choose to which team the device should be assigned.
                        </p>

                        <label>Team:</label>

                        <dropdown-list
                          ref="teams_dropdown"
                          :object_category="getTeamCategory"
                          :required="true"
                          @objectIsSelected="teamDropdownSelected($event)"
                          @objectsListLoaded="teams_list_loaded = true"
                        />
                      </div>

                      <base-button
                        v-if="advanced_flag"
                        @click="getDeviceByQrAndClaimCodes"
                        :is_accent="true"
                        text="Verify the claim code and continue"
                      />

                      <base-button
                        v-else
                        @click="automaticDeviceClaim"
                        :is_accent="true"
                        :text="'Claim the device for the team ' + team_name"
                      />

                      <template v-if="claim_code_is_wrong">
                        <p class="mt-10 red--text">
                          Wrong code! Please, try again.
                        </p>
                        <p>
                          If you think there is an error or if you have a
                          question, please
                          <a
                            target="_blanc"
                            href="https://users.strataggem.com/contact"
                          >
                            contact us</a
                          >.
                        </p>
                      </template>

                      <div
                        v-else-if="
                          claim_code_is_ok &&
                          null != device_full &&
                          advanced_flag
                        "
                      >
                        <p class="ok--text">Your claim code is correct.</p>

                        <div class="claim-container">
                          <p class="font-weight-bold">Device information</p>
                          <p>
                            <label>Team:</label> {{ device_full.team.name }}
                            <br />
                            <label>Name:</label> {{ device_full.name }}

                            <br />
                            <label>Type:</label> {{ device_full.type.name }}
                            <br />
                            <label>UID:</label> {{ device_full.uid_for_type }}
                            <br />
                            <template
                              v-if="null != device_full.target_firmware"
                            >
                              <label>Target firmware:</label>
                              {{ device_full.target_firmware.name }} ({{
                                device_full.target_firmware.version
                              }})
                            </template>

                            <br />
                            <router-link
                              target="_blank"
                              :to="'/device_view?id=' + device_full.id"
                              >Device page in PUCS</router-link
                            >

                            <span class="grey--text">
                              (you may not have access)</span
                            >
                          </p>
                        </div>

                        <div class="claim-container mt-5">
                          <div>
                            <p class="font-italic" v-if="null == team_id">
                              Please select the team first.
                            </p>

                            <div
                              :class="{
                                'grey--text':
                                  null == team_id || device_stays_in_same_team,
                              }"
                            >
                              <p
                                v-if="
                                  !device_stays_in_same_team &&
                                  null != team_id &&
                                  null != proposed_type_id
                                "
                              >
                                The original type of the device is not available
                                for the new team you selected. However, we found
                                a type in your team with the same name. Do you
                                want to use it?
                              </p>

                              <!-- You have two choices: 
                              * find an existing type that has the same name
                              * or create a new type  -->

                              <label>Type</label>

                              <v-progress-circular
                                v-if="!teams_list_loaded"
                                class="mt-4"
                                indeterminate
                              />

                              <dropdown-list
                                v-else
                                ref="types_dropdown"
                                :disabled="
                                  null == team_id || device_stays_in_same_team
                                "
                                :preselected_id="proposed_type_id"
                                @objectsListLoaded="typesLoaded($event)"
                                :object_category="getTypeCategory"
                                :team_id_filter="team_id"
                                :required="true"
                                @objectIsSelected="typeDropdownSelected($event)"
                              />

                              <br />

                              <base-button
                                v-if="
                                  null != team_id &&
                                  !device_stays_in_same_team &&
                                  proposed_type_id != null
                                "
                                text="No, create another type instead of the selected one"
                                @click="add_type_component_is_visible = true"
                              />

                              <base-button
                                v-else-if="
                                  null != team_id && !device_stays_in_same_team
                                "
                                :is_accent="true"
                                text="Copy the original device type"
                                @click="add_type_component_is_visible = true"
                              />

                              <object-view
                                class="mw-em-wide"
                                :is_child_component="true"
                                :object_category_in="getTypeCategory"
                                :object_to_copy="copy_of_current_type"
                                :preselected_team_id="team_id"
                                v-if="add_type_component_is_visible"
                                @newObjectIsAddedFromChild="newTypeIsAdded"
                                @child_component_is_closed="
                                  add_type_component_is_visible = false
                                "
                              />
                            </div>
                          </div>
                        </div>

                        <div class="claim-container mt-5">
                          <p
                            v-if="
                              null != device_full &&
                              null == device_full.target_firmware
                            "
                          >
                            The original target firmware of the device is not
                            defined.
                          </p>

                          <template v-else>
                            <p class="font-italic" v-if="null == type_id">
                              Please select the type first.
                            </p>

                            <div
                              :class="{
                                'grey--text':
                                  null == type_id || device_stays_in_same_team,
                              }"
                            >
                              <label>Target firmware</label>

                              <div
                                v-show="
                                  null != type_id &&
                                  null == proposed_target_firmware_id
                                "
                              >
                                <p class="font-italic">
                                  There is no firmware of the current type with
                                  the same name and version as the original. We
                                  suggest that you copy the original firmware
                                  file to your team. If you choose not to do so,
                                  the target firmware will not be set and the
                                  device will not be updated.
                                </p>
                                <v-switch
                                  v-model="copy_firmware_flag"
                                  :label="'Automatically copy the target firmware'"
                                ></v-switch>
                              </div>

                              <div
                                v-show="
                                  null == type_id ||
                                  null != proposed_target_firmware_id
                                "
                              >
                                <dropdown-list
                                  ref="firmware_dropdown"
                                  :disabled="
                                    null == type_id || device_stays_in_same_team
                                  "
                                  @objectsListLoaded="firmwaresLoaded($event)"
                                  :type_id_filter="type_id"
                                  :preselected_id="proposed_target_firmware_id"
                                  :object_category="getFirmwareCategory"
                                  :required="true"
                                  @objectIsSelected="
                                    firmwareDropdownSelected($event)
                                  "
                                />

                                <base-button
                                  v-if="
                                    !device_stays_in_same_team &&
                                    null != target_firmware_id
                                  "
                                  text="Unset the target firmware"
                                  @click="clearFirmware"
                                />

                                <p
                                  v-else-if="
                                    proposed_target_firmware_id != null &&
                                    target_firmware_id == null
                                  "
                                  class="font-italic"
                                >
                                  Attention! The original target firmware of the
                                  device is not empty. However, you have chosen
                                  to unset the target firmware.
                                </p>
                              </div>
                            </div>
                          </template>
                        </div>

                        <base-button
                          @click="claimDevice"
                          :is_accent="true"
                          text="Claim the device"
                        />
                      </div>

                      <div class="mt-5">
                        <v-checkbox
                          class="force-padding-0"
                          v-model="add_strataggem_flag"
                          label="Add a Strataggem support user to the selected team."
                        ></v-checkbox>
                      </div>
                    </v-form>
                  </template>
                </v-card>
              </template>
            </template>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { mapGetters } from "vuex";
import BaseButton from "../components/app/BaseButton.vue";
import DropdownList from "../layouts/default/widgets/DropdownList.vue";
import QrCodeDeviceInfo from "../layouts/default/widgets/QrCodeDeviceInfo.vue";
import QrCodeDevicePublicInfo from "../layouts/default/widgets/QrCodeDevicePublicInfo.vue";
import {
  getFirmwareCategory,
  getTeamCategory,
  getTypeCategory,
} from "../utils/RoutesUtils";
import {
  claimDeviceCall,
  executeQrCodeAction,
  getDeviceJson,
  getDeviceByQrAndClaimCodesCall,
  addStratagemUserToTeam,
  addNewObject,
  getObjectsList,
} from "../utils/ServerUtils";
import ObjectView from "./ObjectView.vue";

export default {
  components: {
    BaseButton,
    QrCodeDeviceInfo,
    QrCodeDevicePublicInfo,
    DropdownList,
    ObjectView,
  },

  data() {
    return {
      device_public_info: null,
      device_full: null,
      user_has_rights: false,
      qr_code_id: null,

      team_id: null,
      type_id: null,
      target_firmware_id: null,
      teams_list_loaded: false,
      types_list_loaded: false,
      firmwares_list_loaded: false,

      proposed_type_id: null,
      proposed_target_firmware_id: null,
      copy_firmware_flag: true,

      claimed_open: false,
      claim_code: "",
      claim_code_is_wrong: false,
      claim_code_is_ok: false,
      add_strataggem_flag: true,
      advanced_flag: false,
      team_name: "UNDEFINED",

      add_type_component_is_visible: false,
      add_firmware_component_is_visible: false,

      label_image: null,
    };
  },

  async created() {
    this.qr_code_id = new URL(location.href).searchParams.get("id");

    // we suppose that the action is "getDevicePublicInfo"
    this.device_public_info = await executeQrCodeAction(this.qr_code_id);
    if (null != this.device_public_info)
      this.label_image = this.device_public_info["label_svg_image"];

    // if the device ID is returned, that means that the user DOES have rights to see it
    this.user_has_rights =
      null != this.device_public_info && null != this.device_public_info.id;

    if (this.user_has_rights) this.getFullDeviceInfo();
  },

  computed: {
    ...mapGetters(["user"]),

    isValidQrCodeAddress() {
      return null != this.device_public_info;
    },

    isDeviceWaitingToBeClaimed() {
      return (
        null != this.device_public_info &&
        this.device_public_info.to_be_claimed == true
      );
    },

    getTeamCategory() {
      return getTeamCategory();
    },

    getTypeCategory() {
      return getTypeCategory();
    },

    getFirmwareCategory() {
      return getFirmwareCategory();
    },

    device_stays_in_same_team() {
      return (
        null != this.device_full && this.device_full.team_id == this.team_id
      );
    },

    copy_of_current_type() {
      if (null == this.device_full) return null;

      var type_to_copy = this.device_full.type;

      // Copy all the field except of
      // id, team and owner
      type_to_copy.id = null;
      type_to_copy.team_id = this.team_id;
      type_to_copy.author_id = null;

      return type_to_copy;
    },
  },

  methods: {
    teamDropdownSelected: function (object) {
      if (null != object) {
        this.team_id = object.id;
        this.team_name = object.name;

        this.findTypeCorrespondingToDevice();
      } else {
        this.team_id = null;
        this.team_name = "UNDEFINED";

        this.type_id = null;
        this.proposed_type_id = null;

        this.target_firmware_id = null;
        this.proposed_target_firmware_id = null;
      }
    },

    clearFirmware() {
      this.$refs["firmware_dropdown"].clearSelection();
    },

    async automaticDeviceClaim() {
      // First, check if the team, to we which the device will be claimed, is set
      if (null == this.team_id) {
        if (null != this.$refs["teams_dropdown"])
          this.$refs["teams_dropdown"].validateInput();

        return;
      }

      // Then, get the device to be claimed by its QR code ID
      await this.getDeviceByQrAndClaimCodes();

      // Copy the type or find the type corresponding to the device to be claimed
      await this.findCorrespondingTypeOrCreateNew();

      if (null != this.type_id) {
        // Then, decide if we need to copy a firmware to the new type or if we can use an existing one
        this.findTargetFirmwareCorrespondingToDevice();

        const should_firmware_be_copied =
          this.proposed_target_firmware_id != null &&
          this.target_firmware_id == null
            ? false
            : this.copy_firmware_flag;

        // Finally, claim the device
        const claim_result = await claimDeviceCall(
          this.device_full.id,
          this.claim_code,
          this.team_id,
          this.type_id,
          this.target_firmware_id,
          should_firmware_be_copied
        );

        // Last step - add the Strataggem support user to the team
        await addStratagemUserToTeam(this.team_id);

        // If everything went well, refresh the page
        if (null != claim_result && claim_result.status == 204) {
          console.log("Device claimed, refreshing page");
          this.reload();
        }
      } else {
        console.log("Error. No type found, neither could be created.");
      }
    },

    async findCorrespondingTypeOrCreateNew() {
      var typesList = await getObjectsList(this.getTypeCategory, true);
      this.typesLoaded(typesList);

      if (null == this.proposed_type_id) {
        await this.createType();
      } else {
        this.type_id = this.proposed_type_id;
      }
    },

    async createType() {
      const req_body = this.copy_of_current_type;

      Object.keys(req_body).forEach((key) => {
        if (req_body[key] === null) {
          delete req_body[key];
        }
      });

      const object_created = await addNewObject(this.getTypeCategory, req_body);

      if (null != object_created) {
        this.type_id = object_created.id;
      }
    },

    findTypeCorrespondingToDevice() {
      if (this.device_full != null) {
        // If the device stays in the same team, just use the same type
        if (this.device_stays_in_same_team) {
          this.proposed_type_id = this.device_full.type_id;
          // Otherwise, try to find a type with the same name
        } else {
          this.proposed_type_id = null;

          if (null != this.types) {
            for (var type of this.types) {
              if (
                type.name == this.device_full.type.name &&
                type.team_id == this.team_id
              ) {
                this.proposed_type_id = type.id;
                this.findTargetFirmwareCorrespondingToDevice();
                return;
              }
            }
          }
        }
      } else {
        console.log("Error. Device information has not been loaded.");
      }
    },

    typeDropdownSelected: function (object) {
      if (null != object) {
        this.type_id = object.id;
      } else {
        this.type_id = null;
      }

      this.findTargetFirmwareCorrespondingToDevice();
    },

    firmwareDropdownSelected(object) {
      if (null != object) {
        this.target_firmware_id = object.id;
      } else {
        this.target_firmware_id = null;
      }
    },

    async findTargetFirmwareCorrespondingToDevice() {
      if (!this.firmwares_list_loaded) {
        await this.getFirmwares();
      }

      if (this.device_full != null) {
        // If the device stays in the same team, just use the same firmware
        if (this.device_stays_in_same_team) {
          this.proposed_target_firmware_id =
            this.device_full.target_firmware_id;
        } else {
          // Otherwise, try to find a firmware with the same name and version
          this.proposed_target_firmware_id = null;

          if (
            null != this.firmwares &&
            null != this.device_full.target_firmware
          ) {
            for (var firmware of this.firmwares) {
              if (
                firmware.name == this.device_full.target_firmware.name &&
                firmware.version == this.device_full.target_firmware.version &&
                firmware.type_id == this.type_id
              ) {
                this.proposed_target_firmware_id = firmware.id;
                return;
              }
            }
          }
        }
      } else {
        console.log("Error. Device information has not been loaded.");
      }
    },

    async getFirmwares() {
      var firmwaresList = await getObjectsList(this.getFirmwareCategory, true);
      this.firmwaresLoaded(firmwaresList);
    },

    typesLoaded(typesList) {
      this.types_list_loaded = true;
      this.types = typesList;
      this.findTypeCorrespondingToDevice();
    },

    firmwaresLoaded(firmwaresList) {
      this.firmwares_list_loaded = true;
      this.firmwares = firmwaresList;
      this.findTargetFirmwareCorrespondingToDevice();
    },

    async getDeviceByQrAndClaimCodes() {
      if (this.validateForm(true)) {
        const result = await getDeviceByQrAndClaimCodesCall(
          this.qr_code_id,
          this.claim_code
        );

        if (
          null != result &&
          result.status == 200 &&
          null != result.data &&
          null != result.data.is_code_valid
        ) {
          const is_code_valid = result.data.is_code_valid;

          if (is_code_valid) {
            this.claim_code_is_ok = true;
            this.claim_code_is_wrong = false;
            this.device_full = result.data.device;

            this.findTypeCorrespondingToDevice();
          } else {
            this.claim_code_is_ok = false;
            this.claim_code_is_wrong = true;
          }
        }
      }
    },

    async claimDevice() {
      if (this.validateForm()) {
        const should_firmware_be_copied =
          this.proposed_target_firmware_id != null &&
          this.target_firmware_id == null
            ? false
            : this.copy_firmware_flag;

        const claim_result = await claimDeviceCall(
          this.device_full.id,
          this.claim_code,
          this.team_id,
          this.type_id,
          this.target_firmware_id,
          should_firmware_be_copied
        );

        if (this.add_strataggem_flag) {
          await addStratagemUserToTeam(this.team_id);
        }

        if (null != claim_result && claim_result.status == 204) {
          this.reload();
        }
      } else {
        console.log("form is not valid");
      }
    },

    validateForm(validate_claim_code_only = false) {
      if (null != this.$refs["claim_code_input"])
        this.$refs["claim_code_input"].validate();

      if (validate_claim_code_only) {
        return "" != this.claim_code;
      } else {
        // send a validation event to the teams dropdown list
        if (null != this.$refs["teams_dropdown"])
          this.$refs["teams_dropdown"].validateInput();

        return null != this.team_id && "" != this.claim_code;
      }
    },

    async getFullDeviceInfo() {
      this.device_full = await getDeviceJson(this.device_public_info.id);
    },

    reload() {
      this.$router.go(this.$router.currentRoute);
    },

    newTypeIsAdded(response_data) {
      this.type_id = response_data.id;

      // trigger an update of the types dropdown list component
      this.$refs["types_dropdown"].updateObjects();
      this.proposed_type_id = this.type_id;

      this.add_type_component_is_visible = false;
    },
  },
};
</script>
